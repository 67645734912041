import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { shallowEqual, useSelector } from 'react-redux';
import { log } from 'utils';
import './AllCalendar.css';

function AllCalendar() {
  const { mediaList } = useSelector(
    (state) => ({
      mediaList: state.media.list,
    }),
    shallowEqual
  );

  //   const { siteId } = useParams();
  const localizer = momentLocalizer(moment);
  const [data, setData] = useState({ event: [], view: 'week' });
  const [index, setIndex] = useState({
    month: 0,
    week: 0,
  });
  //   const [isModalShow, setIsModalShow] = useState(false);
  //   const [selectedMedia, setSelectedMedia] = useState({
  //     hours: [],
  //     name: '',
  //     logo: '',
  //   });
  //   const [loading, setLoading] = useState(false);
  const [defaultView, setDefaultView] = useState('week');
  const [selectedYear, setSelectedYear] = useState(moment().year());

  useEffect(() => {
    // log('2020-12-26', moment('2020-12-26').format('dddd'));
    setIndex({
      ...index,
      week: moment().week(),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getData();
  }, [index, defaultView]); // eslint-disable-line react-hooks/exhaustive-deps

  const customToolbar = (props) => {
    const navigate = (action) => {
      props.onNavigate(action);
    };

    return (
      <div className="button-action">
        <div className="button-wrapper">
          <div className="button-group-wrapper">
            <button
              className={`button-primary ${
                defaultView === 'week' ? 'active' : ''
              }`}
              onClick={() => setDefaultView('week')}
            >
              <i className="feather-list" />
              Week View
            </button>
            <button
              className={`button-primary ${
                defaultView === 'month' ? 'active' : ''
              }`}
              onClick={() => setDefaultView('month')}
            >
              <i className="feather-calendar" />
              Month View
            </button>
          </div>

          <div className="button-group-wrapper">
            <button
              className="button-secondary"
              type="button"
              onClick={() => navigate('TODAY')}
            >
              <i className="feather-calendar" /> Today
            </button>
            <button
              className="button-secondary"
              type="button"
              onClick={() => navigate('PREV')}
            >
              <i className="feather-chevron-left" />
              Prev
            </button>
            <button
              className="button-secondary"
              type="button"
              onClick={() => navigate('NEXT')}
            >
              Next
              <i className="feather-chevron-right" />
            </button>
          </div>
        </div>
        <div className="title" style={{ marginBottom: 20 }}>
          {props.label}
        </div>
      </div>
    );
  };

  const getData = () => {
    const dates = mediaList
      .filter((item) => item.type === 'all')
      .map((item, index) => {
        let color = item.color ? item.color : '#3273dc';
        return {
          start: new Date('12-12-1000'),
          end: new Date('12-12-3000'),
          title: defaultView === 'month' ? item.name : '',
          logo: item.logo,
          fileType: item.fileType,
          id: item._id,
          color,
          legend: item.name,
          unique: 'all-' + index,
        };
      });

    let dateCollect = [];
    mediaList
      .filter((item) => item.type === 'date')
      // eslint-disable-next-line
      .map((item) => {
        const date = calculateDate(item);
        dateCollect = [...dateCollect, ...date];
      });

    let daysCollect = [];
    mediaList
      .filter((item) => item.type === 'day')
      // eslint-disable-next-line
      .map((item) => {
        const dateByDay = calculateDateByDay(item, defaultView);
        daysCollect = [...daysCollect, ...dateByDay];
      });

    // log(daysCollect)

    const group = [...dates, ...daysCollect, ...dateCollect];
    // const groupFiltered = defaultView === 'month' ? _.uniq(group, 'unique') : group
    setData({ event: group, view: defaultView });
  };

  const calculateDate = (media) => {
    let collect = [];
    let color = media.color ? media.color : '#3273dc';

    // eslint-disable-next-line
    media.dates.map((date, index) => {
      // eslint-disable-next-line
      date.hours.map((hour, index2) => {
        const range = hour.split('-');
        collect = [
          ...collect,
          {
            start: new Date(`${date.date} ${range[0]}`),
            end: new Date(`${date.date} ${range[1]}`),
            title: defaultView === 'month' ? media.name : '',
            legend: media.name,
            logo: media.logo,
            fileType: media.fileType,
            color,
            unique: 'date-' + index,
          },
        ];
      });
      // return {
      //   id: media._id,
      //   index,
      //   start: new Date(moment(date.date)),
      //   end: new Date(moment(date.date)),
      //   logo: media.logo,
      //   title: media.name,
      //   hours: date.hours
      // }
    });

    return [...collect];
  };

  const calculateDateByDay = (media, type) => {
    let dates = [];
    let color = media.color ? media.color : '#3273dc';

    media.days &&
      // eslint-disable-next-line
      media.days.map((data) => {
        // const day = moment().startOf(type).add(index[type], type).day(data.day);
        let day;
        if (type === 'week') {
          day = moment().year(selectedYear).day(data.day).week(index.week);
        } else {
          day = moment()
            .year(selectedYear)
            .startOf('month')
            .add(index.month, 'month')
            .day(data.day);
          // .month(index.month);
        }

        // : moment().add(index[type]);
        // const day = moment().add(index.week, 'week').day(data.day);
        if (type === 'month') {
          if (day.date() > 7) {
            day.add(7, 'd');
          }
        }

        const month = day.month();

        log(day.format('YYYY-MM-DD'));

        while (month === day.month()) {
          data.hours &&
            // eslint-disable-next-line
            data.hours.map((hour) => {
              const range = hour.split('-');

              if (range[0] && range[1]) {
                const hourFrom = parseInt(range[0].split(':')[0]);
                const minuteFrom = parseInt(range[0].split(':')[1]);

                const hourTo = parseInt(range[1].split(':')[0]);
                const minuteTo = parseInt(range[1].split(':')[1]);

                const date = {
                  start: new Date(new Date(day).setHours(hourFrom, minuteFrom)),
                  end: new Date(new Date(day).setHours(hourTo, minuteTo)),
                  title: defaultView === 'month' ? media.name : '',
                  legend: media.name,
                  logo: media.logo,
                  fileType: media.fileType,
                  color,
                  unique: 'day-' + day + '-' + media.name,
                };
                dates.push(date);
              }
            });

          day.add(7, 'd');
        }
      });

    return dates;
  };

  const onNavigate = (date, view, rightAction) => {
    const weekCount = moment(date).week();
    const monthCount = moment(date).month();
    const currentMonthCount = moment().month();
    const year = moment(date).year();

    if (weekCount === 1 && monthCount === 11 && defaultView === 'week') {
      setSelectedYear(moment(date).add(1, 'year').year());
    } else {
      setSelectedYear(year);
    }

    setIndex({
      week: weekCount,
      month: monthCount - currentMonthCount,
    });
  };

  // log(data.event)

  return (
    <div className="allcalendar">
      <Calendar
        popup
        // toolbar={false}
        components={{
          toolbar: customToolbar,
        }}
        view={defaultView}
        onView={() => true}
        localizer={localizer}
        events={data.event}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onNavigate={onNavigate}
        eventPropGetter={(event, start, end, isSelected) => {
          return {
            style: {
              backgroundColor: event.color,
            },
            className: defaultView === 'week' ? 'rbc-event-custom' : '',
          };
        }}
        // onSelectEvent={(e) => {
        //   setIsModalShow(true);
        //   const findData = data.event.filter((x) => x.legend === e.legend);
        //   setSelectedMedia(e);
        // }}
      />
    </div>
  );
}

export default AllCalendar;
