import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const SUBCATEGORIES_FETCH_DATA_INIT = createAction(
  'SUBCATEGORIES_FETCH_DATA_INIT'
);
export const SUBCATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'SUBCATEGORIES_FETCH_DATA_SUCCESS'
);
export const SUBCATEGORIES_FETCH_DATA_FAIL = createAction(
  'SUBCATEGORIES_FETCH_DATA_FAIL'
);

export const SUBCATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'SUBCATEGORIES_DELETE_CATEGORY_INIT'
);
export const SUBCATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'SUBCATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const SUBCATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'SUBCATEGORIES_DELETE_CATEGORY_FAIL'
);

export const SUBCATEGORIES_CLEAR_DATA = createAction(
  'SUBCATEGORIES_CLEAR_DATA'
);

export const SUBCATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'SUBCATEGORIES_CREATE_CATEGORY_INIT'
);
export const SUBCATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'SUBCATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const SUBCATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'SUBCATEGORIES_CREATE_CATEGORY_FAIL'
);

export const SUBCATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'SUBCATEGORIES_MODIFY_CATEGORY_INIT'
);
export const SUBCATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'SUBCATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const SUBCATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'SUBCATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const SUBCATEGORIES_CLEAN_UP = createAction('SUBCATEGORIES_CLEAN_UP');

export const SUBCATEGORIES_CLEAR_DATA_LOGOUT = createAction(
  'SUBCATEGORIES_CLEAR_DATA_LOGOUT'
);

export const SET_SUBCATEGORY = createAction('SET_SUBCATEGORY');

export const fetchSubCategories = ({ subCategoryId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(SUBCATEGORIES_FETCH_DATA_INIT());
    if (subCategoryId) {
      let subCategoryData;
      try {
        subCategoryData = await Axios.get(`${url}/poiSubCategory/list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SUBCATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      const subCategory = { ...subCategoryData, id: subCategoryId };
      return dispatch(
        SUBCATEGORIES_FETCH_DATA_SUCCESS({
          list: getState().subcategories.list,
          subCategory,
        })
      );
    }

    let subCategories;
    try {
      subCategories = await Axios.get(`${url}/poiSubCategory/list`, {
        params: {
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(SUBCATEGORIES_FETCH_DATA_FAIL({ error }));
    }
    subCategories = subCategories.data.data.filter(
      (subCategory) => subCategory.siteId === siteId
    );

    return dispatch(
      SUBCATEGORIES_FETCH_DATA_SUCCESS({
        list: subCategories,
        subCategory: getState().subcategories.subCategory,
      })
    );
  };
};

export const deleteSubCategory = (id, cb) => {
  return async (dispatch) => {
    dispatch(SUBCATEGORIES_DELETE_CATEGORY_INIT());

    try {
      await Axios.delete(`${url}/poiSubCategory/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage, { icon: 'error' });
      return dispatch(
        SUBCATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The subCategory was deleted.');
    cb();
    return dispatch(SUBCATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearSubCategoriesData = () => {
  return (dispatch) => {
    dispatch(SUBCATEGORIES_CLEAR_DATA());
  };
};

export const clearSubCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(SUBCATEGORIES_CLEAR_DATA_LOGOUT());
  };
};

export const createSubCategory = (
  { name, description, siteId, categoryId, file },
  cb
) => {
  return async (dispatch) => {
    dispatch(SUBCATEGORIES_CREATE_CATEGORY_INIT());
    let subCategory;
    const subCategoryData = new FormData();
    subCategoryData.set('name', name);
    subCategoryData.set('description', description);
    subCategoryData.set('categoryId', categoryId);
    subCategoryData.set('siteId', siteId);
    if (file) subCategoryData.append('file', file);
    try {
      subCategory = await Axios.post(
        `${url}/poiSubCategory/create`,
        subCategoryData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error.response, '<<< error response');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SUBCATEGORIES_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Category created successfully');
    cb();
    dispatch(
      SET_SUBCATEGORY({
        data: {
          name: '',
          description: '',
        },
      })
    );

    return dispatch(
      SUBCATEGORIES_CREATE_CATEGORY_SUCCESS({
        subCategory: subCategory.data.data,
      })
    );
  };
};

export const modifySubCategory = (
  {
    id,
    name,
    description,
    siteId,
    categoryId,
    modifiedAt,
    modifiedBy,
    logo,
    file,
  },
  cb
) => {
  return async (dispatch) => {
    dispatch(SUBCATEGORIES_MODIFY_CATEGORY_INIT());

    const subCategoryData = new FormData();
    subCategoryData.set('name', name);
    subCategoryData.set('id', id);
    subCategoryData.set('categoryId', categoryId);
    subCategoryData.set('description', description);
    subCategoryData.set('siteId', siteId);
    subCategoryData.set('modifiedAt', modifiedAt);
    subCategoryData.set('modifiedBy', modifiedBy);
    if (logo) subCategoryData.set('logo', logo);
    if (file) subCategoryData.append('file', file);

    let updateCategoryDbTask;

    try {
      updateCategoryDbTask = await Axios.post(
        `${url}/poiSubCategory/edit`,
        subCategoryData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      // await Promise.all([updateCategoryDbTask]);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        SUBCATEGORIES_MODIFY_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category updated successfully');
    cb();
    dispatch(
      SET_SUBCATEGORY({
        data: {
          name: '',
          description: '',
        },
      })
    );
    dispatch(
      SUBCATEGORIES_MODIFY_CATEGORY_SUCCESS({
        subCategory: updateCategoryDbTask.data.data,
        id,
      })
    );
    return dispatch(fetchSubCategories({ siteId }));
  };
};

export const subCategoriesCleanUp = () => (dispatch) =>
  dispatch(SUBCATEGORIES_CLEAN_UP());

export const setSubCategory = (data, cb) => {
  return (dispatch) => {
    dispatch(SET_SUBCATEGORY({ data }));
    if (cb) {
      cb();
    }
  };
};
