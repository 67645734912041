import React from 'react';
const ImportOutlineIcon = ({ size = 14, color = '#000' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90129 12.9792H5.09796C2.23379 12.9792 1.00879 11.7542 1.00879 8.88999V8.81416C1.00879 6.22416 2.02962 4.97583 4.31629 4.75999C4.54962 4.74249 4.77129 4.91749 4.79462 5.15666C4.81796 5.39583 4.64296 5.61166 4.39796 5.63499C2.56629 5.80416 1.88379 6.66749 1.88379 8.81999V8.89583C1.88379 11.27 2.72379 12.11 5.09796 12.11H8.90129C11.2755 12.11 12.1155 11.27 12.1155 8.89583V8.81999C12.1155 6.65583 11.4213 5.79249 9.55462 5.63499C9.31546 5.61166 9.13462 5.40166 9.15796 5.16249C9.18129 4.92333 9.38546 4.74249 9.63046 4.76583C11.9521 4.96416 12.9905 6.21833 12.9905 8.82583V8.90166C12.9905 11.7542 11.7655 12.9792 8.90129 12.9792Z"
        fill={color}
      />
      <path
        d="M7 9.11783C6.76083 9.11783 6.5625 8.91949 6.5625 8.68033V1.16699C6.5625 0.927826 6.76083 0.729492 7 0.729492C7.23917 0.729492 7.4375 0.927826 7.4375 1.16699V8.68033C7.4375 8.92533 7.23917 9.11783 7 9.11783Z"
        fill={color}
      />
      <path
        d="M6.99958 9.77107C6.88875 9.77107 6.77792 9.73024 6.69042 9.64274L4.73625 7.68857C4.56708 7.5194 4.56708 7.2394 4.73625 7.07023C4.90542 6.90107 5.18542 6.90107 5.35458 7.07023L6.99958 8.71523L8.64458 7.07023C8.81375 6.90107 9.09375 6.90107 9.26292 7.07023C9.43208 7.2394 9.43208 7.5194 9.26292 7.68857L7.30875 9.64274C7.22125 9.73024 7.11042 9.77107 6.99958 9.77107Z"
        fill={color}
      />
    </svg>
  );
};

export default ImportOutlineIcon;
