import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import UserForm from 'components/UserForm';
import { createUser, modifyUser, fetchUsers } from 'state/actions/users';
import { fetchOrganizations } from 'state/actions/organizations';
// import paths from 'pages/Router/paths';
import { Breadcrumb } from 'antd';
import { fetchRoles } from 'state/actions/roles';

const ScreenUser = ({ history }) => {
  const { id } = useParams();

  const {
    // success,
    usersList,
    userData,
    organizationsList,
    roleList,
    error,
    loading,
  } = useSelector(
    (state) => ({
      // success: state.users.success,
      usersList: state.users.list,
      userData: state.users.user,
      organizationsList: state.organizations.list,
      roleList: state.roles.list,
      error: state.users.error,
      loading: state.users.loading,
    }),
    shallowEqual
  );

  const [user, setUser] = useState(userData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganizations());
    if (id) {
      const userFetched = usersList.find(
        (fetchedUser) => fetchedUser.id === id
      );
      if (userFetched) {
        setUser(userFetched);
        dispatch(fetchRoles());
      } else if (userData.id === id) {
        setUser(userData);
        dispatch(fetchRoles());
      } else {
        dispatch(fetchUsers(id));
        dispatch(fetchRoles());
      }
    } else {
      setUser({});
    }
  }, [id, userData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEditing = !!id;
  const userForm =
    !user && id ? (
      <ClipLoader />
    ) : (
      <UserForm
        isEditing={isEditing}
        user={user}
        organizations={organizationsList}
        setUser={setUser}
        error={error}
        roles={roleList}
        action={isEditing ? modifyUser : createUser}
        isScreen={true}
        history={history}
      />
    );

  // const redirect = success && <Redirect to={paths.SCREEN_USERS} />;

  return (
    <div className="user">
      {/* {redirect} */}
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/users">Screen Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                {user._id ? 'Edit Screen' : 'Add new Screen'}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">
            {user._id ? 'Edit Screen' : 'Add new Screen'}
          </h1>
        </div>
        <div className="header-right-content">
          <Link to="/screen-users" className="button-secondary">
            <i className="feather-arrow-left" />
            Back
          </Link>
        </div>
      </div>
      <div className="user-content">{loading ? <ClipLoader /> : userForm}</div>
    </div>
  );
};

export default ScreenUser;
