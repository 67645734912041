import { createReducer } from 'redux-act';

import {
  CATEGORIES_FETCH_DATA_INIT,
  CATEGORIES_FETCH_DATA_SUCCESS,
  CATEGORIES_FETCH_DATA_FAIL,
  CATEGORIES_DELETE_CATEGORY_INIT,
  CATEGORIES_DELETE_CATEGORY_SUCCESS,
  CATEGORIES_DELETE_CATEGORY_FAIL,
  CATEGORIES_CLEAR_DATA,
  CATEGORIES_CREATE_CATEGORY_INIT,
  CATEGORIES_CREATE_CATEGORY_SUCCESS,
  CATEGORIES_CREATE_CATEGORY_FAIL,
  CATEGORIES_MODIFY_CATEGORY_INIT,
  CATEGORIES_MODIFY_CATEGORY_SUCCESS,
  CATEGORIES_MODIFY_CATEGORY_FAIL,
  CATEGORIES_CLEAN_UP,
  CATEGORIES_CLEAR_DATA_LOGOUT,
  SET_CATEGORY,
} from 'state/actions/categories';

const initialState = {
  list: [],
  category: {
    _id: '',
    name: '',
    description: '',
    siteId: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
  updated: false,
};

export const categoriesReducer = createReducer(
  {
    [CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      category: payload.category || initialState.category,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.category),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.id) {
              console.log(payload, 'payload');
              return {
                _id: payload.id,
                name: payload.category.name,
                description: payload.category.description,
                siteId: payload.category.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.category.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
      updated: false,
    }),
    [CATEGORIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_CATEGORY]: (state, payload) => ({
      ...state,
      category: payload.data,
    }),
  },
  initialState
);
