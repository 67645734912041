import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const SCREENSHOT_FETCH_DATA_INIT = createAction(
  'SCREENSHOT_FETCH_DATA_INIT'
);
export const SCREENSHOT_FETCH_DATA_SUCCESS = createAction(
  'SCREENSHOT_FETCH_DATA_SUCCESS'
);
export const SCREENSHOT_FETCH_DATA_FAIL = createAction(
  'SCREENSHOT_FETCH_DATA_FAIL'
);

export const SCREENSHOT_FETCH_DATA_PER_HOUR_INIT = createAction(
  'SCREENSHOT_FETCH_DATA_PER_HOUR_INIT'
);
export const SCREENSHOT_FETCH_DATA_PER_HOUR_SUCCESS = createAction(
  'SCREENSHOT_FETCH_DATA_PER_HOUR_SUCCESS'
);
export const SCREENSHOT_FETCH_DATA_PER_HOUR_FAIL = createAction(
  'SCREENSHOT_FETCH_DATA_PER_HOUR_FAIL'
);

export const SCREENSHOT_FETCH_DATA_STATUS_INIT = createAction(
  'SCREENSHOT_FETCH_DATA_STATUS_INIT'
);

export const SCREENSHOT_FETCH_DATA_STATUS_SUCCESS = createAction(
  'SCREENSHOT_FETCH_DATA_STATUS_SUCCESS'
);

export const SCREENSHOT_FETCH_DATA_STATUS_FAIL = createAction(
  'SCREENSHOT_FETCH_DATA_STATUS_FAIL'
);

export const SCREENSHOT_DELETE_SCREENSHOT_INIT = createAction(
  'SCREENSHOT_DELETE_SCREENSHOT_INIT'
);
export const SCREENSHOT_DELETE_SCREENSHOT_SUCCESS = createAction(
  'SCREENSHOT_DELETE_SCREENSHOT_SUCCESS'
);
export const SCREENSHOT_DELETE_SCREENSHOT_FAIL = createAction(
  'SCREENSHOT_DELETE_SCREENSHOT_FAIL'
);

export const SCREENSHOT_CLEAR_DATA = createAction('SCREENSHOT_CLEAR_DATA');

export const SCREENSHOT_CREATE_SCREENSHOT_INIT = createAction(
  'SCREENSHOT_CREATE_SCREENSHOT_INIT'
);
export const SCREENSHOT_CREATE_SCREENSHOT_SUCCESS = createAction(
  'SCREENSHOT_CREATE_SCREENSHOT_SUCCESS'
);
export const SCREENSHOT_CREATE_SCREENSHOT_FAIL = createAction(
  'SCREENSHOT_CREATE_SCREENSHOT_FAIL'
);

export const SCREENSHOT_MODIFY_SCREENSHOT_INIT = createAction(
  'SCREENSHOT_MODIFY_SCREENSHOT_INIT'
);
export const SCREENSHOT_MODIFY_SCREENSHOT_SUCCESS = createAction(
  'SCREENSHOT_MODIFY_SCREENSHOT_SUCCESS'
);
export const SCREENSHOT_MODIFY_SCREENSHOT_FAIL = createAction(
  'SCREENSHOT_MODIFY_SCREENSHOT_FAIL'
);

export const SCREENSHOT_CLEAN_UP = createAction('SCREENSHOT_CLEAN_UP');

export const SCREENSHOT_CLEAR_DATA_LOGOUT = createAction(
  'SCREENSHOT_CLEAR_DATA_LOGOUT'
);

export const fetchScreenshot = ({ screenshotId, siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SCREENSHOT_FETCH_DATA_INIT());
    if (screenshotId) {
      let screenshotData;
      try {
        screenshotData = await Axios.get(`${url}/screenshot/get`, {
          params: {
            id: screenshotId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SCREENSHOT_FETCH_DATA_FAIL({ error }));
      }

      const screenshot = { ...screenshotData.data.data, id: screenshotId };
      return dispatch(
        SCREENSHOT_FETCH_DATA_SUCCESS({
          list: getState().screenshot.list,
          screenshot,
        })
      );
    }

    if (siteId) {
      let screenshot;
      try {
        screenshot = await Axios.get(`${url}/screenshot/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SCREENSHOT_FETCH_DATA_FAIL({ error }));
      }
      // feedback = feedback.data.data.filter((feedback) => feedback.siteId === siteId);
      return dispatch(
        SCREENSHOT_FETCH_DATA_SUCCESS({
          list: screenshot.data.data,
          screenshot: getState().screenshot.screenshot,
        })
      );
    }
  };
};

export const fetchPerHour = ({ siteId, mapId }) => {
  log(siteId, '<<<< site id');
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SCREENSHOT_FETCH_DATA_PER_HOUR_INIT);

    if (siteId) {
      let screenshot;
      try {
        screenshot = await Axios.get(`${url}/screenshot/getEveryHour`, {
          params: {
            siteId,
            mapId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SCREENSHOT_FETCH_DATA_PER_HOUR_FAIL({ error }));
      }
      // feedback = feedback.data.data.filter((feedback) => feedback.siteId === siteId);
      return dispatch(
        SCREENSHOT_FETCH_DATA_PER_HOUR_SUCCESS({
          list: screenshot.data.data,
          screenshot: getState().screenshot.screenshot,
        })
      );
    }
  };
};

export const fetchStatus = ({ mapId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SCREENSHOT_FETCH_DATA_STATUS_INIT());

    if (mapId) {
      let status;
      try {
        status = await Axios.get(`${url}/screenshot/getStatus`, {
          params: {
            mapId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        log(status, '<<<<<< dispatching status');
      } catch (error) {
        toastr.error('', 'No Status Detected');
        return dispatch(SCREENSHOT_FETCH_DATA_STATUS_FAIL({ error }));
      }
      return dispatch(
        SCREENSHOT_FETCH_DATA_STATUS_SUCCESS({
          status: status.data,
          // data: status.data.data,
        })
      );
    }
  };
};
export const deleteScreenshot = (id) => {
  return async (dispatch, getState) => {
    dispatch(SCREENSHOT_DELETE_SCREENSHOT_INIT());

    try {
      await Axios.delete(`${url}/screenshot/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREENSHOT_DELETE_SCREENSHOT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The screenshot was deleted.');
    return dispatch(SCREENSHOT_DELETE_SCREENSHOT_SUCCESS({ id }));
  };
};

export const clearScreenshotData = () => {
  return (dispatch) => {
    dispatch(SCREENSHOT_CLEAR_DATA());
  };
};

export const clearScreenshotDataLogout = () => {
  return (dispatch) => {
    dispatch(SCREENSHOT_CLEAR_DATA_LOGOUT());
  };
};

export const createScreenshot = ({ name, image, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(SCREENSHOT_CREATE_SCREENSHOT_INIT());

    let createScreenshot;
    try {
      createScreenshot = await Axios.post(
        `${url}/screenshot/create`,
        {
          name,
          siteId,
          image,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', error && error.response && error.response.data);
      return dispatch(
        SCREENSHOT_CREATE_SCREENSHOT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Screenshot created successfully');
    return dispatch(
      SCREENSHOT_CREATE_SCREENSHOT_SUCCESS({
        screenshot: createScreenshot.data.data,
      })
    );
  };
};

export const screenshotCleanUp = () => (dispatch) =>
  dispatch(SCREENSHOT_CLEAN_UP());
