import { CircularProgress } from '@material-ui/core';
import { Table } from 'antd';
import {
  ChartTitle,
  ChartWrapper,
  ReportWrapper,
} from 'components/Report/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
// import ScreensaverReport from './ScreensaverReport';

const VehicleAnalyticReport = (props) => {
  const { analytics, loading, locations } = props;
  const INITIAL_STATE = {
    labels: [],
    datasets: [],
  };

  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [dataTable, setDataTable] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      // yAxes: [
      //   {
      //     ticks: {
      //       beginAtZero: true,
      //     },
      //   },
      // ],
    },
    // animation: {
    //   duration: 1,
    //   onComplete() {
    //     const chartInstance = this.chart;
    //     const { ctx } = chartInstance;

    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'bottom';

    //     this.data.datasets.forEach(function (dataset, i) {
    //       const meta = chartInstance.controller.getDatasetMeta(i);
    //       meta.data.forEach(function (bar, index) {
    //         if (dataset.data[index] > 0) {
    //           const data = dataset.data[index];
    //           const textwidth = ctx.measureText(data).width;
    //           ctx.fillStyle = bar._model.backgroundColor;
    //           ctx.font = 'bold 12pt roboto';
    //           ctx.fillText(data, bar._model.x - 20, bar._model.y);

    //           ctx.font = 'normal 12pt roboto';
    //           ctx.fillStyle = '#404040';
    //           ctx.fillText(' people', bar._model.x + textwidth, bar._model.y);
    //         }
    //       });
    //     });
    //   },
    // },
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 15,
        borderRadius: 10,
        useBorderRadius: true,
        fontStyle: 'bold',
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      width: '10%',
      render: (text, record, index) => (
        <div className="table-name">{index + 1}</div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '40%',
      render: (text, record) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Vehicle Ratio (Truck : Car : Motorcylce : Bicycle)',
      dataIndex: 'data',
      width: '20%',
      render: (text, record) => {
        const truck = _.sumBy(record.ratio.truck, 'count');
        const car = _.sumBy(record.ratio.car, 'count');
        const motorcycle = _.sumBy(record.ratio.motorcycle, 'count');
        const bicycle = _.sumBy(record.ratio.bicycle, 'count');

        return (
          record.ratio && (
            <div className="table-name">
              <div className="truck">
                {record.ratio.truck
                  ? `(${Math.round((truck / record.total) * 100)}%)  ${truck} `
                  : ' (0%) 0 '}
              </div>
              :
              <div className="car">
                {record.ratio.car
                  ? ` (${Math.round((car / record.total) * 100)}%) ${car} `
                  : `0 (0%) `}
              </div>
              :
              <div className="motorcycle">
                {record.ratio.motorcycle
                  ? ` (${Math.round(
                      (motorcycle / record.total) * 100
                    )}%) ${motorcycle} `
                  : ` 0 (0%) `}
              </div>
              :
              <div className="bicyle">
                {record.ratio.bicycle
                  ? ` (${Math.round(
                      (bicycle / record.total) * 100
                    )}%) ${bicycle} `
                  : ` 0 (0%) `}
              </div>
            </div>
          )
        );
      },
    },
  ];

  const getColor = (vehicle) => {
    switch (vehicle) {
      case 'Truck':
        return '#2C82F6';
      case 'Car':
        return '#FF7066';
      case 'Motorcycle':
        return '#64D5D2';
      default:
        return '#FFBC00';
    }
  };
  useEffect(() => {
    console.log(locations, 'analytics in report');
    if (analytics && !loading) {
      // if (type === 'month') {
      const types = ['Truck', 'Car', 'Motorcycle', 'Bicycle'];

      const datasets = [];
      // eslint-disable-next-line
      types.map((t) => {
        datasets.push({
          label: t,
          data: Object.values(analytics).map((val) =>
            val ? val[t.toLowerCase()] : 0
          ),
          borderWidth: 1,
          stack: 'Stack 0',
          backgroundColor: getColor(t),
          strokeColor: getColor(t),
          borderRadius: 5,
          pointStyle: 'rectRounded',
        });
      });
      // });
      console.log(datasets, 'data sets');
      setReportData({
        labels: Object.keys(analytics),
        datasets,
      });
      let dataMonth = [];
      dataMonth = locations.map((loc) => {
        return {
          location: loc.location,
          total: _.sumBy(loc.data, 'count'),
          ratio: _.groupBy(loc.data, 'type'),
        };
      });
      console.log(dataMonth, 'data month');
      setDataTable(_.sortBy(dataMonth, ['location']));
    }
    // }
  }, [analytics]); // eslint-disable-line

  return (
    <div className="analytic-chart">
      {/* <div className="field-body"> */}

      {/* </div> */}
      <ReportWrapper>
        <ChartTitle>Vehicle Counter</ChartTitle>

        {/* <div className="selector">
          {locations && (
            <div className="m-2">
              <Select
                // defaultValue={loc}
                placeholder="Location"
                style={{ width: 120 }}
                onChange={(val) => {
                  setReportData();
                  setLoc(val);
                }}
                options={[
                  {
                    value: null,
                    label: 'All location',
                  },
                  ...locations.map((location) => {
                    return {
                      value: location,
                      label: location,
                    };
                  }),
                ]}
              />
            </div>
          )}
        </div> */}

        {/* <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{"Start Date"}</label>
          </div>
          <div className="field-label is-normal">
            <label className="label">{"End Date"}</label>
          </div>
        </div> */}
        {reportData && !loading ? (
          <ChartWrapper>
            <Bar
              data={reportData}
              type="bar"
              width={100}
              height={250}
              options={options}
            />
          </ChartWrapper>
        ) : (
          <>
            <CircularProgress />
          </>
        )}
        <div className="gender-summary" />
      </ReportWrapper>
      <Table columns={columns} dataSource={dataTable} />
    </div>
  );
};

export default VehicleAnalyticReport;
