import { createReducer } from "redux-act"
import { ACTIVE_TAB } from "state/actions/tab"

const initialState = {
    tab: 'media'
}

export const tabReducer = createReducer({
    [ACTIVE_TAB]: (state, payload) => ({
        ...state,
        tab:payload.tab
    }),
}, initialState)