import TemplateForm from 'components/TemplateForm';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { modifyTemplate } from 'state/actions/template';
import './Template.css';

const Template = ({ history }) => {
  const { id } = useParams();

  const { success, categoriesList, templateList, templateData } = useSelector(
    (state) => ({
      success: state.template.modified,
      categoriesList: state.mediaCategories.list,
      templateList: state.template.list,
      templateData: state.template.template,
      sitesList: state.sites.list,
      error: state.pois.error,
    }),
    shallowEqual
  );

  const [template, setTemplate] = useState(templateData);

  console.log(templateList, 'template fetch');
  useEffect(() => {
    if (id) {
      const templateFetched = templateList.find((temp) => temp._id === id);

      // if (templateFetched) {
      setTemplate(templateFetched);
      // }
    } else if (templateData._id) {
      setTemplate(templateData);
    }
  }, [id, templateData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(template, 'templatedata');
  }, [template]); // eslint-disable-line react-hooks/exhaustive-deps

  const poiForm =
    !template && id ? (
      <ClipLoader />
    ) : (
      template.name !== '' && (
        <TemplateForm
          history={history}
          success={success}
          template={template}
          action={modifyTemplate}
          categoriesList={categoriesList}
        />
      )
    );
  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  return poiForm;
};

export default Template;
