import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { errorMsg } from 'utils';

export const RESPONDENT_FETCH_DATA_INIT = createAction(
  'RESPONDENT_FETCH_DATA_INIT'
);
export const RESPONDENT_FETCH_DATA_SUCCESS = createAction(
  'RESPONDENT_FETCH_DATA_SUCCESS'
);
export const RESPONDENT_FETCH_DATA_FAIL = createAction(
  'RESPONDENT_FETCH_DATA_FAIL'
);

export const fetchRespondent = ({ id }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(RESPONDENT_FETCH_DATA_INIT());
    if (id) {
      let respondentData;
      try {
        respondentData = await Axios.get(`${url}/respondent/get`, {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(RESPONDENT_FETCH_DATA_FAIL({ error }));
      }

      const respondent = {
        ...respondentData.data.data,
        id: id,
      };
      return dispatch(
        RESPONDENT_FETCH_DATA_SUCCESS({
          list: getState().respondent.list,
          respondent,
        })
      );
    }
  };
};
