import { Table, DatePicker, Tag } from 'antd';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import { downloadLogs, fetchLogs } from 'state/actions/log';
import { FilterIcon } from 'assets/icon';
import { fetchRoles } from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import { log } from 'utils';
import SelectDropdown from 'components/SelectDropdown';
import PageHeader from 'design-components/PageHeader';
import './Logs.css';

const { RangePicker } = DatePicker;

export default function Logs({ match }) {
  const [dropdown, setDropdown] = useState();
  const [category, setCategory] = useState();
  const [dropdownType, setFilterType] = useState('All');
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const {
    userData,
    roleList,
    logsList,
    siteList,
    role,
    loading,
    deleted,
    error,
    userList,
    downloading,
  } = useSelector(
    (state) => ({
      roleList: state.roles.list,
      userData: state.auth.userData,
      organizationList: state.organizations.list,
      logsList: state.logs.list,
      siteList: state.sites.list,
      userList: state.users.list,
      loading: state.logs.loading,
      deleted: state.logs.deleted,
      role: state.auth.userData.role,
      error: state.logs.error,
      downloading: state.logs.downloading,
    }),
    shallowEqual
  );

  const filterCategory =
    userData && userData.role === 'superadmin'
      ? ['Role', 'User', 'Site']
      : ['Role', 'User'];
  const roleListbyUser =
    userData && userData.role === 'superadmin'
      ? roleList
      : userData.roleAccess &&
        roleList.filter((role) => role.name === userData.roleAccess.name);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (role === 'admin' || role === 'superadmin') {
  //     dispatch(fetchLogs({}));
  //   }
  // }, [dispatch, role]); // eslint-disable-line

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { menu: 'Audit Logs', link: '/logs' },
        { menu: 'System Log', link: '/logs' },
      ])
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (dateRange.start !== '' && dateRange.end !== '') {
      onSearch();
    }
  }, [category, dateRange]); // eslint-disable-line

  const onSearch = () => {
    const { startDate, endDate } = dateRange;
    log(dropdownType, category, 'dropdown value');
    if (
      userData.organizationName ||
      userData.role === 'superadmin' ||
      userData.role === 'admin'
    ) {
      if (dropdownType === 'Role' && category !== 'All') {
        log(category);
        dispatch(
          fetchLogs({
            startDate,
            endDate,
            role: category,
          })
        );
      } else if (dropdownType === 'User' && category !== 'All') {
        dispatch(fetchLogs({ startDate, endDate, email: category }));
      } else if (dropdownType === 'Site' && category !== 'All') {
        dispatch(fetchLogs({ startDate, endDate, siteId: category }));
      } else {
        dispatch(fetchLogs({ startDate, endDate }));
      }
    }
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    setDateRange((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  useEffect(() => {
    if (logsList) {
      const arrayOfDropdown = [];
      switch (dropdownType) {
        case 'Role':
          roleListbyUser.map((org) =>
            arrayOfDropdown.push({
              categoryId: org._id,
              name: org.name,
            })
          );
          break;
        case 'User':
          userList
            .filter((org) =>
              userData.role === 'superadmin'
                ? true
                : !!(
                    userData.roleAccess &&
                    userData.roleAccess._id === org.roleId
                  )
            )
            .map((user) =>
              arrayOfDropdown.push({
                categoryId: user.email,
                name: user.name ?? user.email,
              })
            );
          break;
        case 'Site':
          siteList.map((site) =>
            arrayOfDropdown.push({
              categoryId: site._id,
              name: site.name,
            })
          );

          break;

        default:
          // setCategory('All');
          break;
      }

      setDropdown(
        arrayOfDropdown.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    }
  }, [logsList, dropdownType]); // eslint-disable-line

  useEffect(() => {
    // if (deleted && !loading) {
    //   dispatch(fetchLogs());
    // }
  }, [loading, deleted]); // eslint-disable-line

  const handleDownload = () => {
    const { startDate, endDate } = dateRange;
    if (dropdownType === 'Role' && category !== 'All') {
      log(category);
      dispatch(
        downloadLogs({
          startDate,
          endDate,
          role: category,
        })
      );
    } else if (dropdownType === 'User' && category !== 'All') {
      dispatch(downloadLogs({ startDate, endDate, email: category }));
    } else if (dropdownType === 'Site' && category !== 'All') {
      dispatch(downloadLogs({ startDate, endDate, siteId: category }));
    } else {
      dispatch(downloadLogs({ startDate, endDate }));
    }
    // dispatch(downloadLogs());
  };

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchUsers());
    // dispatch(fetchSites());
  }, []); // eslint-disable-line

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const filteredData = search
    ? logsList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.message.toLowerCase().includes(search.toLowerCase());
      })
    : logsList;

  const columns = [
    {
      title: useFormatMessage('Logs.email'),
      dataIndex: 'email',
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: useFormatMessage('Logs.browser'),
      dataIndex: 'browserAgent',
    },
    {
      title: useFormatMessage('Logs.ip'),
      dataIndex: 'ipAddress',
    },
    {
      title: useFormatMessage('Logs.date'),
      dataIndex: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => {
        if (record.date) {
          return (
            <Tag className="table-date">
              <div>{`${moment(record.date)
                .utcOffset('+08:00')
                .format('h:mm A, MMM Do, YYYY')}`}</div>
            </Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: useFormatMessage('Logs.status'),
      dataIndex: 'status',
      render: (text, record) => {
        if (record.status === 500 || record.status === 422) {
          return <Tag color="red">Error</Tag>;
        }
        if (record.status === 201 || record.status === 200) {
          return <Tag color="green">Success</Tag>;
        }
      },
    },
    {
      title: useFormatMessage('Logs.message'),
      dataIndex: 'message',
      render: (text, record) => {
        return <span>{record.message}</span>;
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  // console.log(filteredData, 'filtered data')
  return (
    <section className="logs">
      {redirect}
      <PageHeader
        title="System Logs"
        isGrid={false}
        isSearch
        searchValue={search}
        searchAction={(e) => setSearch(e.target.value)}
        searchPlaceholder="Enter User's Name"
        isAdd={false}
        addLabel=""
        isDownload
        isDownloading={downloading}
        downloadAction={handleDownload}
      />
      <div className="logs-menu">
        <div className="logs-search">
          {/* <div className="logs-limit">
            <label className="logs-limit-label">Total Data: </label>
            <InputNumber
              value={limit}
              onChange={handleLimit}
              className="logs-limit-input"
            />
          </div> */}

          <RangePicker
            className="logs-date-picker"
            onChange={handleDateChange}
          />
        </div>
        <div className="logs-dropdown">
          {dropdownType !== 'All' && dropdown && (
            <SelectDropdown
              list={dropdown}
              valueParams="categoryId"
              labelParams="name"
              selected={category}
              onSet={(selected) => setCategory(selected)}
              allTitle="All"
            />
          )}
          {/* <span>Filter by: </span> */}

          <SelectDropdown
            list={filterCategory}
            selected={dropdownType}
            onSet={(selected) => {
              if (selected === 'all') {
                selected = 'All';
              }
              dropdown && dropdown.length > 0 && setCategory('all');
              setFilterType(selected);
            }}
            allTitle="All"
            Icon={FilterIcon}
          />
        </div>
      </div>

      <div className="logs__table">
        {loading && filteredData ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="_id"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
