import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useChangeHandler } from 'hooks';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import { logout } from 'state/actions/auth';
import { toastr } from 'react-redux-toastr';
import { ReactComponent as LockLogo } from '../../assets/Iconly/Light-Outline/Lock-logo.svg';
import { mfa } from 'state/actions/auth';
import path from 'pages/Router/paths';
import NotFoudImage from 'assets/404.gif';
import { log } from 'utils';
import './MFA.css';
import { ScanIcon } from 'assets/icon';

const MFA = ({ history }) => {
  const { isAuth, userData, error, configUri } = useSelector(
    (state) => ({
      isAuth: !!state.auth.userData.id,
      userData: state.auth.userData,
      error: state.auth.error,
      configUri: state.auth.configUri,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');
  const [isShowCode, setIsShowCode] = useState(true);

  const otpInput = useRef(null);
  const onChangeHandler = useChangeHandler(setOtp);

  const userPath = isAuth ? path.ROOT : path.LOGIN;

  useEffect(() => {
    if (otpInput.current) {
      otpInput.current.input.focus();
    }
  }, [otpInput]);

  useEffect(() => {
    if (error) {
      toastr.error(error);
      setOtp('');
    }
    if (isAuth) {
      sessionStorage.setItem('activeMenu', 'dashboard');
      history.push(path.ROOT);
    }
    if (configUri) {
      log(configUri);
    }

    setIsShowCode(!userData.mfaEnabled);
  }, [error, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickLogoutHandler = () => {
    dispatch(logout());
    history.push(path.ROOT);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(mfa(otp.otp));
  };

  const showMFAInput = () => {
    setIsShowCode(false);
  };

  const showQRPage = () => {
    setIsShowCode(true);
  };

  return !userData ? (
    <div className="container is-widescreen">
      <h1 className="title">Error 404: page not found</h1>
      <p className="subtitle">The requested URL was not found</p>
      <Link className="button is-info is-normal" to={userPath}>
        Go Back
      </Link>
      <img src={NotFoudImage} alt="404 error" />
    </div>
  ) : (
    <div className="mfa">
      <div className="mfa-header">
        <img
          src={require('../../assets/logo-new.png')}
          className="login-logo"
          alt="logo"
        />
        <h1>Multi-factor Authentication</h1>
        <p>Enter an MFA code to complete sign in.</p>
      </div>
      {!isShowCode ? (
        userData.mfaEnabled ? (
          <>
            <form className="form-wrapper-mfa" onSubmit={onSubmitHandler}>
              <span>Code </span>
              <div>
                <Input
                  id="otp"
                  name="otp"
                  placeholder="Enter your MFA Code"
                  prefix={<LockLogo width="22" height="22" />}
                  ref={otpInput}
                  maxLength={6}
                  required
                  onChange={onChangeHandler}
                  value={otp.otp}
                />
              </div>
              <div className="buttons">
                <button type="submit" className="button mfa-button">
                  Submit
                </button>
                <div className="back" onClick={onClickLogoutHandler}>
                  <i className="feather-arrow-left" />
                  Back to Login
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <form className="form-wrapper-mfa" onSubmit={onSubmitHandler}>
              <div>Code </div>
              <div>
                <Input
                  id="otp"
                  name="otp"
                  placeholder="Enter your MFA Code"
                  prefix={<LockLogo width="22" height="22" />}
                  ref={otpInput}
                  maxLength={6}
                  required
                  onChange={onChangeHandler}
                  value={otp.otp}
                />
              </div>
              <div className="scan" onClick={showQRPage}>
                <ScanIcon width="24" height="24" />
                <p>Open Code</p>
              </div>
              <div className="buttons">
                <button type="submit" className="button mfa-button">
                  Submit
                </button>
                <div className="back" onClick={onClickLogoutHandler}>
                  <Link to="/login" className="btn-primary-ghost">
                    <i className="feather-arrow-left" />
                    Back to Login
                  </Link>
                </div>
              </div>
            </form>
          </>
        )
      ) : (
        <div className="mfa-qr">
          <div className="qr-wrapper">
            {configUri && (
              <QRCodeSVG width={160} height={160} value={configUri}></QRCodeSVG>
            )}
          </div>
          <ul className="step">
            <li>
              <span>1</span>
              Install Google Authenticator
            </li>
            <li>
              <span>2</span>
              Open the Application and tap the (+) button
            </li>
            <li>
              <span>3</span>
              Scan Barcode
            </li>
            <li>
              <span>4</span>
              Enter the one time code provided by the application
              <button className="btn-primary-ghost" onClick={showMFAInput}>
                Click Here
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* <h1 className="recover-password__title">
              Multi-factor Authentication
            </h1>
            <p className="recover-password__desc">
              Enter an MFA code to complete sign-in.
            </p>
            <form className="form-wrapper-mfa" onSubmit={onSubmitHandler}>
              <div className="form-mfa">
                <Input
                  id="otp"
                  name="otp"
                  prefix={<LockLogo width="22" height="20" />}
                  suffix={<label className="form-label">MFA Code</label>}
                  ref={otpInput}
                  maxLength={6}
                  required
                  onChange={onChangeHandler}
                  value={otp.otp}
                />
              </div>
              <div className="buttons">
                <button type="submit" className="button mfa-button">
                  Submit
                </button>
                <button
                  onClick={onClickLogoutHandler}
                  className="button mfa-back"
                >
                  Back
                </button>
              </div>
            </form> */}
    </div>
  );
  // : (
  // <div className="mfa">
  //   <p>Install Google Authenticator to your phone </p>
  //   <p>
  //     Open the Application and tap the plus (+) button, then scan the
  //     barcode
  //   </p>

  //   <p></p>
  //   <p>
  //     Enter the one time code provided by the application and click
  //     Submit
  //   </p>
  //   <form className="form-wrapper-mfa" onSubmit={onSubmitHandler}>
  //     <div className="form-mfa">
  //       <Input
  //         id="otp"
  //         name="otp"
  //         prefix={<LockLogo width="22" height="20" />}
  //         suffix={<label className="form-label">MFA Code</label>}
  //         maxLength={6}
  //         required
  //         onChange={onChangeHandler}
  //         value={otp.otp}
  //       />
  //     </div>
  //     <div className="buttons">
  //       <button type="submit" className="button mfa-button">
  //         Submit
  //       </button>
  //       <button
  //         onClick={onClickLogoutHandler}
  //         className="button mfa-back"
  //       >
  //         Back
  //       </button>
  //     </div>
  //   </form>
  // </div>
  // )
};

export default MFA;
