import { Form, Input, Modal, Radio, Tooltip, Upload, message } from 'antd';
// import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';
import { ExportIcon, TrashIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { tagsCleanUp } from 'state/actions/tag';

const ModalTagForm = ({
  tag,
  setTag,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) => {
  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.tags.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isDragged, setIsDragged] = useState(false);
  const [radio, setRadio] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(tagsCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (tag.file || tag.logo) {
      setImagePreviewUrl(
        tag.file
          ? tag.file && URL.createObjectURL(tag.file)
          : tag.logo && `${imgUrl}/tag/${tag.logo}`
      );
    }
    if (tag) {
      if (tag.isRibbon && !tag.isBuilding) setRadio('ribbon');
      if (!tag.isRibbon && tag.isBuilding) setRadio('building');
      if (!tag.isRibbon && !tag.isBuilding) setRadio('general');
    }
  }, [tag]);

  // const onChangeHandler = useChangeHandler(setTag);

  const handleChange = (name, value) => {
    setTag({
      ...tag,
      [name]: value,
    });
  };
  const handleChangeRadio = (e) => {
    const { value } = e.target;
    switch (value) {
      case 'building':
        setTag({ ...tag, isRibbon: false, isBuilding: true });
        break;
      case 'ribbon':
        setTag({ ...tag, isRibbon: true, isBuilding: false });
        break;
      default:
        setTag({ ...tag, isRibbon: false, isBuilding: false });
        break;
    }
  };

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...tag,
        id: tag._id,
        name: tag.name,
        file: tag.file,
        isRibbon: tag.isRibbon,
        ribbonLabel: tag.ribbonLabel,
        // orientation: values.orientation,
      });
      form.resetFields();
    }
  };

  const handleDeletePreviewImage = () => {
    setTag({ ...tag, file: null, logo: null });
    setImagePreviewUrl(null);
  };

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif'
    ) {
      if (file.size / 1000000 <= 10) {
        // setMedia((prevState) => ({ ...prevState, file, logo: null }));
        dispatch(
          setTag({
            ...tag,
            file,
          })
        );
        return false;
      }
      message.error('Maximum file size is 300MB');
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Tag' : 'Add New Tag'}
      closeIcon={<i className="feather-x" />}
      okText="Submit"
      okButtonProps={{
        className: 'button-submit',
      }}
      cancelButtonProps={{
        className: 'button-cancel',
      }}
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        // onChange={onChangeHandler}
      >
        <Form.Item
          label="Name"
          // name="name"
          rules={[{ required: true, message: `'Name' is required` }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input
            value={tag.name}
            placeholder="Enter tag's name"
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="isRibbon"
          valuePropName="isribbon"
          value={tag.isRibbon}
        >
          <Radio.Group onChange={handleChangeRadio} value={radio}>
            <Radio value="general">General Tag</Radio>
            <Radio value="building">Building Tag </Radio>
            <Radio value="ribbon">Ribbon Tag</Radio>
          </Radio.Group>
        </Form.Item>

        {tag.isRibbon && (
          <Form.Item
            name="ribbonLabel"
            valuePropName="ribbonLabel"
            value={tag.ribbonLabel}
          >
            <Input
              value={tag.ribbonLabel}
              placeholder="Enter ribbon's label"
              onChange={(e) => handleChange('ribbonLabel', e.target.value)}
            />
          </Form.Item>
        )}

        {radio === 'general' && (
          <Form.Item
            label="Tag Logo"
            // name="description"
            validateStatus={errors.logo ? 'error' : 'success'}
            help={errors.logo ? errors.logo : null}
          >
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <TrashIcon size={18} color="#CB3A31" />
                  </button>
                </Tooltip>
                {(tag.file && tag.file.type === 'video/mp4') ||
                (tag.logo?.split('.').at(-1) === 'mp4' && !tag.file) ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={imagePreviewUrl}
                  >
                    <source src={imagePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
                style={{ width: '100%' }}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (Up to 50MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </Form.Item>
        )}

        {/* <Form.Item
          label="Orientation"
          name="orientation"
          rules={[{ required: true, message: `This field is required` }]}
        >
          <Select placeholder="Select orientation">
            <Select.Option value="Portrait">Portrait</Select.Option>
            <Select.Option value="Landscape">Landscape</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

ModalTagForm.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    orientation: PropTypes.string,
  }),
  setTag: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ModalTagForm;
