/* eslint-disable jsx-a11y/label-has-associated-control */
import { ColorPicker, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { ExportIcon } from 'assets/icon/dynamic';
import { log } from 'utils';
// import { colorsUpdate } from 'state/actions/configuration';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import { arrayMoveImmutable } from 'array-move';
import { DragIcon } from 'assets/icon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { filterAccess } from 'components/Report/utils';
import './ColorForm.css';
import ModalResetContent from 'components/ModalResetContent';
import { configUpdate } from 'state/actions/configuration';

const ColorForm = ({ configuration, userData, setConfiguration, loading }) => {
  const { siteId } = useParams();

  // const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    log(configuration, 'configuration');
  }, [configuration]); //eslint-disable-line
  useEffect(() => {
    setErrors();
  }, [loading]); //eslint-disable-line

  const onSubmitHandler = (event) => {
    event.preventDefault();

    log(configuration, 'poi');
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalConfirmationContent />,
      onOk: () =>
        dispatch(
          configUpdate({
            ...configuration,
            siteId,
          })
        ),
    });
  };

  const onChangeDescription = (e) => {
    e.preventDefault();
    log(e, 'val description');
    const newConfig = { ...configuration };
    newConfig.colors[e.target.name].name = e.target.value;

    setConfiguration((prevState) => ({
      ...prevState,
      colors: newConfig.colors,
    }));
  };

  const onChangeColor = (val, ind) => {
    const newConfig = { ...configuration };
    newConfig.colors[ind].color = val.toHexString();

    setConfiguration((prevState) => ({
      ...prevState,
      colors: newConfig.colors,
    }));
  };

  const handleAddContent = (event) => {
    event.preventDefault();
    const newConfig = { ...configuration };
    newConfig.colors.push({ logo: null, description: '' });
    setConfiguration({ ...newConfig });
  };

  const handleDeleteTransport = (index) => {
    const newConfig = { ...configuration };
    newConfig.colors.splice(index, 1);
    setConfiguration({ ...newConfig });
  };
  // const canSubmit = poi.name && poi.description;

  const onReset = (e) => {
    e.preventDefault();

    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,

          colors: [],
        };
        setConfiguration({ ...newConf });
      },
    });
  };

  return (
    <div className="colors-form">
      <h1>Colors</h1>
      <form className="form-wrapper">
        <div>
          <div className="form">
            <DragDropContext
              onDragEnd={(result) => {
                const colors = arrayMoveImmutable(
                  configuration.colors,
                  result.source.index,
                  result.destination.index
                );
                setConfiguration({ ...configuration, colors });
              }}
            >
              <DragContainer
                configuration={configuration}
                errors={errors}
                handleDeleteTransport={handleDeleteTransport}
                handleAddContent={handleAddContent}
                onChangeDescription={onChangeDescription}
                onChangeColor={onChangeColor}
                distance={1}
              />
            </DragDropContext>
            <button className="btn-outline-primary" onClick={handleAddContent}>
              Add New Color
            </button>
          </div>

          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const DragContainer = ({
  configuration,
  errors,
  handleDeleteTransport,
  onChangeDescription,
  onChangeColor,
}) => {
  return (
    <Droppable droppableId="droppableId">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {configuration.colors &&
            configuration.colors.map((transport, ind) => {
              return (
                <DragElement
                  ind={ind}
                  errors={errors}
                  index={ind}
                  transport={transport}
                  onChangeDescription={onChangeDescription}
                  onChangeColor={onChangeColor}
                  handleDeleteTransport={handleDeleteTransport}
                />
              );
            })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const DragElement = ({
  ind,
  errors,
  transport,
  onChangeDescription,
  onChangeColor,
  handleDeleteTransport,
}) => {
  return (
    <Draggable draggableId={`draggable-${ind}`} index={ind} key={ind}>
      {(provided) => (
        <div
          className="form-row"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DragIcon />
          <Input
            placeholder="name"
            className={`${
              errors && errors.errors && errors.errors.description
                ? 'has-error'
                : ''
            }`}
            name={ind}
            value={transport.name}
            onChange={onChangeDescription}
          />
          <ColorPicker
            showText
            value={transport.color}
            onChange={(val) => onChangeColor(val, ind)}
          />
          <button
            type="button"
            onClick={() => handleDeleteTransport(ind)}
            className="btn-remove"
          >
            <i className="feather-x" />
          </button>
        </div>
      )}
    </Draggable>
  );
};

export default ColorForm;
