/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { SaveIcon } from 'assets/icon';

const ModalConfirmationContent = () => {
  return (
    <div className='confirm-modal-content'>
          <SaveIcon />
          <h1 >Save & Apply Changes</h1>
          <p>Are you sure you want to save and apply the new changes? All the previous inputs will be overwritten. 
This action cannot be undone.</p>
        </div>
  );
};

export default ModalConfirmationContent;
