import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const STATIC_MAPS_FETCH_DATA_INIT = createAction(
  'STATIC_MAPS_FETCH_DATA_INIT'
);
export const STATIC_MAPS_FETCH_DATA_SUCCESS = createAction(
  'STATIC_MAPS_FETCH_DATA_SUCCESS'
);
export const STATIC_MAPS_FETCH_DATA_FAIL = createAction(
  'STATIC_MAPS_FETCH_DATA_FAIL'
);

export const STATIC_MAPS_CREATE_MAPS_INIT = createAction(
  'STATIC_MAPS_CREATE_MAPS_INIT'
);
export const STATIC_MAPS_CREATE_MAPS_SUCCESS = createAction(
  'STATIC_MAPS_CREATE_MAPS_SUCCESS'
);
export const STATIC_MAPS_CREATE_MAPS_FAIL = createAction(
  'STATIC_MAPS_CREATE_MAPS_FAIL'
);

export const STATIC_MAPS_MODIFY_MAPS_INIT = createAction(
  'STATIC_MAPS_MODIFY_MAPS_INIT'
);
export const STATIC_MAPS_MODIFY_MAPS_SUCCESS = createAction(
  'STATIC_MAPS_MODIFY_MAPS_SUCCESS'
);
export const STATIC_MAPS_MODIFY_MAPS_FAIL = createAction(
  'STATIC_MAPS_MODIFY_MAPS_FAIL'
);

export const STATIC_MAPS_CLEAN_UP = createAction('STATIC_MAPS_CLEAN_UP');

export const STATIC_MAPS_CLEAR_DATA = createAction('STATIC_MAPS_CLEAR_DATA');

export const STATIC_MAPS_CLEAR_DATA_LOGOUT = createAction(
  'STATIC_MAPS_CLEAR_DATA_LOGOUT'
);

export const fetchMapsStatic = ({ mapsId, siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(STATIC_MAPS_FETCH_DATA_INIT());
    if (siteId) {
      let maps;
      try {
        maps = await Axios.get(`${url}/map-static/get`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }).then((res) => res.data.data);
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(STATIC_MAPS_FETCH_DATA_FAIL({ error }));
      }
      return dispatch(
        STATIC_MAPS_FETCH_DATA_SUCCESS({
          map: maps,
        })
      );
    }
  };
};

export const clearMapsStaticData = () => {
  return (dispatch) => {
    dispatch(STATIC_MAPS_CLEAR_DATA());
  };
};

export const clearMapsStaticDataFileut = () => {
  return (dispatch) => {
    dispatch(STATIC_MAPS_CLEAR_DATA_LOGOUT());
  };
};

export const createMapsStatic = ({
  name,
  images,
  imagesMap,
  data,
  siteId,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(STATIC_MAPS_CREATE_MAPS_INIT());
    const formData = new FormData();
    images &&
      images.map((file, i) => {
        formData.append(`file`, file);
        return file;
      });

    // formData.append('imagesMap', imagesMap);

    formData.set('name', name);
    formData.set('siteId', siteId);
    data &&
      data.objects &&
      data.objects.map((o) => {
        if (o.type === 'image') {
          o.src = '';
        }
        return o;
      });
    formData.set('data', JSON.stringify(data));
    let createMaps;

    try {
      createMaps = await Axios.post(`${url}/map-static/create`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      }).then((res) => res.data.data);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        STATIC_MAPS_CREATE_MAPS_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Maps saved successfully');
    return dispatch(STATIC_MAPS_CREATE_MAPS_SUCCESS({ maps: createMaps }));
  };
};

export const modifyMapsStatic = ({
  name,
  images,
  data,
  siteId,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(STATIC_MAPS_MODIFY_MAPS_INIT());

    const formData = new FormData();
    images.map((file) => {
      formData.append('files[]', file);
      return file;
    });
    formData.set('name', name);
    formData.set('siteId', siteId);
    formData.set('data', data);
    let updateMap;

    try {
      updateMap = await Axios.post(`${url}/map-static/edit`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then((res) => res.data.data);
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        STATIC_MAPS_CREATE_MAPS_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Maps updated successfully');

    return dispatch(
      STATIC_MAPS_MODIFY_MAPS_SUCCESS({ maps: updateMap, siteId })
    );
  };
};

export const mapsCleanUp = () => (dispatch) => dispatch(STATIC_MAPS_CLEAN_UP());
