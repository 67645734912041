import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  MAPVIEWER_FETCH_DATA_INIT,
  MAPVIEWER_FETCH_DATA_SUCCESS,
  MAPVIEWER_FETCH_DATA_FAIL,
  MAPVIEWER_DELETE_MAPVIEWER_INIT,
  MAPVIEWER_DELETE_MAPVIEWER_SUCCESS,
  MAPVIEWER_DELETE_MAPVIEWER_FAIL,
  MAPVIEWER_CLEAR_DATA,
  MAPVIEWER_CREATE_MAPVIEWER_INIT,
  MAPVIEWER_CREATE_MAPVIEWER_SUCCESS,
  MAPVIEWER_CREATE_MAPVIEWER_FAIL,
  MAPVIEWER_MODIFY_MAPVIEWER_INIT,
  MAPVIEWER_MODIFY_MAPVIEWER_SUCCESS,
  MAPVIEWER_MODIFY_MAPVIEWER_FAIL,
} from 'state/actions/mapviewer';

const initialState = {
  list: [],
  map: {
    name: '',
    description: '',
    fileUrl: null,
    siteId: '',
    paths: [],
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const mapviewerReducer = createReducer(
  {
    [MAPVIEWER_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MAPVIEWER_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      map: payload.map || initialState.map,
      loading: false,
      error: null,
    }),
    [MAPVIEWER_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPVIEWER_DELETE_MAPVIEWER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPVIEWER_DELETE_MAPVIEWER_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      deleted: true,
    }),
    [MAPVIEWER_DELETE_MAPVIEWER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPVIEWER_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [MAPVIEWER_CREATE_MAPVIEWER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPVIEWER_CREATE_MAPVIEWER_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [MAPVIEWER_CREATE_MAPVIEWER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPVIEWER_MODIFY_MAPVIEWER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPVIEWER_MODIFY_MAPVIEWER_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.maps.name,
                description: payload.maps.description,
                fileUrl: payload.maps.fileUrl,
                siteId: payload.maps.siteId,
                paths: payload.maps.paths,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.maps.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [MAPVIEWER_MODIFY_MAPVIEWER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
