import React from 'react';
const CheckBullet = ({ color = '#000', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0013 0.666748C4.40964 0.666748 0.667969 4.40841 0.667969 9.00008C0.667969 13.5917 4.40964 17.3334 9.0013 17.3334C13.593 17.3334 17.3346 13.5917 17.3346 9.00008C17.3346 4.40841 13.593 0.666748 9.0013 0.666748ZM12.9846 7.08341L8.25963 11.8084C8.14297 11.9251 7.98464 11.9917 7.81797 11.9917C7.6513 11.9917 7.49297 11.9251 7.3763 11.8084L5.01797 9.45008C4.7763 9.20841 4.7763 8.80841 5.01797 8.56675C5.25963 8.32508 5.65964 8.32508 5.9013 8.56675L7.81797 10.4834L12.1013 6.20008C12.343 5.95842 12.743 5.95842 12.9846 6.20008C13.2263 6.44175 13.2263 6.83341 12.9846 7.08341Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckBullet;
