import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const PANEL_FETCH_DATA_INIT = createAction('PANEL_FETCH_DATA_INIT');
export const PANEL_FETCH_DATA_SUCCESS = createAction(
  'PANEL_FETCH_DATA_SUCCESS'
);
export const PANEL_FETCH_DATA_FAIL = createAction('PANEL_FETCH_DATA_FAIL');

export const PANEL_GET_PANEL_SUCCESS = createAction('PANEL_GET_PANEL_SUCCESS');

export const PANEL_MODIFY_PANEL_INIT = createAction('PANEL_MODIFY_PANEL_INIT');
export const PANEL_MODIFY_PANEL_SUCCESS = createAction(
  'PANEL_MODIFY_PANEL_SUCCESS'
);
export const PANEL_MODIFY_PANEL_FAIL = createAction('PANEL_MODIFY_PANEL_FAIL');

export const fetchPanel = () => {
  return async (dispatch, getState) => {
    dispatch(PANEL_FETCH_DATA_INIT());
    let panel;

    try {
      const res = await axios.get(`${url}/panel`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      panel = res.data.data;
      log(panel);
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(PANEL_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      PANEL_FETCH_DATA_SUCCESS({
        data: panel,
      })
    );
  };
};

export const modifyPanel = ({ expirationDuration, failedAttempt }) => {
  return async (dispatch, getState) => {
    dispatch(PANEL_MODIFY_PANEL_INIT());

    const data = {
      expirationDuration,
      failedAttempt,
    };

    let response;

    try {
      response = await axios.post(`${url}/panel/edit`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;

      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        PANEL_MODIFY_PANEL_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Data successfully updated');
    return dispatch(
      PANEL_MODIFY_PANEL_SUCCESS({
        data: response.data.data,
        message: response.data.message,
      })
    );
  };
};
