import { createReducer } from 'redux-act';

import {
  PLAYLIST_FETCH_DATA_INIT,
  PLAYLIST_FETCH_DATA_SUCCESS,
  PLAYLIST_FETCH_DATA_FAIL,
  PLAYLIST_DELETE_PLAYLIST_INIT,
  PLAYLIST_DELETE_PLAYLIST_SUCCESS,
  PLAYLIST_DELETE_PLAYLIST_FAIL,
  PLAYLIST_CLEAR_DATA,
  PLAYLIST_CREATE_PLAYLIST_INIT,
  PLAYLIST_CREATE_PLAYLIST_SUCCESS,
  PLAYLIST_CREATE_PLAYLIST_FAIL,
  PLAYLIST_MODIFY_PLAYLIST_INIT,
  PLAYLIST_MODIFY_PLAYLIST_SUCCESS,
  PLAYLIST_MODIFY_PLAYLIST_FAIL,
  PLAYLIST_CLEAR_PLAYLIST_INIT,
  PLAYLIST_CLEAR_PLAYLIST_RESET,
  PLAYLIST_CLEAR_PLAYLIST_SUCCESS,
  PLAYLIST_CLEAR_PLAYLIST_FAIL,
  PLAYLIST_CLEAN_UP,
  PLAYLIST_CLEAR_DATA_LOGOUT,
  PLAYLIST_BULK,
} from 'state/actions/playlist';

const initialState = {
  list: [],
  playlist: {
    name: '',
    description: '',
    type: '',
    posts: [],
  },
  loading: false,
  error: {
    errors: null,
    messsage: '',
  },
  success: false,
  deleted: false,
  updated: false,
  added: false,
};

export const playlistReducer = createReducer(
  {
    [PLAYLIST_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [PLAYLIST_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      playlist: payload.playlist || initialState.playlist,
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
    }),
    [PLAYLIST_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PLAYLIST_DELETE_PLAYLIST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PLAYLIST_DELETE_PLAYLIST_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      deleted: true,
      updated: true,
    }),
    [PLAYLIST_DELETE_PLAYLIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PLAYLIST_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [PLAYLIST_CREATE_PLAYLIST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PLAYLIST_CREATE_PLAYLIST_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.media),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      success: true,
      updated: true,
    }),
    [PLAYLIST_CREATE_PLAYLIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PLAYLIST_MODIFY_PLAYLIST_INIT]: (state) => ({
      ...state,
      loading: true,
      added: false,
    }),
    [PLAYLIST_MODIFY_PLAYLIST_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.playlist._id) {
              return {
                _id: payload.playlist._id,
                name: payload.playlist.name,
                description: payload.playlist.description,
                siteId: payload.playlist.siteId,
                posts: payload.playlist.posts,
                type: payload.playlist.type,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      success: true,
      added: true,
      updated: false,
    }),
    [PLAYLIST_MODIFY_PLAYLIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PLAYLIST_CLEAR_PLAYLIST_INIT]: (state) => ({
      ...state,
      loading: true,
      updated: false,
    }),
    [PLAYLIST_CLEAR_PLAYLIST_RESET]: (state) => ({
      ...state,
      loading: false,
      updated: false,
    }),
    [PLAYLIST_CLEAR_PLAYLIST_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.playlist._id) {
              return {
                _id: payload.playlist._id,
                name: payload.playlist.name,
                description: payload.playlist.description,
                siteId: payload.playlist.siteId,
                posts: payload.playlist.posts,
                type: payload.playlist.type,
              };
            }
            return elem;
          }),
      loading: false,
      deleted: true,
      error: {
        errors: null,
        messsage: '',
      },
      success: true,
      updated: true,
    }),
    [PLAYLIST_CLEAR_PLAYLIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PLAYLIST_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      success: false,
      deleted: false,
      updated: false,
      added: true,
    }),
    [PLAYLIST_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [PLAYLIST_BULK]: (state, payload) => ({
      ...state,
      list: payload.medias,
    }),
  },
  initialState
);
