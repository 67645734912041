import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

// import Layout from 'components/Layout';
import paths from '../paths';
import BaseLayout from 'design-components/Layout/BaseLayout';

const PrivateRoute = ({ path, component: Component, ...props }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { id } = useSelector(
    (state) => ({
      id: state.auth.userData.id,
      mapId: state.auth.userData.mapId,
    }),
    shallowEqual
  );

  const handleToggle = (event, menu) => {
    setCollapsed(!collapsed);
    if (menu) {
      sessionStorage.setItem('activeMenu', menu);
    }
  };

  return (
    <Route
      exact
      path={path}
      render={(props) =>
        id ? (
          <BaseLayout
            collapsed={collapsed}
            handleToggle={handleToggle}
            setCollapsed={setCollapsed}
            {...props}
          >
            <Component
              {...props}
              collapsed={collapsed}
              handleToggle={handleToggle}
              setCollapsed={setCollapsed}
            />
          </BaseLayout>
        ) : (
          <Redirect to={paths.LOGIN} />
        )
      }
    />
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
