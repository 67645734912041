// import './RecoverPassword.scss';
import { Input, Alert } from 'antd';
import { useChangeHandler, useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { readTokenFromEmail, sendTokenViaEmail } from 'state/actions/auth';
import { ReactComponent as EmailLogo } from '../../assets/Iconly/Light-Outline/Email-logo.svg';
import './RecoverPassword.css';
import { regexEmail } from 'utils/regex';
import { ReactComponent as ErrorLogo } from '../../assets/Iconly/Bulk/Info-Circle.svg';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RecoverPassword = () => {
  const { loading, isAuth, isSuccessReset } = useSelector(
    (state) => ({
      loading: state.auth.loading,
      isAuth: !!state.auth.userData.userId,
      isSuccessReset: state.auth.isSuccessReset,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const query = useQuery();

  const [errors, setErrors] = useState(null);
  const [focus, setFocus] = useState({ email: false });

  useEffect(() => {
    if (query.get('token')) {
      const token = query.get('token');
      dispatch(readTokenFromEmail(token));
    }
  }, [query.get('token')]); // eslint-disable-line react-hooks/exhaustive-deps

  const [resetPasswordData, setResetPasswordData] = useState({
    email: '',
  });

  const onChangeHandler = useChangeHandler(setResetPasswordData);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (!regexEmail(resetPasswordData.email) && resetPasswordData.email) {
      setErrors({
        ...errors,
        msg: 'Email field cannot contain special character',
      });
    } else {
      dispatch(sendTokenViaEmail(resetPasswordData.email));
      setResetPasswordData({
        email: '',
      });
    }
  };

  const redirect = isAuth && <Redirect to={paths.ROOT} />;

  const tokenConfirmed = isSuccessReset && (
    <Redirect to={paths.RESET_PASSWORD} />
  );

  const backMessage = useFormatMessage('ResetPassword.back');

  return (
    <section className="recover-password">
      {tokenConfirmed}
      {redirect}
      <div className="recover-password-header">
        <img
          src={require('../../assets/logo-new.png')}
          className="login-logo"
          alt="logo"
        />
        <h1 className="recover-password__title">Forget Password</h1>
        <p className="recover-password__desc">
          Don’t worry, happens to the best of us.
        </p>
      </div>
      {errors && (
        <Alert
          message={errors.msg}
          type="error"
          showIcon
          closable
          icon={<ErrorLogo width="16.67" height="16.67" />}
        />
      )}
      <form className="recover-password__form" onSubmit={onSubmitHandler}>
        <label>Email</label>
        <div
          className={`recover-password__form-controls ${
            errors ? 'error-recover' : ''
          } ${focus.email ? 'focus' : ''}`}
        >
          <Input
            id="email"
            type="email"
            required
            value={resetPasswordData.email}
            name="email"
            onChange={onChangeHandler}
            prefix={<EmailLogo width="22" height="20" />}
            // suffix={<label className="recover-password__label">Email</label>}
            placeholder="Enter your email"
            onFocus={() => setFocus({ ...focus, email: true })}
            onBlur={() => setFocus({ ...focus, email: false })}
          />
        </div>
        <div className="recover-password__form-controls">
          {loading ? (
            <button type="button" disabled className="recover-password__button">
              Loading...
            </button>
          ) : (
            <button type="submit" className="button recover-password__button">
              Reset Password
            </button>
          )}

          <Link to={paths.LOGIN} className="button recover-password__go-back">
            <i className="feather-arrow-left" /> {backMessage}
          </Link>
        </div>
      </form>
    </section>
  );
};

export default RecoverPassword;
