import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const TAG_FETCH_DATA_INIT = createAction('TAG_FETCH_DATA_INIT');
export const TAG_FETCH_DATA_SUCCESS = createAction('TAG_FETCH_DATA_SUCCESS');
export const TAG_FETCH_DATA_FAIL = createAction('TAG_FETCH_DATA_FAIL');

export const TAG_DELETE_INIT = createAction('TAG_DELETE_INIT');
export const TAG_DELETE_SUCCESS = createAction('TAG_DELETE_SUCCESS');
export const TAG_DELETE_FAIL = createAction('TAG_DELETE_FAIL');

export const TAG_CLEAR_DATA = createAction('TAG_CLEAR_DATA');

export const TAG_CREATE_INIT = createAction('TAG_CREATE_INIT');
export const TAG_CREATE_SUCCESS = createAction('TAG_CREATE_SUCCESS');
export const TAG_CREATE_FAIL = createAction('TAG_CREATE_FAIL');

export const TAG_MODIFY_INIT = createAction('TAG_MODIFY_INIT');
export const TAG_MODIFY_SUCCESS = createAction('TAG_MODIFY_SUCCESS');
export const TAG_MODIFY_FAIL = createAction('TAG_MODIFY_FAIL');
export const SET_TAG = createAction('SET_TAG');

export const TAG_CLEAN_UP = createAction('TAG_CLEAN_UP');

export const TAG_CLEAR_DATA_LOGOUT = createAction('TAG_CLEAR_DATA_LOGOUT');

export const fetchTags = ({ tagId, siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(TAG_FETCH_DATA_INIT());
    if (tagId) {
      let tagData;
      try {
        tagData = await Axios.get(`${url}/tag`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(TAG_FETCH_DATA_FAIL({ error }));
      }
      const tag = { ...tagData, id: tagId };
      return dispatch(
        TAG_FETCH_DATA_SUCCESS({
          list: getState().tags.list,
          tag,
        })
      );
    }

    if (siteId) {
      let tags;
      try {
        tags = await Axios.get(`${url}/tag`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(TAG_FETCH_DATA_FAIL({ error }));
      }

      tags = tags.data.data.filter((tag) => tag.siteId === siteId);

      return dispatch(
        TAG_FETCH_DATA_SUCCESS({
          list: tags,
          tag: getState().tags.tag,
        })
      );
    }
  };
};

export const deleteTag = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(TAG_DELETE_INIT());

    try {
      await Axios.delete(`${url}/tag/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TAG_DELETE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The tag was deleted.');
    cb();
    dispatch(TAG_DELETE_SUCCESS());
    return dispatch(fetchTags({ siteId }));
  };
};

export const clearCategoriesData = () => {
  return (dispatch) => {
    dispatch(TAG_CLEAR_DATA());
  };
};

export const clearCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(TAG_CLEAR_DATA_LOGOUT());
  };
};

export const createTag = (
  { name, siteId, file, isRibbon, isBuilding, ribbonLabel },
  cb
) => {
  return async (dispatch) => {
    dispatch(TAG_CREATE_INIT());
    let createCategory;
    let ribbon = isRibbon;
    let building = isBuilding;
    if (isRibbon === undefined) ribbon = false;
    if (isBuilding === undefined) building = false;

    const tag = new FormData();
    tag.set('name', name);
    tag.set('siteId', siteId);
    tag.set('ribbonLabel', ribbonLabel);
    tag.set('isRibbon', JSON.stringify(ribbon));
    tag.set('isBuilding', JSON.stringify(building));
    if (file) tag.append('file', file);

    try {
      createCategory = await Axios.post(`${url}/tag/create`, tag, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      log(createCategory, '<<< created tag');
    } catch (error) {
      log(error.response, '<<< ERROR CREATE TAG');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TAG_CREATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category created successfully');
    cb();
    dispatch(TAG_CREATE_SUCCESS());
    return dispatch(fetchTags({ siteId }));
  };
};

export const modifyTag = (
  { id, name, siteId, logo, file, ribbonLabel, isRibbon, isBuilding },
  cb
) => {
  return async (dispatch) => {
    dispatch(TAG_MODIFY_INIT());

    const tagData = new FormData();
    tagData.set('id', id);
    tagData.set('name', name);
    tagData.set('siteId', siteId);
    tagData.set('ribbonLabel', ribbonLabel);
    tagData.set('isRibbon', JSON.stringify(isRibbon));
    tagData.set('isBuilding', JSON.stringify(isBuilding));
    tagData.append('logo', logo);
    if (file) tagData.append('file', file);

    try {
      await Axios.post(`${url}/tag/edit`, tagData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TAG_MODIFY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category updated successfully');
    cb();

    dispatch(TAG_MODIFY_SUCCESS());
    return dispatch(fetchTags({ siteId }));
  };
};

export const tagsCleanUp = () => (dispatch) => dispatch(TAG_CLEAN_UP());

export const setTag = (data, cb) => {
  return (dispatch) => {
    dispatch(SET_TAG({ data }));
    if (cb) {
      cb();
    }
  };
};
