import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const ORGANIZATIONS_FETCH_DATA_INIT = createAction(
  'ORGANIZATIONS_FETCH_DATA_INIT'
);
export const ORGANIZATIONS_FETCH_DATA_SUCCESS = createAction(
  'ORGANIZATIONS_FETCH_DATA_SUCCESS'
);
export const ORGANIZATIONS_FETCH_DATA_FAIL = createAction(
  'ORGANIZATIONS_FETCH_DATA_FAIL'
);

export const ORGANIZATIONS_DELETE_ORGANIZATION_INIT = createAction(
  'ORGANIZATIONS_DELETE_ORGANIZATION_INIT'
);
export const ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS = createAction(
  'ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS'
);
export const ORGANIZATIONS_DELETE_ORGANIZATION_FAIL = createAction(
  'ORGANIZATIONS_DELETE_ORGANIZATION_FAIL'
);

export const ORGANIZATIONS_CLEAR_DATA = createAction(
  'ORGANIZATIONS_CLEAR_DATA'
);

export const ORGANIZATIONS_CREATE_ORGANIZATION_INIT = createAction(
  'ORGANIZATIONS_CREATE_ORGANIZATION_INIT'
);
export const ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS = createAction(
  'ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS'
);
export const ORGANIZATIONS_CREATE_ORGANIZATION_FAIL = createAction(
  'ORGANIZATIONS_CREATE_ORGANIZATION_FAIL'
);

export const ORGANIZATIONS_MODIFY_ORGANIZATION_INIT = createAction(
  'ORGANIZATIONS_MODIFY_ORGANIZATION_INIT'
);
export const ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS = createAction(
  'ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS'
);
export const ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL = createAction(
  'ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL'
);

export const ORGANIZATIONS_CLEAN_UP = createAction('ORGANIZATIONS_CLEAN_UP');

export const ORGANIZATIONS_CLEAR_DATA_LOGOUT = createAction(
  'ORGANIZATIONS_CLEAR_DATA_LOGOUT'
);

export const fetchOrganizations = (organizationId = '') => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_FETCH_DATA_INIT());
    log(organizationId);
    if (organizationId) {
      let organizationData;
      try {
        const res = await axios.post(
          `${url}/organization/get`,
          {
            id: organizationId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        organizationData = res.data.data;
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(ORGANIZATIONS_FETCH_DATA_FAIL({ error }));
      }

      const organization = { ...organizationData, id: organizationId };
      log(organization);
      log(organizationData);
      return dispatch(
        ORGANIZATIONS_FETCH_DATA_SUCCESS({
          list: getState().organizations.list,
          organization: organization,
        })
      );
    }

    const { uid } = localStorage.getItem('uid');

    let organizations;

    try {
      const res = await axios.get(`${url}/organization/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      organizations = res.data.data;
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ORGANIZATIONS_FETCH_DATA_FAIL({ error }));
    }

    const organizationsData = organizations
      ? Object.entries(organizations).map(([key, value]) => ({
          id: key,
          ...value,
        }))
      : [];

    return dispatch(
      ORGANIZATIONS_FETCH_DATA_SUCCESS({
        list: organizationsData.filter(
          (organization) => organization.id !== uid
        ),
        organization: getState().organizations.organization,
      })
    );
  };
};

export const deleteOrganization = (id) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_DELETE_ORGANIZATION_INIT());

    try {
      await axios.delete(`${url}/organization/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_DELETE_ORGANIZATION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The organization was deleted.');
    return dispatch(ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS({ id }));
  };
};

export const createOrganization = ({
  name,
  description,
  organizationId,
  organizationName,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_CREATE_ORGANIZATION_INIT());

    const organization = {
      name: name,
      description: description,
      organizationId: organizationId,
      organizationName: organizationName,
      modifiedAt: modifiedAt,
      modifiedBy: modifiedBy,
    };

    try {
      await axios.post(`${url}/organization/create`, organization, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_CREATE_ORGANIZATION_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'organization created successfully');
    return dispatch(
      ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS({ organization: organization })
    );
  };
};

export const modifyOrganization = ({
  name,
  description,
  organizationId,
  organizationName,
  modifiedAt,
  modifiedBy,
  id,
}) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_MODIFY_ORGANIZATION_INIT());

    const organizationData = {
      name: name,
      description: description,
      organizationId: organizationId,
      organizationName: organizationName,
    };

    try {
      await axios.post(`${url}/organization/${id}`, organizationData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Organization updated successfully');

    return dispatch(
      ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS({
        organization: organizationData,
        id,
      })
    );
  };
};

export const organizationsCleanUp = () => (dispatch) =>
  dispatch(ORGANIZATIONS_CLEAN_UP());
