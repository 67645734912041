import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CategoryDropdown from 'components/CategoryDropdown';
import { DatePicker } from 'antd';
import { EmptyAnalyticIcon, FilterIcon } from 'assets/icon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  downloadAnalytics,
  downloadAnalyticsLeaderboard,
  fetchAnalytics,
  fetchLeaderboard,
} from 'state/actions/analytic';

import FilterDropdown from 'components/FilterDropdown';
import { ExportIcon } from 'assets/icon/dynamic';
import LeaderboardReport from 'components/LeaderboardReport';
import { fetchScreens } from 'state/actions/screens';
import AnalyticReport from 'components/AnalyticReport';
import ProfileAnalyzerReport from 'components/ProfileAnalyzerReport';
import VehicleAnalyticReport from 'components/VehicleAnalyticReport';
import './Analytic.css';
import { getAccess } from 'utils';

const Analytics = () => {
  const { siteId } = useParams();

  // select report type 'poi' or 'place'
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);

  const [locationOpen, setLocationOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [tempSelectedLocation, setTempSelectedLocation] = useState([]);

  // select report type 'poi' or 'place'
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [tempSelectedDevice, setTempSelectedDevice] = useState([]);
  const [access, setAccess] = useState();
  const [chartType, setChartType] = useState();

  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const {
    analytics,
    loading,
    screens,
    locations,
    userData,
    role,
  } = useSelector(
    (state) => ({
      analytics: state.analytic.data,
      loading: state.analytic.loading,
      locations: state.analytic.locations,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      screens: state.screens.list,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
    if (userData && userData.roleAccess) {
      setAccess(getAccess(userData, 'analytic', siteId));
    }
  }, [siteId]); // eslint-disable-line
  useEffect(() => {
    if (role === 'superadmin') {
      const charts = [
        { _id: 'place', name: 'Place' },
        { _id: 'poi', name: 'POI' },
        { _id: 'category', name: 'Category' },
        { _id: 'leaderboard', name: 'Kiosk Performance' },
        { _id: 'trafficcounter', name: 'Traffic Counter' },
        { _id: 'profileanalyzer', name: 'Profile Analyzer' },
        { _id: 'vehiclecounter', name: 'Vehicle Counter' },
      ];
      setChartType(charts);
    } else if (access) {
      const charts = [];
      if (access.wayfinding) {
        charts.push(
          { _id: 'place', name: 'Place' },
          { _id: 'poi', name: 'POI' },
          { _id: 'category', name: 'Category' },
          { _id: 'leaderboard', name: 'Kiosk Performance' }
        );
      }

      if (access.camera) {
        charts.push(
          { _id: 'trafficcounter', name: 'Traffic Counter' },
          { _id: 'profileanalyzer', name: 'Profile Analyzer' },
          { _id: 'vehiclecounter', name: 'Vehicle Counter' }
        );
      }

      setChartType(charts);
    }
  }, [access, role]); // eslint-disable-line
  useEffect(() => {
    if (selectedType) {
      console.log('fetch analytics');
      if (selectedType === 'leaderboard') {
        console.log('leaderboard');
        dispatch(
          fetchLeaderboard({
            start: dateRange.startDate,
            end: dateRange.endDate,
            adsumScreenIds: selectedDevice,
            siteId,
          })
        );
      } else {
        dispatch(
          fetchAnalytics({
            start: dateRange.startDate,
            end: dateRange.endDate,
            type: selectedType,
            adsumScreenIds: selectedDevice,
            locations: selectedLocation,
            siteId,
          })
        );
      }
    }
  }, [selectedType, dateRange, selectedDevice, selectedLocation]); // eslint-disable-line

  useEffect(() => {
    const ids = screens.map((screen) =>
      screen.adsumScreenId ? screen.adsumScreenId : screen._id
    );
    setTempSelectedDevice([...ids]);
    setSelectedDevice([...ids]);
  }, [screens]); // eslint-disable-line

  const handleSetType = () => {
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
  };
  const handleSetDevice = () => {
    setSelectedDevice(tempSelectedDevice);
    setDeviceOpen(false);
  };
  const handleSetLocation = () => {
    setSelectedLocation(tempSelectedLocation);
    setLocationOpen(false);
  };

  const getType = (type) => {
    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    setDateRange((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };
  return (
    <>
      <div className="analytic">
        <div className="analytic-header">
          <div className="analytic-header-left">
            {chartType && (
              <div
                className="playlist-wrapper"
                onClick={() => !loading && setTypeOpen(!typeOpen)}
              >
                <div className="playlist-selection">
                  <FilterIcon />
                  <span>{!selectedType ? 'Type' : getType(selectedType)}</span>
                </div>
                <i
                  className={`feather-chevron-down ${
                    typeOpen ? 'playlist-open' : 'playlist-closed'
                  }`}
                />

                <CategoryDropdown
                  categories={chartType}
                  modalOpen={typeOpen}
                  handleSetPlaylist={handleSetType}
                  setTempSelectedCategory={(id) => setTempSelectedType(id)}
                  tempSelectedCategory={tempSelectedType}
                />
              </div>
            )}
            {selectedType !== 'profileanalyzer' &&
              selectedType !== 'vehiclecounter' &&
              selectedType !== 'trafficcounter' && (
                <div
                  className="playlist-wrapper"
                  onClick={() => !loading && setDeviceOpen(!deviceOpen)}
                >
                  <div className="playlist-selection">
                    <FilterIcon />
                    <span>Show All</span>
                  </div>
                  <i
                    className={`feather-chevron-down ${
                      deviceOpen ? 'playlist-open' : 'playlist-closed'
                    }`}
                  />

                  <FilterDropdown
                    categories={screens}
                    modalOpen={deviceOpen}
                    handleSetPlaylist={handleSetDevice}
                    isAdsum={screens.find((screen) => screen.adsumScreenId)}
                    setTempSelectedCategory={(id) => {
                      const index = tempSelectedDevice.indexOf(id);
                      if (index === -1) {
                        setTempSelectedDevice([...tempSelectedDevice, id]);
                      } else {
                        tempSelectedDevice.splice(index, 1);
                        setTempSelectedDevice([...tempSelectedDevice]);
                      }
                    }}
                    tempSelectedCategory={tempSelectedDevice}
                  />
                </div>
              )}
            {(selectedType === 'profileanalyzer' ||
              selectedType === 'vehiclecounter' ||
              selectedType === 'trafficcounter') && (
              <div
                className="playlist-wrapper"
                onClick={() => !loading && setLocationOpen(!locationOpen)}
              >
                <div className="playlist-selection">
                  <FilterIcon />
                  <span>Show All</span>
                </div>
                <i
                  className={`feather-chevron-down ${
                    locationOpen ? 'playlist-open' : 'playlist-closed'
                  }`}
                />

                <FilterDropdown
                  categories={locations.map((loc) => ({
                    _id: loc.location,
                    name: loc.location,
                  }))}
                  modalOpen={locationOpen}
                  title="Location"
                  handleSetPlaylist={handleSetLocation}
                  setTempSelectedCategory={(id) => {
                    const index = tempSelectedLocation.indexOf(id);
                    if (index === -1) {
                      setTempSelectedLocation([...tempSelectedLocation, id]);
                    } else {
                      tempSelectedLocation.splice(index, 1);
                      setTempSelectedLocation([...tempSelectedLocation]);
                    }
                  }}
                  tempSelectedCategory={tempSelectedLocation}
                />
              </div>
            )}

            <div>
              <DatePicker.RangePicker
                className="analytic-date-picker"
                onChange={handleDateChange}
              />
            </div>
          </div>
          <div className="analytic-header-right">
            {selectedType !== '' && selectedType !== 'profileanalyzer' && (
              <button
                className="btn-outline-primary"
                onClick={() =>
                  dispatch(
                    downloadAnalytics({
                      start: dateRange.startDate,
                      end: dateRange.endDate,
                      adsumScreenIds: selectedDevice,
                      type: selectedType,
                      siteId,
                    })
                  )
                }
              >
                <ExportIcon color="#64D5D2" size={18} />
                {selectedType === 'leaderboard'
                  ? 'Export Summary'
                  : 'Export Raw Data'}
              </button>
            )}
          </div>
        </div>
        <div className="analytic-content-wrapper">
          <div className="analytic-content">
            {selectedType ? (
              selectedType === 'leaderboard' ? (
                <LeaderboardReport siteId={siteId} loading={loading} />
              ) : selectedType === 'profileanalyzer' ? (
                <ProfileAnalyzerReport
                  siteId={siteId}
                  analytics={analytics}
                  type={selectedType}
                  loading={loading}
                  locations={locations}
                />
              ) : selectedType === 'vehiclecounter' ? (
                <VehicleAnalyticReport
                  siteId={siteId}
                  analytics={analytics}
                  type={selectedType}
                  loading={loading}
                  locations={locations}
                />
              ) : (
                <AnalyticReport
                  siteId={siteId}
                  loading={loading}
                  type={selectedType}
                  analytics={analytics}
                  selectedType={selectedType}
                  onExport={() =>
                    dispatch(
                      downloadAnalyticsLeaderboard({
                        start: dateRange.startDate,
                        end: dateRange.endDate,
                        adsumScreenIds: selectedDevice,
                        type: selectedType,
                        siteId,
                      })
                    )
                  }
                />
              )
            ) : (
              <div className="empty-content">
                <EmptyAnalyticIcon />
                <h1 className="empty-title">
                  Select the type of data analytics
                </h1>
                <p>
                  Please select the data type first to display the analytics
                  data to be shown
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
