import { Table, Tag, Tooltip } from 'antd';
import { EditIcon, TrashIcon } from 'assets/icon';
import ModalDeleteConfirmation from 'components/ModalDeleteConfirmation';
import PageHeader from 'design-components/PageHeader';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import { fetchRoles } from 'state/actions/roles';
import { deleteUser, fetchUsers } from 'state/actions/users';
import { regexInput } from 'utils/regex';
// import './User.css';
import './Users.scss';

function User() {
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const history = useHistory();
  const onNewUser = () => history.push('/users/new');

  const {
    usersList,
    roleList,
    // isAdmin,
    error,
    loading,
    deleted,
    userData,
    role,
  } = useSelector(
    (state) => ({
      usersList: state.users.list,
      roleList: state.roles.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchUsers());
      dispatch(fetchRoles());
    }
  }, [dispatch, role]);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { menu: 'Access Management', link: `/users` },
        { menu: 'User Management', link: `/users` },
      ])
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchUsers());
      if (deleted) {
        setDeleteModal((prevState) => ({
          userId: null,
          isOpen: !prevState.isOpen,
        }));
      }
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchError, setSearchError] = useState(null);

  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
    // Modal.confirm({
    //   title: 'Are you sure want to delete this data?',
    //   content: 'Data cannot be restored after delete',
    //   onOk: () => dispatch(deleteUser(userId)),
    //   okButtonProps: {
    //     loading,
    //   },
    // });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteUser(deleteModal.userId));
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: useFormatMessage('Users.name'),
      dataIndex: 'name',
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: useFormatMessage('Users.email'),
      dataIndex: 'email',
    },
    {
      title: 'Access',
      dataIndex: 'roleId',
      render: (rext, record) => {
        if (record.roleId) {
          const role = roleList.find((role) => role._id === record.roleId);

          return <div>{`${role && role.name}`}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: useFormatMessage('Users.role'),
      dataIndex: 'role',
    },
    {
      title: useFormatMessage('Users.lastLogin'),
      dateIndex: 'lastLoginDate',

      render: (text, record) => {
        if (record.lastLoginDate) {
          return (
            <Tag className="table-date">
              <div>{`${moment(record.lastLoginDate)
                .utcOffset('+08:00')
                .format('h:mm A, MMM Do, YYYY')}`}</div>
            </Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',
      render: (text, record) => {
        return (
          <div className="users__actions">
            {(role === 'admin' && record.role === 'user') ||
            role === 'superadmin' ? (
              <Link to={`/users/${record._id}`} className="btn btn--edit">
                <EditIcon style={{ width: 14, height: 14 }} />
                Edit
              </Link>
            ) : (
              <></>
            )}

            {/* {((role==='superadmin'&&(record.role === 'user'||record.role === 'admin'))||(role==='admin'&&record.role==='user')) ? (
              <Tooltip title="Access">
                <Link
                  to={`/users/manage/${record._id}`}
                  className="btn btn--access"
                >
                  <i className="feather-lock" />
                  Access
                </Link>
              </Tooltip>
            ):<></>} */}

            {role === 'superadmin' && (
              <Tooltip title="Delete User">
                <button
                  className="btn btn--delete"
                  onClick={() => onRemoveButtonClickHandler(record._id)}
                >
                  <TrashIcon style={{ width: 14, height: 14 }} />
                  Delete
                </button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const data = search
    ? usersList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.name &&
          clonedElem.name.toLowerCase().includes(search.toLowerCase());
        const org =
          clonedElem.organizationName &&
          clonedElem.organizationName
            .toLowerCase()
            .includes(search.toLowerCase());
        const email =
          clonedElem.email &&
          clonedElem.email.toLowerCase().includes(search.toLowerCase());
        const role =
          clonedElem.role &&
          clonedElem.role.toLowerCase().includes(search.toLowerCase());
        if (name || org || email || role) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      usersList.filter((el) => {
        if (role === 'superadmin') {
          return el.role !== 'superadmin';
        } else if (role === 'admin') {
          return (
            el.role !== 'superadmin' &&
            el.organizationName === userData.organizationName
          );
        }
      });

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  return (
    <section className="users">
      {redirect}
      <PageHeader
        title="Users"
        isGrid={false}
        isSearch={true}
        searchValue={search}
        searchAction={handleSearch}
        searchPlaceholder="Enter User's Name"
        searchError={searchError}
        isAdd={(role === 'superadmin' || role === 'admin')}
        addAction={onNewUser}
        addLabel="Add User"
      />
      {deleteModal.isOpen && (
        <ModalDeleteConfirmation
          title={'Delete user'}
          onCloseHandler={onCloseModalHandler}
          onDeleteHandler={onDeleteUserHandler}
          description={'Are you sure you want to delete user?'}
          visible={deleteModal.isOpen}
        />
      )}
      <div className="users__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default User;
