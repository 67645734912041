import { Button, Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
import ModalDelete from 'components/ModalDelete';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchCategories } from 'state/actions/categories';
import _ from 'underscore';
import { getAccess, log } from 'utils';
// import { regexInput } from 'utils/regex';
import './Template.css';
import Cards from 'design-components/Cards';
import {
  createTemplate,
  fetchTemplates,
  removeTemplate,
} from 'state/actions/template';
import ModalTemplateForm from 'components/ModalTemplateForm';
import { imgUrl } from 'utils/url';
import { fabric } from 'fabric';
import { setBreadcrumb } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Templates({ history }) {
  const [search, setSearch] = useState();
  // const [selectedTab, setSelectedTab] = useState('published');

  // const [sortBy, setSortBy] = useState({
  //   sort: 'name',
  //   direction: 'asc',
  // });
  // // const [isEditing, setIsEditing] = useState(false);
  // const [modalDeleteCategory, setModalDeleteCategory] = useState({
  //   show: false,
  //   category: null,
  // });

  // const [isEditingFloor, setIsEditingFloor] = useState(false);
  const [access, setAccess] = useState(null);

  const { siteId, siteName } = useParams();

  const { templateList, loading, userData, role, selectedTab } = useSelector(
    (state) => ({
      templateList: state.template.list,
      loading: state.pois.loading,
      // downloading: state.pois.downloading,
      deleted: state.pois.deleted,
      categoriesList: state.categories.list,
      floorList: state.floor.list,
      category: state.categories.category,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      categoryLoading: state.categories.loading,
      floor: state.floor.floor,
      floorLoading: state.floor.loading,
      selectedTab: state.breadcrumb.tenant,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    poisListSorted: null,
    isOpen: false,
  });
  const [addModal, setAddModal] = useState(false);
  const [template, setTemplate] = useState({
    name: '',
    description: '',
    backgroundType: 'color',
    siteId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories({ siteId }));
    dispatch(
      setBreadcrumb([
        { menu: siteName, link: `/${siteId}/information/${siteName}` },
        {
          menu: 'Template Library',
          link: `/${siteId}/information/${siteName}/templates`,
        },
      ])
    );

    // dispatch(fetchMaps({ siteId: siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData && userData.roleAccess) {
      setAccess(getAccess(userData, 'tenant', siteId));
    }
  }, [userData, siteId]);

  useEffect(() => {
    dispatch(
      // fetchPois({ siteId, categoryId: selectedCategory, type: selectedTab })
      fetchTemplates({ siteId })
    );
  }, [siteId, selectedTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemoveButtonClickHandler = (poi) => {
    setDeleteModal((prevState) => ({
      poi,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ poi: null, isOpen: false });
  };

  const onDeleteTemplateHandler = () => {
    log('delete poi clicked', deleteModal);
    dispatch(
      removeTemplate({ id: deleteModal.poi, siteId }, () => {
        onCloseModalHandler();
      })
    );
  };

  const handleSearch = (e) => {
    // setSearchError(null);
    // if (!regexInput(e.target.value) && e.target.value) {
    //   setSearchError('Search cannot using special character');
    // }
    setSearch(e.target.value);
  };
  const getImage = (template) => {
    const c = new fabric.Canvas(template._id);
    c.loadFromJSON(template.canvas);
    c.setWidth(
      ((template.orientation === 'landscape'
        ? template.width
        : template.height) *
        720) /
        template.width
    );
    c.setHeight(
      ((template.orientation === 'landscape'
        ? template.height
        : template.width) *
        720) /
        template.width
    );
    if (template.backgroundType === 'image') {
      // fabric.Image.fromURL(`${imgUrl}/media/${template.logo}`, function (img) {
      //   console.log(img, 'img');
      //   if (template.orientation === 'landscape') {
      //     img.scaleToWidth(c.width);
      //   } else {
      //     img.scaleToHeight(c.height);
      //   }
      // });
      console.log('set background image');
      const img = fabric.Image.fromURL(`${imgUrl}/media/${template.logo}`);
      // c.setBackgroundImage(
      //   `${imgUrl}/media/${template.logo}`,
      //   function () {
      //     c.renderAll();
      //   },
      //   {
      //     width: c.width,
      //     height: c.height,
      //   }
      // );
      // c.set('backgroundColor', template.color);
      c.set('backgroundImage', img);
    } else {
      c.set('backgroundColor', template.color);
    }
    console.log(c, 'canvas');
    const img = c.toDataURL('png');
    console.log(img, 'image');
    return img;
  };

  const filteredData = search
    ? _.sortBy(templateList, 'asc').filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : _.sortBy(templateList, 'asc');

  const handleEdit = (id) => {
    history.push(`/${siteId}/information/${siteName}/template/${id}`);
  };

  return (
    <div className="template-library">
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={onDeleteTemplateHandler}
        onCancel={onCloseModalHandler}
        title="Delete Template"
        subtitle={deleteModal.poi ? deleteModal.poi.name : ''}
        loading={loading}
      />

      <ModalTemplateForm
        visible={addModal}
        onCancel={() => {
          setAddModal(false);
          setTemplate({ name: '', description: '' });
        }}
        onCreate={(values) => {
          dispatch(createTemplate(values));
        }}
        template={template}
        setTemplate={setTemplate}
        history={history}
      />

      <div className="header-nav p-20">
        <div>
          <h3 className="title">Template Library</h3>

          <span>{`Template Library ${siteName}`}</span>
        </div>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary btn "
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="template-body">
        <div className="template-header">
          <div className="template-header-left">
            <div className="searchbar-wrapper">
              <div className="searchbar">
                <i className="feather-search" />
                <input
                  placeholder="Search"
                  className="search-input"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="actions__wrapper">
            {role !== 'superadmin' ? (
              access &&
              access.create && (
                <Button
                  onClick={() => {
                    setAddModal(true);
                  }}
                  className="btn-primary"
                >
                  <i className="feather-plus mr-2" />
                  New Template
                </Button>
              )
            ) : (
              <Button
                onClick={() => {
                  setAddModal(true);
                }}
                className="btn-primary"
              >
                <i className="feather-plus mr-2" />
                New Template
              </Button>
            )}
          </div>
        </div>

        <div className="template-content-wrapper">
          <div className="template-content">
            <div className="template-list-content">
              {filteredData.length > 0 && filteredData.length > 0 ? (
                _.chunk(filteredData, 3).map((chunk, index) => {
                  return (
                    <div className="template-list-chunk" key={index}>
                      {chunk.map((template) => {
                        return (
                          <div
                            key={template._id}
                            data-id={template._id}
                            className="template-list"
                          >
                            <div className="preview-container">
                              {/* {template.backgroundType === 'image' && (
                                <img
                                  className="background-card"
                                  src={`${imgUrl}/media/${template.logo}`}
                                />
                              )} */}
                              <div
                                className="background-card"
                                style={{
                                  backgroundImage: `url(${imgUrl}/media/${template.logo})`,
                                }}
                              >
                                <img
                                  className="img-card"
                                  src={getImage(template)}
                                  alt={template.backgroundType}
                                />
                              </div>
                            </div>

                            <Cards
                              data={template}
                              type="template"
                              isShowMedia={true}
                              editAction={() => handleEdit(template._id)}
                              deleteAction={() =>
                                onRemoveButtonClickHandler(template._id)
                              }
                            />
                          </div>
                        );
                      })}
                      {chunk.length < 2 && (
                        <div className="template-list-empty" />
                      )}
                      {chunk.length < 3 && (
                        <div className="template-list-empty" />
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="template-empty">
                  <Empty image={EmptyIcon} description="No Tenant" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Templates;
