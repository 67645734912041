/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Input, Modal, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { mapsCleanUp } from 'state/actions/maps';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import './ModalMap.css';
import './ModalMapForm.scss';
import { regexDescription, regexInput } from 'utils/regex';

const ModalMapForm = ({
  map,
  setMap,
  visible,
  onCreate,
  isEditing,
  onCancel,
}) => {
  const [isDragged, setIsDragged] = useState(false);

  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.maps.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    return () => dispatch(mapsCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (map._id) {
      urlToObject();
    }
  }, [map._id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setMap);

  const onFileChangedHandler = (file) => {
    setMap((prevState) => ({ ...prevState, file, logoUrl: null }));
  };

  const handleDeletePreviewImage = () => {
    setMap((prevState) => ({
      ...prevState,
      file: null,
      fileUrl: null,
      logoUrl: null,
    }));

    form.setFieldsValue({
      map: null,
    });
  };

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...map,
        id: map._id,
        name: values.name,
        description: values.description,
      });
      form.resetFields();
    }
  };

  const imagePreviewUrl = map.file
    ? map.file && URL.createObjectURL(map.file)
    : map.fileUrl && `${imgUrl}/map/${map.fileUrl}`;

  const urlToObject = async () => {
    const response = await fetch(imagePreviewUrl);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], `${map.fileUrl}`, { type: 'image' });
    log(file, '<<< FILEE');

    setMap((prevState) => ({
      ...prevState,
      file,
    }));
  };

  return (
    <Modal
      visible={visible}
      title={isEditing ? 'Edit Map' : 'Create New Map'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <div className="map-form">
        <Form
          layout="vertical"
          form={form}
          onChange={onChangeHandler}
          initialValues={{
            name: map.name,
            description: map.description,
            map: map.fileUrl,
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true }]}
            validateStatus={errors.name ? 'error' : 'success'}
            help={errors.name ? errors.name : null}
          >
            <Input placeholder="Map Name" />
          </Form.Item>
          <Form.Item
            label="Map Image"
            name="map"
            rules={[{ required: true, message: "'Map Image' is required" }]}
          >
            {map.file || map.fileUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img src={imagePreviewUrl} alt="img-preview" />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload</p>
                </div>
              </Upload>
            )}
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            validateStatus={errors.description ? 'error' : 'success'}
            help={errors.description ? errors.description : null}
          >
            <Input.TextArea rows={4} placeholder="Description" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
    // <>
    //   <div className="tile is-ancestor">
    //     <div className="tile is-parent">
    //       <div className="card tile is-child">
    //         <header className="card-header">
    //           <p className="card-header-title">
    //             <span className="icon">
    //               <i className="mdi mdi-account-edit default" />
    //             </span>
    //             {useFormatMessage('MapForm.mapInfo')}
    //           </p>
    //         </header>
    //         <div className="card-content">
    //           <form>
    //             <div className="field is-horizontal">
    //               <div className="field-label is-normal">
    //                 <label className="label">
    //                   {useFormatMessage('MapForm.name')}{' '}
    //                   <span className="has-text-danger">*</span>
    //                 </label>
    //               </div>
    //               <div className="field-body">
    //                 <div className="field">
    //                   <div className="control">
    //                     <input
    //                       id="name"
    //                       className={`input ${
    //                         error && error.errors && error.errors.name
    //                           ? 'is-danger'
    //                           : ''
    //                       }`}
    //                       type="text"
    //                       required
    //                       name="name"
    //                       value={map.name}
    //                       onChange={onChangeHandler}
    //                     />
    //                   </div>
    //                   {error && error.errors && error.errors.name && (
    //                     <p className="help is-danger">
    //                       {error.errors.name[0].msg}
    //                     </p>
    //                   )}
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="field is-horizontal">
    //               <div className="field-label is-normal">
    //                 <label className="label">
    //                   {useFormatMessage('MapForm.description')}
    //                 </label>
    //               </div>
    //               <div className="field-body">
    //                 <div className="field">
    //                   <div className="control">
    //                     <textarea
    //                       style={{
    //                         height: 100,
    //                       }}
    //                       id="description"
    //                       className="input"
    //                       type="text"
    //                       required
    //                       name="description"
    //                       value={map.description}
    //                       onChange={onChangeHandler}
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="field is-horizontal">
    //               <div className="field-label is-normal">
    //                 <label className="label">
    //                   {useFormatMessage('MapForm.image')}{' '}
    //                   <span className="has-text-danger">*</span>
    //                 </label>
    //               </div>
    //               <div className="field-body">
    //                 <div className="field">
    //                   <div
    //                     className={`file has-name ${
    //                       error && error.errors && error.errors.file
    //                         ? 'is-danger'
    //                         : ''
    //                     }`}
    //                   >
    //                     <label className="file-label">
    //                       <input
    //                         className="file-input"
    //                         type="file"
    //                         accept="image/*"
    //                         onChange={onFileChangedHandler}
    //                       />
    //                       <span className="file-cta">
    //                         <span className="file-icon">
    //                           <i className="fas fa-upload" />
    //                         </span>
    //                         <span className="file-label">
    //                           {map.file
    //                             ? pickAnotherFileMessage
    //                             : pickFileMessage}
    //                         </span>
    //                       </span>
    //                       <span className="file-name">
    //                         {map.file && map.file.name}
    //                       </span>
    //                     </label>
    //                   </div>
    //                   {error && error.errors && error.errors.file && (
    //                     <p className="help is-danger">
    //                       {error.errors.file[0].msg}
    //                     </p>
    //                   )}
    //                 </div>
    //               </div>
    //             </div>

    //             <hr />
    //             <div className="field is-horizontal">
    //               <div className="field-label" />
    //               <div className="field-body">
    //                 <div className="field">
    //                   <div className="field is-grouped">
    //                     <div className="control">
    //                       <button
    //                         onClick={onSubmitHandler}
    //                         type="button"
    //                         className={`button is-primary ${
    //                           loading && 'is-loading'
    //                         }`}
    //                         // disabled={!canSubmit}
    //                       >
    //                         <span>{useFormatMessage('MapForm.submit')}</span>
    //                       </button>
    //                     </div>
    //                     <div className="control">
    //                       <Link
    //                         to={{
    //                           pathname: `/${siteId}/information/${siteName}`,
    //                           id: 'map',
    //                         }}
    //                         className="button"
    //                       >
    //                         {goBackMessage}
    //                       </Link>
    //                     </div>
    //                     <button
    //                       type="reset"
    //                       className="button"
    //                       onClick={handleReset}
    //                     >
    //                       Reset
    //                     </button>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="tile is-parent preview">
    //       <div className="card tile is-child">
    //         <header className="card-header">
    //           <p className="card-header-title">
    //             <span className="icon">
    //               <i className="mdi mdi-account default" />
    //             </span>
    //             {useFormatMessage('MapForm.mapPreview')}
    //           </p>
    //         </header>
    //         <div className="card-content">
    //           {imagePreviewUrl && (
    //             <>
    //               <div className="is-map-avatar image has-max-width is-aligned-center">
    //                 <img
    //                   className="map-avatar"
    //                   src={imagePreviewUrl}
    //                   alt="User profile logo preview"
    //                 />
    //               </div>
    //               <hr />
    //             </>
    //           )}

    //           <div className="field">
    //             <label className="label">
    //               {useFormatMessage('MapForm.name')}
    //             </label>
    //             <div className="control is-clearfix">
    //               <input
    //                 type="text"
    //                 readOnly="readOnly"
    //                 className="input is-static"
    //                 value={map.name}
    //               />
    //             </div>
    //           </div>

    //           <div className="field">
    //             <label className="label">
    //               {useFormatMessage('MapForm.description')}
    //             </label>
    //             <div className="control is-clearfix">
    //               <input
    //                 type="text"
    //                 readOnly="readOnly"
    //                 className="input is-static"
    //                 value={map.description}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

ModalMapForm.propTypes = {
  mapData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    fileUrl: PropTypes.string,
    siteId: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
};

export default ModalMapForm;
