import { Table, DatePicker, Tag } from 'antd';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import { fetchAuthLogs, downloadAuthLogs } from 'state/actions/log';
import { fetchRoles } from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import { FilterIcon } from 'assets/icon';
import SelectDropdown from 'components/SelectDropdown';
import PageHeader from 'design-components/PageHeader';
import './Logs.css';

const { RangePicker } = DatePicker;

export default function AuthLogs({ match }) {
  const [dropdown, setDropdown] = useState();
  const [category, setCategory] = useState();
  const [dropdownType, setFilterType] = useState('All');
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const {
    logsList,
    userData,
    role,
    loading,
    deleted,
    error,
    roleList,
    userList,
    downloading,
  } = useSelector(
    (state) => ({
      roleList: state.roles.list,
      logsList: state.logs.list,
      userList: state.users.list,
      loading: state.logs.loading,
      deleted: state.logs.deleted,
      role: state.auth.userData.role,
      userData: state.auth.userData,
      error: state.logs.error,
      downloading: state.logs.downloading,
    }),
    shallowEqual
  );
  const filterCategory = ['Role', 'User'];

  const dispatch = useDispatch();

  const roleListbyUser =
    userData && userData.role === 'superadmin'
      ? roleList
      : userData.roleAccess &&
        roleList.filter((role) => role.name === userData.roleAccess.name);

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchAuthLogs({}));
    }
  }, [dispatch, role]); // eslint-disable-line

  useEffect(() => {
    console.log('set category', category, dropdownType);
  }, [category, dropdownType]);

  const onSearch = () => {
    const { startDate, endDate } = dateRange;
    if (dropdownType === 'Role' && category !== 'All') {
      dispatch(
        fetchAuthLogs({
          startDate,
          endDate,
          role: category,
        })
      );
    } else if (dropdownType === 'User' && category !== 'All') {
      dispatch(
        fetchAuthLogs({
          startDate,
          endDate,
          email: category,
        })
      );
    } else {
      dispatch(fetchAuthLogs({ startDate, endDate }));
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    setDateRange((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  useEffect(() => {
    if (logsList) {
      const arrayOfDropdown = [];
      switch (dropdownType) {
        case 'Role':
          roleListbyUser &&
            roleListbyUser.map((org) =>
              arrayOfDropdown.push({
                categoryId: org._id,
                name: org.name,
              })
            );
          break;
        case 'User':
          userList &&
            userList
              .filter((org) =>
                userData.role === 'superadmin'
                  ? true
                  : !!(
                      userData.roleAccess &&
                      userData.roleAccess._id === org.roleId
                    )
              )
              .map((user) =>
                arrayOfDropdown.push({
                  categoryId: user.email,
                  name: user.name ?? user.email,
                })
              );
          break;

        default:
          break;
      }
      setDropdown(arrayOfDropdown);
    }
  }, [logsList, dropdownType]); // eslint-disable-line

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchAuthLogs());
    }
  }, [loading, deleted]); // eslint-disable-line

  const handleDownload = () => {
    dispatch(downloadAuthLogs());
  };

  useEffect(() => {
    if (dateRange.start !== '' && dateRange.end !== '') {
      onSearch();
    }
  }, [category, dateRange]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchUsers(match.params.id));
    dispatch(fetchRoles());
    dispatch(
      setBreadcrumb([
        { menu: 'Audit Logs', link: '/logs' },
        { menu: 'Authentication Log', link: '/authlogs' },
      ])
    );
  }, []); // eslint-disable-line

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const filteredData = search
    ? logsList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.message.toLowerCase().includes(search.toLowerCase());
      })
    : logsList;

  const columns = [
    {
      title: useFormatMessage('Logs.email'),
      dataIndex: 'email',
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: useFormatMessage('Logs.browser'),
      dataIndex: 'browserAgent',
    },
    {
      title: useFormatMessage('Logs.ip'),
      dataIndex: 'ipAddress',
    },
    {
      title: useFormatMessage('Logs.date'),
      dataIndex: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => {
        if (record.date) {
          return (
            <Tag className="table-date">
              <div>{`${moment(record.date)
                .utcOffset('+08:00')
                .format('h:mm A, MMM Do, YYYY')}`}</div>
            </Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: useFormatMessage('Logs.status'),
      dataIndex: 'status',
      render: (text, record) => {
        if (record.status === 500 || record.status === 422) {
          return <Tag color="red">Error</Tag>;
        }
        if (record.status === 201 || record.status === 200) {
          return <Tag color="green">Success</Tag>;
        }
      },
    },
    {
      title: useFormatMessage('Logs.message'),
      dataIndex: 'message',
      render: (text, record) => {
        return <span>{record.message}</span>;
      },
    },
  ];

  return (
    <section className="authlogs">
      {redirect}
      <PageHeader
        title="Authentication Logs"
        isGrid={false}
        isSearch
        searchValue={search}
        searchAction={(e) => setSearch(e.target.value)}
        searchPlaceholder="Enter User's Name"
        isAdd={false}
        addLabel=""
        isDownload
        isDownloading={downloading}
        downloadAction={handleDownload}
      />

      <div className="authlogs-menu">
        <div className="authlogs-search">
          <RangePicker
            className="authlogs-date-picker"
            onChange={handleDateChange}
          />
        </div>
        <div className="authlogs-dropdown">
          {dropdownType !== 'All' && dropdown && (
            <SelectDropdown
              list={dropdown}
              valueParams="categoryId"
              labelParams="name"
              selected={category}
              onSet={(selected) => setCategory(selected)}
              allTitle="All"
            />
          )}
          {/* <span>Filter by: </span> */}

          <SelectDropdown
            list={filterCategory}
            selected={dropdownType}
            onSet={(selected) => {
              console.log(dropdown.length);
              dropdown && dropdown.length > 0 && setCategory('all');
              setFilterType(selected);
            }}
            allTitle="All"
            Icon={FilterIcon}
          />
        </div>
      </div>

      <div>
        {loading && filteredData ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="_id"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
