/* eslint-disable jsx-a11y/label-has-associated-control */
import { fabric } from 'fabric';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './TemplateForm.css';
import {
  CheckBullet,
  EditIcon,
  ExportIcon,
  GridIcon,
  PlusIcon,
  TrashIcon,
} from 'assets/icon/dynamic';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
// import { ReactComponent as BackwardIcon } from '../../assets/icon/backward-5-seconds.svg';
// import { ReactComponent as ForwardIcon } from '../../assets/icon/forward-5-seconds.svg';
import { ReactComponent as CloseIcon } from '../../assets/icon/close-circle.svg';
import ModalTemplateElementForm from 'components/ModalTemplateElementForm';
import { fetchMediaCategories } from 'state/actions/mediaCategories';
import {
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Select,
  Slider,
  Tooltip,
  Upload,
} from 'antd';
import { useChangeHandler } from 'hooks';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { setBreadcrumb } from 'state/actions/breadcrumb';

const TemplateForm = ({
  history,
  template,
  action,
  categoriesList,
  success,
}) => {
  const { siteId, siteName } = useParams();
  const canvas = useRef(null);
  const fabricRef = useRef(null);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });
  const [templateData, setTemplateData] = useState(template);
  const [addElement, setAddElement] = useState(false);
  const [element, setElement] = useState({});
  const [isDragged, setIsDragged] = useState(false);
  const [activeObject, setActiveObject] = useState({});
  const [elementList, setElementList] = useState([]);
  const [templateProperties, setTemplateProperties] = useState(false);
  const [mediaCat, setMediaCat] = useState(false);
  const [zoom, setZoom] = useState(100);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [file, setFile] = useState();
  const [scale, setScale] = useState();

  const onChangeHandler = useChangeHandler(setTemplateData);

  const dispatch = useDispatch();

  const addRectangle = () => {
    console.log('add rectangel');
    const rect = new fabric.Rect({
      // top: 50,
      // left: 50,
      width: 100,
      height: 100,
      fill: 'white',
      // borderColor: 'black',
      stroke: 'grey',
      strokeWidth: 1,
      strokeUniform: true,
      originX: 'center',
      originY: 'center',
    });

    const cat = new fabric.Textbox(element.name, {
      fontSize: 10,
      fontWeight: 400,
      fontFamily: 'roboto',
      originX: 'center',
      originY: 'bottom',
      textAlign: 'center',
      splitByGrapheme: true,
      isWrapping: true,
      height: 100,
      width: 100,
    });
    const size = new fabric.Textbox(element.name, {
      fontSize: 7,
      fontWeight: 400,
      fontFamily: 'roboto',
      originX: 'center',
      originY: 'top',
      textAlign: 'center',
      splitByGrapheme: true,
      isWrapping: true,
      fill: 'grey',
      height: 100,
      width: 100,
    });

    const group = new fabric.Group([rect, cat, size], {
      left: 150,
      top: 100,
      stroke: 'grey',
      strokeWidth: 1,
      strokeUniform: true,
    });

    const id = Date.now();
    group.set('categoryId', element.categoryId);
    group.set('name', element.name);
    group.set('id', id);
    // group.categoryId = element.cateogryId;

    // group.on('scaling', function (obj) {

    // });
    setElementList([...elementList, { ...element, id }]);
    fabricRef.current.add(group);

    fabricRef.current.setActiveObject(group);
    // addCategory(element.categoryId, element.name);
    setElement({});
  };
  useEffect(() => {
    setImagePreviewUrl(
      file
        ? URL.createObjectURL(file)
        : template.logo && `${imgUrl}/media/${template.logo}`
    );
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   // console.log(template, 'template in fomr');
  //   // setTemplateData(template);

  // }, [templateData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeletePreviewImage = () => {
    setFile();
    setImagePreviewUrl(null);
  };
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { menu: siteName, link: `/${siteId}/information/${siteName}` },
        {
          menu: 'Template Library',
          link: `/${siteId}/information/${siteName}/templates`,
        },
        {
          menu: 'Template Form',
          link: `/${siteId}/information/${siteName}/template/${template._id}`,
        },
      ])
    );
    const long = template.width * (720 / template.width);
    const short = template.height * (720 / template.width);
    setTemplateData(template);

    console.log(template);
    const initFabric = () => {
      if (template.backgroundType === 'color') {
        fabricRef.current = new fabric.Canvas(canvas.current, {
          height:
            template.orientation === 'landscape'
              ? template.height * (720 / template.width)
              : template.width * (720 / template.width),
          width:
            template.orientation === 'landscape'
              ? template.width * (720 / template.width)
              : template.height * (720 / template.width),
          backgroundColor:
            template.backgroundType === 'color' ? template.color : 'white',

          selection: false,
          renderOnAddRemove: true,
        });
      } else {
        fabricRef.current = new fabric.Canvas(canvas.current, {
          height: template.orientation === 'landscape' ? short : long,
          width: template.orientation === 'landscape' ? long : short,
          // backgroundImage: template.logo && `${imgUrl}/media/${template.logo}`,

          selection: false,
          renderOnAddRemove: true,
        });

        setImagePreviewUrl(
          file
            ? URL.createObjectURL(file)
            : template.logo && `${imgUrl}/media/${template.logo}`
        );

        // fabricRef.current.setBackgroundImage(
        //   `${imgUrl}/media/${template.logo}`,
        //   fabricRef.current.renderAll.bind(fabricRef.current),
        //   {
        //     width: fabricRef.current.width,
        //     height: fabricRef.current.height,
        //     // Needed to position backgroundImage at 0/0
        //     originX: 'left',
        //     originY: 'top',
        //   }
        // );
      }
    };

    const disposeFabric = () => {
      fabricRef.current.dispose();
    };
    dispatch(fetchMediaCategories({ siteId }));

    initFabric();
    const scaleCanvas =
      (720 /
        (template.orientation === 'landscape'
          ? template.width
          : template.height)) *
      zoom;
    setScale(scaleCanvas);

    if (template._id && template.canvas) {
      console.log(template.canvas, 'template canvas');
      setElementList(template.canvas.objects);
      fabricRef.current.loadFromJSON(template.canvas);
    }

    // fabricRef.current.object;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = 'white';
    fabric.Object.prototype.cornerStyle = 'square';
    fabric.Object.prototype.cornerStrokeColor = '#2C82F6';
    fabric.Object.prototype.cornerSize = 5;

    fabricRef.current.on('selection:updated', (obj) => {
      console.log(obj, 'object updated');
      setActiveObject(obj.selected[0]);
    });
    fabricRef.current.on('selection:created', (obj) => {
      setActiveObject(obj.selected[0]);
    });
    fabricRef.current.on('selection:cleared', (obj) => {
      console.log(obj, 'object updated');
      setActiveObject({});
    });
    // fabricRef.current.on('object:moving', (obj) =>

    // );
    fabricRef.current.on('object:moving', (e) => {
      var obj = e.target;
      // if object is too big ignore

      var halfw = obj.width / 2;
      var halfh = obj.height / 2;
      var bounds = {
        tl: { x: halfw, y: halfh },
        br: { x: fabricRef.current.width, y: fabricRef.current.height },
      };

      // top-left  corner

      console.log(obj, bounds);
      if (obj.top < bounds.tl.y || obj.left < bounds.tl.x) {
        obj.top = Math.max(obj.top, 0);
        obj.left = Math.max(obj.left, 0);
      }

      // bot-right corner
      if (
        obj.top > bounds.br.y - obj.height * obj.scaleY ||
        obj.left > bounds.br.x - obj.width * obj.scaleX
      ) {
        console.log('bottom right');
        obj.top = Math.min(obj.top, bounds.br.y - obj.height * obj.scaleY);
        obj.left = Math.min(obj.left, bounds.br.x - obj.width * obj.scaleX);
      }
      fabricRef.current.renderAll();
    });
    var previous_left, previous_scaleX, previous_scaleY, previous_top;
    fabricRef.current.on('object:scaling', function (obj) {
      var el = obj.target;
      var halfw = el.width / 2;
      var halfh = el.height / 2;
      var bounds = {
        tl: { x: halfw, y: halfh },
        br: { x: fabricRef.current.width, y: fabricRef.current.height },
      };
      const width = (
        templateData.orientation
          ? templateData.orientation === 'landscape'
          : template.orientation === 'landscape'
      )
        ? templateData.width
          ? templateData.width
          : template.width
        : templateData.height
        ? templateData.height
        : template.height;
      const height = (
        templateData.orientation
          ? templateData.orientation === 'landscape'
          : template.orientation === 'landscape'
      )
        ? templateData.height
          ? templateData.height
          : template.height
        : templateData.width
        ? templateData.width
        : template.width;
      console.log(el.left, bounds.br.x - el.width * el.scaleX, 'width height');
      console.log(el.top, bounds.br.y - el.height * el.scaleY, 'top height');
      if (
        el &&
        el.height * el.scaleX > 0 &&
        el.left + el.width * el.scaleX < fabricRef.current.width &&
        el.top + el.height * el.scaleY < fabricRef.current.height
      ) {
        previous_scaleY = el.scaleY;
        previous_scaleX = el.scaleX;
        previous_left = el.left;
        previous_top = el.top;
      }
      if (
        el.top >= bounds.br.y - el.height * el.scaleY ||
        el.left >= bounds.br.x - el.width * el.scaleX
      ) {
        el.left = previous_left;
        el.top = previous_top;
        el.scaleX = previous_scaleX;
        el.scaleY = previous_scaleY;
        el.lockScalingX = true;
        el.lockScalingY = true;
        fabricRef.current.renderAll();
      }
      if (el.top < -5 || el.left < -5) {
        el.left = previous_left;
        el.top = previous_top;
        el.scaleX = previous_scaleX;
        el.scaleY = previous_scaleY;
        el.lockScalingX = true;
        el.lockScalingY = true;
        fabricRef.current.renderAll();
      }
      if (
        el.top > bounds.br.y - el.height * el.scaleY ||
        el.left > bounds.br.x - el.width * el.scaleX
      ) {
        console.log('mOVINGGGGGGGGGGGGG');
        el.left = previous_left;
        el.top = previous_top;
        console.log('Scale X:' + el.scaleX);
        console.log('Scale Y:' + el.scaleY);
        console.log('width:' + el.width);
        console.log('Height:' + el.height);

        console.log('Previous Y: ' + previous_scaleY); // PREVIOUS SCALE X & Y WILL COME FROM MOUSE DOWN EVENT
        el.scaleX = previous_scaleX;
        el.scaleY = previous_scaleY;
        fabricRef.current.renderAll();
      }

      var size = obj.transform.target._objects[2];
      var text = obj.transform.target._objects[1];
      var rect = obj.transform.target._objects[0];
      var both = obj.transform.target;

      rect.width = both.width;
      rect.height = both.height;
      text.scaleX = 1;
      text.scaleY = 1;
      text.width = both.width;
      console.log(both.height * both.scaleY, width, 'width');
      size.set(
        'text',
        `${Math.round(
          both.width * both.scaleX * (width / fabricRef.current.width)
        )} x ${Math.round(
          both.height * both.scaleY * (height / fabricRef.current.height)
        )}`
      );
      // text.fontSize = 16;
      text._wrapText(1, both.width);
    });

    fabricRef.current.on('mouse:up', function (e) {
      console.log(e, 'mouse up');
      var activeObject = e.target;
      if (activeObject !== null) {
        activeObject.lockScalingX = false; // this will connect with object scalling event
        activeObject.lockScalingY = false;
      }
    });

    // if (template.backgroundType === 'image') {
    //   template.logo &&
    //     fabric.Image.fromURL(`${imgUrl}/media/${template.logo}`, function (
    //       img
    //     ) {
    //       console.log(img, fabricRef.current.width, 'img');
    //       if (templateData.orientation === 'landscape') {
    //         img.scaleToWidth(fabricRef.current.width);
    //       } else {
    //         img.scaleToHeight(fabricRef.current.height);
    //       }
    //       fabricRef.current.setBackgroundImage(
    //         img,
    //         fabricRef.current.renderAll.bind(fabricRef.current)
    //       );
    //     });
    // }

    return () => {
      disposeFabric();
    };
  }, [template]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // fabricRef.current.setZoom(zoom / 100);
    console.log(templateData, imagePreviewUrl, scale, 'tempalte data');
    fabricRef.current.setWidth(
      ((templateData.orientation === 'landscape'
        ? templateData.width * (720 / templateData.width)
        : templateData.height * (720 / templateData.width)) *
        zoom) /
        100
    );
    fabricRef.current.setHeight(
      ((templateData.orientation === 'landscape'
        ? templateData.height * (720 / templateData.width)
        : templateData.width * (720 / templateData.width)) *
        zoom) /
        100
    );
    if (templateData.backgroundType === 'color') {
      fabricRef.current.setBackgroundImage(null);
      fabricRef.current.set('backgroundColor', templateData.color ?? 'white');
      fabricRef.current.renderAll();
    }
    if (templateData.backgroundType === 'image') {
      if (imagePreviewUrl) {
        // fabricRef.current.set('backgroundColor', 'transparent');
        fabric.Image.fromURL(imagePreviewUrl, function (img) {
          console.log(img, fabricRef.current.getWidth(), 'img');
          if (templateData.orientation === 'landscape') {
            img.scaleToWidth(fabricRef.current.width);
          } else {
            img.scaleToHeight(fabricRef.current.height);
          }
          fabricRef.current.setBackgroundImage(
            img,
            fabricRef.current.renderAll.bind(fabricRef.current)
          );
        });
        // fabricRef.current.setBackgroundImage(
        //   imagePreviewUrl,
        //   fabricRef.current.renderAll.bind(fabricRef.current),
        //   {
        //     width: 720,
        //     height: 720,

        //     // Needed to position backgroundImage at 0/0
        //     originX: 'left',
        //     originY: 'top',
        //   }
        // );
      } else {
        console.log(fabricRef.current);
        fabricRef.current.setBackgroundImage(null);
      }
      fabricRef.current.renderAll();
    }
  }, [templateData, imagePreviewUrl]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    fabricRef.current.setZoom(zoom / 100);
    fabricRef.current.setWidth(
      ((templateData.orientation === 'landscape'
        ? templateData.width * (720 / templateData.width)
        : templateData.height * (720 / templateData.width)) *
        zoom) /
        100
    );
    fabricRef.current.setHeight(
      ((templateData.orientation === 'landscape'
        ? templateData.height * (720 / templateData.width)
        : templateData.width * (720 / templateData.width)) *
        zoom) /
        100
    );
  }, [zoom]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setMediaCat(true);
  }, [activeObject]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mediaCat && templateProperties) {
      setMediaCat(false);
    }
    if (!mediaCat && !templateProperties && activeObject.name) {
      setMediaCat(true);
    }
  }, [templateProperties]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mediaCat && templateProperties) {
      setTemplateProperties(false);
    }
  }, [mediaCat]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}/templates`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSize = (value) => {
    const sizes = value.split('x');
    setTemplateData({
      ...templateData,
      width: parseInt(sizes[0]),
      height: parseInt(sizes[1]),
    });
  };
  const handleChangeBackground = (value) => {
    setTemplateData({
      ...templateData,
      backgroundType: value,
    });
  };
  const handleChangeOrientation = (value) => {
    setTemplateData({
      ...templateData,
      orientation: value,
    });
  };

  const editObject = () => {
    const editedObj = fabricRef.current
      .getObjects()
      .find((obj) => obj.id === element.id);

    console.log(editedObj, 'edited obj');
    editedObj.name = element.name;
    console.log(editedObj._objects[1].text, 'text object');
    editedObj.categoryId = element.categoryId;
    editedObj._objects[1].set('text', element.name);
    console.log(editedObj._objects[1].text, 'text object2');
    fabricRef.current.renderAll();
    const index = elementList.findIndex((el) => el.id === element.id);
    elementList[index] = element;
    setElement({});
  };

  const onFileChangedHandler = (file) => {
    console.log(file, 'file');
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      if (file.size / 1000000 <= 300) {
        // setMedia((prevState) => ({ ...prevState, file, logo: null }));
        setFile(file);
        return false;
      } else {
        message.error('Maximum file size is 300MB');
      }
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  const submit = () => {
    if (
      (!regexInput(templateData.name) && templateData.name) ||
      (!regexDescription(templateData.description) && templateData.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(templateData.name) &&
          templateData.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(templateData.description) &&
          templateData.description &&
          'This field cannot contain any special characters',
      });
    } else if (
      !templateData.width ||
      !templateData.height ||
      !templateData.orientation
    ) {
      console.log('here');
      setErrors({
        ...errors,
        size: !templateData.width && 'This field is required',
        orientation: !templateData.orientation && 'This field is required',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      console.log(templateData, 'template data before post');
      fabricRef.current.setBackgroundColor(null);
      fabricRef.current.setBackgroundImage(null);
      dispatch(
        action({
          template: {
            ...templateData,
            canvas: fabricRef.current.toJSON(['name', 'categoryId', 'id']),
            id: template._id,
          },
          file,
        })
      );
    }
  };

  return (
    <div className="template-form">
      <ModalTemplateElementForm
        visible={addElement}
        onCancel={() => {
          setElement();
          setAddElement(false);
        }}
        onCreate={(values) => {
          if (element.id) {
            console.log('before edit');
            editObject();
          } else {
            addRectangle();
          }
          setAddElement(false);
        }}
        element={element}
        setElement={setElement}
        history={history}
      />
      <div className="element-panel">
        <div className="element-action">
          <div className="action-wrapper">
            {/* <BackwardIcon />
            <ForwardIcon /> */}
          </div>
          <div className="button-action">
            <button
              className="primary flex-center"
              onClick={() => setAddElement(true)}
            >
              <PlusIcon color="#404040" size={18} />
              Layout
            </button>
          </div>
        </div>
        <div className="element-list">
          {fabricRef.current &&
            elementList.map((obj, ind) => (
              <div
                key={ind + obj.name}
                onClick={() => {
                  const activeFab = fabricRef.current
                    .getObjects()
                    .find((fab) => fab.name === obj.name);

                  if (activeFab) {
                    fabricRef.current.setActiveObject(activeFab);
                    fabricRef.current.renderAll();
                  }
                }}
                className={`element-item ${
                  obj.name === activeObject.name ? 'active' : ''
                }`}
              >
                <div className="flex-center">
                  <GridIcon color="#2DA5A1" />
                  <div className="ml-2">{obj.name}</div>
                </div>
                <Tooltip title="Options">
                  <Dropdown
                    trigger="click"
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="edit"
                          onClick={() => {
                            setElement(obj);
                            setAddElement(true);
                          }}
                        >
                          <i className="feather-edit mr-2" />
                          Edit
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          key="delete"
                          onClick={() => {
                            // obj.onDeselect = () => false;
                            // fabricRef.current.discardActiveObject();
                            setElementList(
                              elementList.filter((el) => el.name !== obj.name)
                            );
                            fabricRef.current.remove(
                              fabricRef.current
                                .getObjects()
                                .find((fab) => fab.name === obj.name)
                            );
                            // fabricRef.current.renderAll();
                          }}
                        >
                          <i className="feather-trash-2 mr-2" />
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <button>
                      <i className="feather-more-horizontal" />
                    </button>
                  </Dropdown>
                </Tooltip>
              </div>
            ))}
        </div>
        <div className="button-wrapper">
          <button className="button-primary" onClick={() => submit()}>
            Submit
          </button>
        </div>
      </div>
      <div className="editor-wrapper">
        <div className="canvas-action">
          <div className="canvas-slider">
            <Slider
              defaultValue={100}
              value={zoom}
              min={1}
              max={200}
              onChange={(val) => setZoom(val)}
            />
          </div>
          {template.name}{' '}
          <div
            className="flex-center"
            onClick={() => setTemplateProperties(true)}
          >
            <EditIcon color="#64D5D2" />
            <div className="ml-1">{`Template Properties`}</div>
          </div>
        </div>
        <div className="canvas-space">
          {/* <form className="form-wrapper"> */}
          <canvas ref={canvas} id="canvas" className="template-canvas" />
          {templateProperties && (
            <div className="template-properties">
              <div className="title-template">
                Template Properties
                <CloseIcon onClick={() => setTemplateProperties(false)} />
              </div>
              <div className="form">
                <Form
                  className="form"
                  layout="vertical"
                  form={form}
                  onChange={onChangeHandler}
                  initialValues={templateData}
                >
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                    validateStatus={errors.name ? 'error' : 'success'}
                    help={errors.name ? errors.name : null}
                  >
                    <Input value={templateData.name} />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    validateStatus={errors.description ? 'error' : 'success'}
                    help={errors.description ? errors.description : null}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Description"
                      value={templateData.description}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Background Type"
                    name="backgroundType"
                    initialValue={`${templateData.backgroundType ?? ''}`}
                    validateStatus={errors.backgroundType ? 'error' : 'success'}
                    help={errors.backgroundType ? errors.backgroundType : null}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={handleChangeBackground}
                      options={[
                        { value: 'color', label: 'Color' },
                        { value: 'image', label: 'Image' },
                      ]}
                    />
                  </Form.Item>
                  {templateData.backgroundType &&
                    (templateData.backgroundType === 'color' ? (
                      <Form.Item
                        label="Color"
                        name="color"
                        validateStatus={errors.color ? 'error' : 'success'}
                        help={errors.color ? errors.color : null}
                      >
                        <Input
                          placeholder="Color"
                          value={templateData.color}
                          type="color"
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Background Image"
                        name="file"
                        validateStatus={errors.file ? 'error' : 'success'}
                        help={errors.file ? errors.file : null}
                      >
                        {imagePreviewUrl ? (
                          <div className="upload-image-preview">
                            <Tooltip placement="top" title="Delete">
                              <button
                                type="button"
                                onClick={handleDeletePreviewImage}
                                className="btn-remove"
                              >
                                <TrashIcon size={18} color="#CB3A31" />
                              </button>
                            </Tooltip>
                            {
                              <img
                                className="media-avatar"
                                src={imagePreviewUrl}
                                alt="User profile logo preview"
                              />
                            }
                          </div>
                        ) : (
                          <Upload
                            showUploadList={false}
                            beforeUpload={onFileChangedHandler}
                            style={{ width: '100%' }}
                          >
                            <div
                              onDragLeave={() => setIsDragged(false)}
                              onDragOver={() => setIsDragged(true)}
                              className={`upload-drag-and-drop ${
                                isDragged ? 'active' : ''
                              }`}
                            >
                              <ExportIcon size={22} color="#C2C2C2" />
                              <p className="upload-title">
                                Drag or{' '}
                                <span className="upload-highlight">Browse</span>
                              </p>
                              <p className="upload-subtitle">
                                File format supported: jpg, png, mp4 (Up to
                                50MB){' '}
                              </p>
                            </div>
                          </Upload>
                        )}
                      </Form.Item>
                    ))}
                  <Form.Item
                    label="Canvas Size"
                    name="size"
                    initialValue={`${templateData.width}x${templateData.height}`}
                    validateStatus={errors.size ? 'error' : 'success'}
                    help={errors.size ? errors.size : null}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={handleChangeSize}
                      options={[
                        { value: '1920x1080', label: 'Full HD(1920 x 1080)' },
                        { value: '3840x2160', label: 'Ultra HD(3840 x 2160)' },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Orientation"
                    name="orientation"
                    initialValue={templateData.orientation}
                    validateStatus={errors.size ? 'error' : 'success'}
                    help={errors.size ? errors.size : null}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={handleChangeOrientation}
                      options={[
                        { value: 'portrait', label: 'Portrait' },
                        { value: 'landscape', label: 'Landscape' },
                      ]}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
          {activeObject.name && mediaCat && (
            <div className="template-properties">
              <div className="title-template">
                Media Categories
                <CloseIcon onClick={() => setMediaCat(false)} />
              </div>
              <div className="form">
                {categoriesList.map((cat) => (
                  <div className="radio-container">
                    <div className="radio-title">{cat.name}</div>
                    <div
                      className={`radio ${
                        cat._id === activeObject.categoryId ? 'active' : ''
                      }`}
                      onClick={() => {
                        fabricRef.current.getActiveObject().categoryId =
                          cat._id;
                        setActiveObject({
                          ...activeObject,
                          categoryId: cat._id,
                        });
                      }}
                    >
                      {cat._id === activeObject.categoryId && (
                        <CheckBullet color="#64D5D2" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* </form> */}
          {/* <div
            className="button-wrapper"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div>
              <button className="button-danger" onClick={() => remove()}>
                Delete
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TemplateForm;
