import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const SCREEN_GROUP_FETCH_DATA_INIT = createAction(
  'SCREEN_GROUP_FETCH_DATA_INIT'
);
export const SCREEN_GROUP_FETCH_DATA_SUCCESS = createAction(
  'SCREEN_GROUP_FETCH_DATA_SUCCESS'
);
export const SCREEN_GROUP_FETCH_DATA_FAIL = createAction(
  'SCREEN_GROUP_FETCH_DATA_FAIL'
);

export const SCREEN_GROUP_DELETE_CATEGORY_INIT = createAction(
  'SCREEN_GROUP_DELETE_CATEGORY_INIT'
);
export const SCREEN_GROUP_DELETE_CATEGORY_SUCCESS = createAction(
  'SCREEN_GROUP_DELETE_CATEGORY_SUCCESS'
);
export const SCREEN_GROUP_DELETE_CATEGORY_FAIL = createAction(
  'SCREEN_GROUP_DELETE_CATEGORY_FAIL'
);

export const SCREEN_GROUP_CLEAR_DATA = createAction('SCREEN_GROUP_CLEAR_DATA');

export const SCREEN_GROUP_CREATE_CATEGORY_INIT = createAction(
  'SCREEN_GROUP_CREATE_CATEGORY_INIT'
);
export const SCREEN_GROUP_CREATE_CATEGORY_SUCCESS = createAction(
  'SCREEN_GROUP_CREATE_CATEGORY_SUCCESS'
);
export const SCREEN_GROUP_CREATE_CATEGORY_FAIL = createAction(
  'SCREEN_GROUP_CREATE_CATEGORY_FAIL'
);

export const SCREEN_GROUP_MODIFY_CATEGORY_INIT = createAction(
  'SCREEN_GROUP_MODIFY_CATEGORY_INIT'
);
export const SCREEN_GROUP_MODIFY_CATEGORY_SUCCESS = createAction(
  'SCREEN_GROUP_MODIFY_CATEGORY_SUCCESS'
);
export const SCREEN_GROUP_MODIFY_CATEGORY_FAIL = createAction(
  'SCREEN_GROUP_MODIFY_CATEGORY_FAIL'
);

export const SCREEN_GROUP_CLEAN_UP = createAction('SCREEN_GROUP_CLEAN_UP');

export const SCREEN_GROUP_CLEAR_DATA_LOGOUT = createAction(
  'SCREEN_GROUP_CLEAR_DATA_LOGOUT'
);

export const SET_SCREEN_GROUP = createAction('SET_SCREEN_GROUP');

export const fetchScreenGroups = ({ siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SCREEN_GROUP_FETCH_DATA_INIT());
    // if (groupId) {
    //   let groupData;
    //   try {
    //     groupData = await Axios.get(`${url}/screenGroup`, {
    //       params: {
    //         siteId,
    //       },
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    //       },
    //     });
    //   } catch (error) {
    //     toastr.error('', `${errorMsg(dispatch, error)}`);
    //     return dispatch(SCREEN_GROUP_FETCH_DATA_FAIL({ error }));
    //   }
    //   const group = { ...groupData, id: groupId };
    //   return dispatch(
    //     SCREEN_GROUP_FETCH_DATA_SUCCESS({
    //       list: getState().screenGroup.list,
    //       group,
    //     })
    //   );
    // }

    if (siteId) {
      let categories;
      try {
        categories = await Axios.get(`${url}/screenGroup`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SCREEN_GROUP_FETCH_DATA_FAIL({ error }));
      }
      categories = categories.data.data.filter(
        (group) => group.siteId === siteId
      );
      return dispatch(
        SCREEN_GROUP_FETCH_DATA_SUCCESS({
          list: categories,
          group: getState().screengroup.group,
        })
      );
    }
  };
};

export const deleteScreenGroup = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(SCREEN_GROUP_DELETE_CATEGORY_INIT());

    try {
      await Axios.delete(`${url}/screenGroup/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREEN_GROUP_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The group was deleted.');
    cb();
    return dispatch(SCREEN_GROUP_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearCategoriesData = () => {
  return (dispatch) => {
    dispatch(SCREEN_GROUP_CLEAR_DATA());
  };
};

export const clearCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(SCREEN_GROUP_CLEAR_DATA_LOGOUT());
  };
};

export const createScreenGroup = (
  { uid, name, description, orientation, siteId, modifiedAt, modifiedBy },
  setIsModalCategoryShow
) => {
  return async (dispatch, getState) => {
    dispatch(SCREEN_GROUP_CREATE_CATEGORY_INIT());
    let createCategory;
    const group = {
      name,
      description,
      orientation,
      siteId,
    };

    try {
      createCategory = await Axios.post(`${url}/screenGroup/create`, group, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      log(createCategory, '<<< created cat');
    } catch (error) {
      log(error.response, '<<< ERROR CREATE MEDIA CATEGORY');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREEN_GROUP_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category created successfully');
    setIsModalCategoryShow(false);
    return dispatch(
      SCREEN_GROUP_CREATE_CATEGORY_SUCCESS({
        screengroup: createCategory.data.data,
      })
    );
  };
};

export const modifyScreenGroup = (
  { id, name, description, orientation, siteId, modifiedAt, modifiedBy },
  setIsModalCategoryShow
) => {
  return async (dispatch, getState) => {
    dispatch(SCREEN_GROUP_MODIFY_CATEGORY_INIT());

    const groupData = {
      id,
      name,
      description,
      orientation,
      siteId,
      modifiedAt,
      modifiedBy,
    };

    try {
      await Axios.post(`${url}/screenGroup/edit`, groupData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREEN_GROUP_MODIFY_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category updated successfully');
    setIsModalCategoryShow(false);

    return dispatch(
      SCREEN_GROUP_MODIFY_CATEGORY_SUCCESS({
        screenGroup: groupData,
        id,
      })
    );
  };
};

export const categoriesCleanUp = () => (dispatch) =>
  dispatch(SCREEN_GROUP_CLEAN_UP());

// added by me
export const setScreenGroup = (data) => {
  return (dispatch) => {
    dispatch(
      SET_SCREEN_GROUP({
        data,
      })
    );
    // cb();
  };
};
