/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { ResetIcon } from 'assets/icon';

const ModalResetContent = () => {
  return (
    <div className="confirm-modal-content">
      <ResetIcon />
      <h1>Reset Changes</h1>
      <p>
        Are you sure you want to revert all the recent changes back to its
        previous state? This action cannot be undone.
      </p>
    </div>
  );
};

export default ModalResetContent;
