import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchMedia } from 'state/actions/media';
import { log } from 'utils';

const localizer = momentLocalizer(moment);

const MediaCalendar = () => {
  const { id } = useParams();

  const { mediaList, mediaData } = useSelector(
    (state) => ({
      mediaList: state.media.list,
      mediaData: state.media.media,
    }),
    shallowEqual
  );

  const [media, setMedia] = useState(mediaData || {});
  const [events, setEvents] = useState(0);
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const mediaFetched = mediaList.find(
        (fetchedMedia) => fetchedMedia.id === id
      );
      if (mediaFetched) {
        setMedia(mediaFetched);
      } else if (mediaData.id === id) {
        setMedia(mediaData);
      } else {
        dispatch(fetchMedia({ mediaId: id }));
      }
    }
  }, [id, mediaData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (media && media.name) {
      const dates = getDates(media);
      setEvents(dates);
    }
  }, [media]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (media && media.name) {
      const dates = getDates(media);
      setEvents(dates);
    }
  }, [index]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDates = (media) => {
    let dates;
    switch (media.type) {
      case 'date':
        dates = calculateDate(media);
        break;
      case 'day':
        dates = calculateDateByDay(media);
        break;
      default:
        const alldays = [];
        const date = {
          start: new Date('12-12-1000'),
          end: new Date('12-12-3000'),
          title: '00:00-23:59',
        };
        alldays.push(date);
        dates = alldays;
        break;
    }
    return dates;
  };

  const calculateDate = (media) => {
    const dates = [];
    media.dates &&
      // eslint-disable-next-line
      media.dates.map((data) => {
        // eslint-disable-next-line
        data.hours.map((hour) => {
          const date = {
            start: new Date(data.date),
            end: new Date(data.date),
            title: hour,
          };
          dates.push(date);
        });
      });
    return dates;
  };

  const calculateDateByDay = (media) => {
    const dates = [];
    media.days &&
      // eslint-disable-next-line
      media.days.map((data) => {
        const day = moment().startOf('month').add(index, 'month').day(data.day);
        if (day.date() > 7) day.add(7, 'd');
        const month = day.month();
        while (month === day.month()) {
          //document.body.innerHTML += "<p>" + day.toString() + "</p>";

          // eslint-disable-next-line
          data.hours.map((hour) => {
            const date = {
              start: new Date(day),
              end: new Date(day),
              title: hour,
            };
            dates.push(date);
          });
          day.add(7, 'd');
        }
      });
    return dates;
  };

  const onNavigate = (date, leftAction, rightAction) => {
    switch (rightAction) {
      case 'PREV':
        setIndex(index - 1);
        break;
      case 'NEXT':
        setIndex(index + 1);
        break;
      default:
        setIndex(0);
        break;
    }
  };

  // const events = [
  //   {
  //     start: moment().toDate(),
  //     end: moment()
  //       .add(1, "days")
  //       .toDate(),
  //     title: "Some title"
  //   }
  // ]
  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h2 className="title">Media Calendar</h2>
          {log(events)}
          {events ? (
            <Calendar
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={events}
              style={{ height: '100vh' }}
              views={['month']}
              onNavigate={onNavigate}
            />
          ) : null}
        </div>
      </section>
    </>
  );
};

export default MediaCalendar;
