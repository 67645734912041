import { Form, Input, message, Modal, Select, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { screensCleanUp } from 'state/actions/screens';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { TrashIcon } from 'assets/icon';
import './ModalTemplateForm.css';

export default function ModalTemplateForm({
  template,
  setTemplate,
  visible,
  onCancel,
  onCreate,
  history,
}) {
  const dispatch = useDispatch();
  const { siteId, siteName } = useParams();

  const [form] = Form.useForm();

  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [file, setFile] = useState();
  const { loading } = useSelector(
    (state) => ({
      loading: state.sites.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });
  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    return () => dispatch(screensCleanUp());
  }, [dispatch]);
  useEffect(() => {
    setImagePreviewUrl(
      file
        ? URL.createObjectURL(file)
        : template.logo && `${imgUrl}/media/${template.logo}`
    );
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setTemplate);

  const onSave = (values) => {
    console.log(values, 'values');
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else if (!template.width || !template.height || !values.orientation) {
      console.log('here');
      setErrors({
        ...errors,
        size: !values.width && 'This field is required',
        orientation: !values.orientation && 'This field is required',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        template,
        file,
      });
      form.resetFields();
      history.push(`/${siteId}/information/${siteName}/template/new`);
    }
  };

  const handleChangeSize = (value) => {
    const sizes = value.split('x');
    setTemplate({
      ...template,
      width: parseInt(sizes[0]),
      height: parseInt(sizes[1]),
    });
  };

  const handleChangeBackground = (value) => {
    setTemplate({
      ...template,
      backgroundType: value,
    });
  };
  const handleChangeOrientation = (value) => {
    setTemplate({
      ...template,
      orientation: value,
    });
  };

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      if (file.size / 1000000 <= 300) {
        console.log(file, 'file');

        // setMedia((prevState) => ({ ...prevState, file, logo: null }));
        setFile(file);
        // setTemplate({
        //   ...template,
        //   file: file,
        // });

        return false;
      } else {
        message.error('Maximum file size is 300MB');
      }
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };
  const handleDeletePreviewImage = () => {
    setFile(null);
    setImagePreviewUrl(null);
  };
  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={'Add New Template'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onSave(values);
                })
                .catch((info) => {
                  log('Validate Failed:', info);
                });
            }}
          >
            Submit
          </button>
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={template}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
        <Form.Item
          label="Background Type"
          name="backgroundType"
          initialValue={`${template.backgroundType ?? 'color'}`}
          validateStatus={errors.backgroundType ? 'error' : 'success'}
          help={errors.backgroundType ? errors.backgroundType : null}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleChangeBackground}
            options={[
              { value: 'color', label: 'Color' },
              { value: 'image', label: 'Image' },
            ]}
          />
        </Form.Item>
        {template.backgroundType &&
          (template.backgroundType === 'color' ? (
            <Form.Item
              label="Color"
              name="color"
              validateStatus={errors.color ? 'error' : 'success'}
              help={errors.color ? errors.color : null}
            >
              <Input placeholder="Color" value={template.color} type="color" />
            </Form.Item>
          ) : (
            <Form.Item
              label="Background Image"
              name="file"
              validateStatus={errors.file ? 'error' : 'success'}
              help={errors.file ? errors.file : null}
            >
              {imagePreviewUrl ? (
                <div className="upload-image-preview">
                  <Tooltip placement="top" title="Delete">
                    <button
                      type="button"
                      onClick={handleDeletePreviewImage}
                      className="btn-remove"
                    >
                      <TrashIcon size={18} color="#CB3A31" />
                    </button>
                  </Tooltip>
                  {
                    <img
                      className="media-avatar"
                      src={imagePreviewUrl}
                      alt="User profile logo preview"
                    />
                  }
                </div>
              ) : (
                <Upload
                  showUploadList={false}
                  beforeUpload={onFileChangedHandler}
                  style={{ width: '100%' }}
                >
                  <div
                    onDragLeave={() => setIsDragged(false)}
                    onDragOver={() => setIsDragged(true)}
                    className={`upload-drag-and-drop ${
                      isDragged ? 'active' : ''
                    }`}
                  >
                    <ExportIcon size={22} color="#C2C2C2" />
                    <p className="upload-title">
                      Drag or <span className="upload-highlight">Browse</span>
                    </p>
                    <p className="upload-subtitle">
                      File format supported: jpg, png, mp4 (Up to 50MB){' '}
                    </p>
                  </div>
                </Upload>
              )}
            </Form.Item>
          ))}
        <Form.Item
          label="Canvas Size"
          name="size"
          validateStatus={errors.size ? 'error' : 'success'}
          help={errors.size ? errors.size : null}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleChangeSize}
            options={[
              { value: '1920x1080', label: 'Full HD(1920 x 1080)' },
              { value: '3840x2160', label: 'Ultra HD(3840 x 2160)' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Orientation"
          name="orientation"
          validateStatus={errors.size ? 'error' : 'success'}
          help={errors.size ? errors.size : null}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleChangeOrientation}
            options={[
              { value: 'portrait', label: 'Portrait' },
              { value: 'landscape', label: 'Landscape' },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModalTemplateForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};
