import { Form, Input, Modal, Select } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { screensCleanUp } from 'state/actions/screens';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';

export default function ModalTemplateElementForm({
  element,
  setElement,
  visible,
  onCancel,
  onCreate,
  history,
}) {
  const dispatch = useDispatch();
  const { categoriesList } = useSelector(
    (state) => ({
      categoriesList: state.mediaCategories.list,
    }),
    shallowEqual
  );

  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.sites.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    return () => dispatch(screensCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setElement);

  const onSave = (values) => {
    console.log(values, 'values');
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else if (!element.categoryId) {
      console.log('here');
      setErrors({
        ...errors,
        categoryId: !values.categoryId && 'This field is required',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        element,
      });
      form.resetFields();
    }
  };

  const handleChangeCategory = (value) => {
    setElement({
      ...element,
      categoryId: value,
    });
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={element ? 'Edit Element' : 'Add New Element'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onSave(values);
                })
                .catch((info) => {
                  log('Validate Failed:', info);
                });
            }}
          >
            Submit
          </button>
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={element}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Media Category"
          name="categoryId"
          validateStatus={errors.size ? 'error' : 'success'}
          help={errors.categoryId ? errors.categoryId : null}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleChangeCategory}
            options={categoriesList.map((cat) => {
              return { value: cat._id, label: cat.name };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
