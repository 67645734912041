import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';
import moment from 'moment';
import fileDownload from 'js-file-download';

export const MEDIAANALYTIC_FETCH_DATA_INIT = createAction(
  'MEDIAANALYTIC_FETCH_DATA_INIT'
);
export const MEDIAANALYTIC_FETCH_DATA_SUCCESS = createAction(
  'MEDIAANALYTIC_FETCH_DATA_SUCCESS'
);
export const MEDIAANALYTIC_FETCH_DATA_FAIL = createAction(
  'MEDIAANALYTIC_FETCH_DATA_FAIL'
);

export const MEDIAANALYTIC_EXPORT_LOG_INIT = createAction(
  'MEDIAANALYTIC_EXPORT_LOG_INIT'
);
export const MEDIAANALYTIC_EXPORT_LOG_SUCCESS = createAction(
  'MEDIAANALYTIC_EXPORT_LOG_SUCCESS'
);
export const MEDIAANALYTIC_EXPORT_LOG_FAIL = createAction(
  'MEDIAANALYTIC_EXPORT_POI_FAIL'
);

export const MEDIAANALYTIC_CLEAN_UP = createAction('MEDIAANALYTIC_CLEAN_UP');

export const fetchMediaAnalytic = ({ siteId }) => {
  return async (dispatch) => {
    dispatch(MEDIAANALYTIC_FETCH_DATA_INIT());
    let mediaanalytics;
    try {
      mediaanalytics = await axios.post(
        `${url}/mediaanalytic/get`,
        {
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(MEDIAANALYTIC_FETCH_DATA_FAIL({ error }));
    }

    console.log(mediaanalytics.data.data, 'media analytic');

    return dispatch(
      MEDIAANALYTIC_FETCH_DATA_SUCCESS({
        list: mediaanalytics.data.data,
      })
    );
  };
};

export const downloadMediaAnalytic = () => {
  return async (dispatch) => {
    dispatch(MEDIAANALYTIC_EXPORT_LOG_INIT());
    axios
      .get(`${url}/mediaanalytic/export`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `mediaanalytics_export_${moment(new Date()).format('YYYY-MM-DD')}.csv`
        );
        return dispatch(MEDIAANALYTIC_EXPORT_LOG_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MEDIAANALYTIC_EXPORT_LOG_FAIL({ error }));
      });
  };
};

export const mediaanalyticsCleanUp = () => (dispatch) =>
  dispatch(MEDIAANALYTIC_CLEAN_UP());
