import { createReducer } from 'redux-act';

import {
  MEDIAANALYTIC_FETCH_DATA_INIT,
  MEDIAANALYTIC_FETCH_DATA_SUCCESS,
  MEDIAANALYTIC_FETCH_DATA_FAIL,
  MEDIAANALYTIC_EXPORT_LOG_INIT,
  MEDIAANALYTIC_EXPORT_LOG_SUCCESS,
  MEDIAANALYTIC_EXPORT_LOG_FAIL,
  MEDIAANALYTIC_CLEAN_UP,
} from 'state/actions/mediaAnalytic';

const initialState = {
  list: [],
  loading: false,
  error: null,
  deleted: false,
  downloading: false,
};

export const mediaAnalyticsReducer = createReducer(
  {
    [MEDIAANALYTIC_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEDIAANALYTIC_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      loading: false,
      error: null,
    }),
    [MEDIAANALYTIC_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIAANALYTIC_EXPORT_LOG_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [MEDIAANALYTIC_EXPORT_LOG_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
    }),
    [MEDIAANALYTIC_EXPORT_LOG_FAIL]: (state) => ({
      ...state,
      downloading: false,
    }),
    [MEDIAANALYTIC_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
  },
  initialState
);
