import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const CONFIGS_FETCH_DATA_INIT = createAction('CONFIGS_FETCH_DATA_INIT');
export const CONFIGS_FETCH_DATA_SUCCESS = createAction(
  'CONFIGS_FETCH_DATA_SUCCESS'
);
export const CONFIGS_FETCH_DATA_FAIL = createAction('CONFIGS_FETCH_DATA_FAIL');

export const CONFIGS_DELETE_CONFIG_INIT = createAction(
  'CONFIGS_DELETE_CONFIG_INIT'
);
export const CONFIGS_DELETE_CONFIG_SUCCESS = createAction(
  'CONFIGS_DELETE_CONFIG_SUCCESS'
);
export const CONFIGS_DELETE_CONFIG_FAIL = createAction(
  'CONFIGS_DELETE_CONFIG_FAIL'
);

export const CONFIGS_CLEAR_DATA = createAction('CONFIGS_CLEAR_DATA');

export const CONFIGS_CREATE_CONFIG_INIT = createAction(
  'CONFIGS_CREATE_CONFIG_INIT'
);
export const CONFIGS_CREATE_CONFIG_SUCCESS = createAction(
  'CONFIGS_CREATE_CONFIG_SUCCESS'
);
export const CONFIGS_CREATE_CONFIG_FAIL = createAction(
  'CONFIGS_CREATE_CONFIG_FAIL'
);

export const CONFIGS_MODIFY_CONFIG_INIT = createAction(
  'CONFIGS_MODIFY_CONFIG_INIT'
);
export const CONFIGS_MODIFY_CONFIG_SUCCESS = createAction(
  'CONFIGS_MODIFY_CONFIG_SUCCESS'
);
export const CONFIGS_MODIFY_CONFIG_FAIL = createAction(
  'CONFIGS_MODIFY_CONFIG_FAIL'
);

export const CONFIGS_CLEAN_UP = createAction('CONFIGS_CLEAN_UP');

export const CONFIGS_CLEAR_DATA_LOGOUT = createAction(
  'CONFIGS_CLEAR_DATA_LOGOUT'
);

export const SET_CONFIG_TAB = createAction('SET_CONFIG_TAB');
export const SET_ACTIVE_CONFIG = createAction('SET_ACTIVE_CONFIG');

export const fetchConfigs = ({ siteId }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_FETCH_DATA_INIT());

    let config;

    try {
      const res = await axios.get(`${url}/configuration/`, {
        params: {
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      config = res.data.data;
      log(config, 'config response');
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(CONFIGS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      CONFIGS_FETCH_DATA_SUCCESS({
        config,
      })
    );
  };
};

export const deleteConfig = (id) => {
  return async (dispatch) => {
    dispatch(CONFIGS_DELETE_CONFIG_INIT());

    try {
      await axios.delete(`${url}/config/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_DELETE_CONFIG_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The config was deleted.');
    return dispatch(CONFIGS_DELETE_CONFIG_SUCCESS({ id }));
  };
};

export const configUpdate = ({ siteId, colors, medias }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('colors', JSON.stringify(colors));
    config.set('siteId', siteId);
    const listMedia = [];
    for (let i = 0; i < medias.length; i += 1) {
      const media = medias[i];
      if (media.file)
        config.append('file', media.file, `medias-${i}-${media.file.name}`);
      listMedia.push({ name: media.name, logo: media.logo });
    }
    config.set('medias', JSON.stringify(listMedia));

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};

export const updateConfig = ({
  name,
  description,
  siteId,
  siteName,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = {
      name,
      description,
      siteId,
      siteName,
      modifiedAt,
      modifiedBy,
    };

    try {
      await axios.post(`${url}/config/create`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'config created successfully');
    return dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
  };
};

export const modifyConfig = ({ name, description, siteId, siteName, id }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_MODIFY_CONFIG_INIT());

    const configData = {
      name,
      description,
      siteId,
      siteName,
    };

    try {
      await axios.post(`${url}/config/${id}`, configData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_MODIFY_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Config updated successfully');

    return dispatch(CONFIGS_MODIFY_CONFIG_SUCCESS({ config: configData, id }));
  };
};

export const configsCleanUp = () => (dispatch) => dispatch(CONFIGS_CLEAN_UP());

// added by me
export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch(SET_CONFIG_TAB(tab));
  };
};

export const setActiveConfig = (config, cb) => {
  return (dispatch) => {
    dispatch(SET_ACTIVE_CONFIG(config));
    cb();
  };
};
