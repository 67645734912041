import { createReducer } from 'redux-act';

import {
  SUBCATEGORIES_FETCH_DATA_INIT,
  SUBCATEGORIES_FETCH_DATA_SUCCESS,
  SUBCATEGORIES_FETCH_DATA_FAIL,
  SUBCATEGORIES_DELETE_CATEGORY_INIT,
  SUBCATEGORIES_DELETE_CATEGORY_SUCCESS,
  SUBCATEGORIES_DELETE_CATEGORY_FAIL,
  SUBCATEGORIES_CLEAR_DATA,
  SUBCATEGORIES_CREATE_CATEGORY_INIT,
  SUBCATEGORIES_CREATE_CATEGORY_SUCCESS,
  SUBCATEGORIES_CREATE_CATEGORY_FAIL,
  SUBCATEGORIES_MODIFY_CATEGORY_INIT,
  SUBCATEGORIES_MODIFY_CATEGORY_SUCCESS,
  SUBCATEGORIES_MODIFY_CATEGORY_FAIL,
  SUBCATEGORIES_CLEAN_UP,
  SUBCATEGORIES_CLEAR_DATA_LOGOUT,
  SET_SUBCATEGORY,
} from 'state/actions/subCategories';

const initialState = {
  list: [],
  subCategory: {
    _id: '',
    name: '',
    description: '',
    siteId: '',
    subCategoryId: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
  updated: false,
};

export const subCategoriesReducer = createReducer(
  {
    [SUBCATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SUBCATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      subCategory: payload.subCategory || initialState.subCategory,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [SUBCATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUBCATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SUBCATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [SUBCATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUBCATEGORIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [SUBCATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SUBCATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.subCategory),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [SUBCATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUBCATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SUBCATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.id) {
              return {
                _id: payload.id,
                name: payload.subCategory.name,
                description: payload.subCategory.description,
                siteId: payload.subCategory.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.subCategory.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [SUBCATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUBCATEGORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
      updated: false,
    }),
    [SUBCATEGORIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_SUBCATEGORY]: (state, payload) => ({
      ...state,
      subCategory: payload.data,
    }),
  },
  initialState
);
