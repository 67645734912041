import { CircularProgress } from '@material-ui/core';
import { Table } from 'antd';
import { ExportIcon } from 'assets/icon/dynamic';
import {
  ChartTitle,
  ChartWrapper,
  ReportWrapper,
} from 'components/Report/styles';
import React, { useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import { shallowEqual, useSelector } from 'react-redux';
// import ScreensaverReport from './ScreensaverReport';

const AnalyticReport = (props) => {
  const { analytics, loading, type, onExport } = props;
  const INITIAL_STATE = {
    labels: [],
    datasets: [],
  };

  const [reportData, setReportData] = useState(INITIAL_STATE);

  const { dataTable } = useSelector(
    (state) => ({
      dataTable: state.analytic.rawData,
      loadingTable: state.analytic.loadingCalculate,
      screens: state.screens.list,
    }),
    shallowEqual
  );

  const options = {
    tooltips: {
      displayColors: true,
      callbacks: {
        mode: 'x',
      },
    },
    // events: [],
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    animation: {
      duration: 1,
      onComplete() {
        const chartInstance = this.chart;
        const { ctx } = chartInstance;

        // ctx.font = Chart.helpers.fontString(
        //   Chart.defaults.global.defaultFontSize,
        //   Chart.defaults.global.defaultFontStyle,
        //   Chart.defaults.global.defaultFontFamily
        // );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        console.log(this, 'on complete data');
        this.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          console.log(meta, 'meta');
          if (!meta.hidden) {
            meta.data.forEach(function (bar, index) {
              if (dataset.data[index] > 0) {
                const data = dataset.data[index];
                ctx.font = 'bold 12pt roboto';
                ctx.fillText(data, bar._model.x, bar._model.y);
              }
            });
          }
        });
      },
    },
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    layout: {
      padding: 20,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const optionsTC = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      // yAxes: [
      //   {
      //     ticks: {
      //       beginAtZero: true,
      //     },
      //   },
      // ],
    },
    // animation: {
    //   duration: 1,
    //   onComplete() {
    //     const chartInstance = this.chart;
    //     const { ctx } = chartInstance;

    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'bottom';

    //     this.data.datasets.forEach(function (dataset, i) {
    //       const meta = chartInstance.controller.getDatasetMeta(i);
    //       meta.data.forEach(function (bar, index) {
    //         if (dataset.data[index] > 0) {
    //           const data = dataset.data[index];
    //           const textwidth = ctx.measureText(data).width;
    //           ctx.fillStyle = bar._model.backgroundColor;
    //           ctx.font = 'bold 12pt roboto';
    //           ctx.fillText(data, bar._model.x - 20, bar._model.y);

    //           ctx.font = 'normal 12pt roboto';
    //           ctx.fillStyle = '#404040';
    //           ctx.fillText(' people', bar._model.x + textwidth, bar._model.y);
    //         }
    //       });
    //     });
    //   },
    // },
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 15,
        borderRadius: 10,
        useBorderRadius: true,
        fontStyle: 'bold',
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  let columns;
  if (type === 'trafficcounter') {
    columns = [
      {
        title: '#',
        dataIndex: 'index',
        width: '10%',
        render: (text, record, index) => (
          <div className="table-name">{index + 1}</div>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        width: '40%',
        render: (text, record) => <div className="table-name">{text}</div>,
      },
      {
        title: 'Total Human Count',
        dataIndex: 'data',
        width: '20%',
        render: (text, record) => (
          <div className="table-name">{text?.toLocaleString()}</div>
        ),
      },
    ];
  } else {
    columns = [
      {
        title: 'Total Action Counts',
        dataIndex: 'count',
        width: '10%',
        sorter: (a, b) => a.count - b.count,
        render: (text) => <div className="table-name">{text}</div>,
      },
      {
        title: '#select',
        dataIndex: 'select',
        width: '10%',
        sorter: (a, b) => a.select - b.select,
        render: (text) => <div className="table-name">{text}</div>,
      },
      {
        title: '#goTo',
        dataIndex: 'goto',
        width: '10%',
        sorter: (a, b) => a.goto - b.goto,
        render: (text) => <div className="table-name">{text}</div>,
      },

      {
        title: 'Label',
        dataIndex: 'name',
        width: '40%',
        render: (text) => <div className="table-name">{text}</div>,
      },
    ];
  }

  const colorPallete = [
    '#2C82F6',
    '#BFD9FC',
    '#7FB1F9',
    '#C6DFFD',
    '#1F69D8',
    '#1B5DBB',
  ];
  useEffect(() => {
    console.log(analytics, 'analytics in report');
    if (analytics && !loading) {
      const actions = [];
      Object.values(analytics).forEach((val) => {
        Object.keys(val).forEach((loc) => {
          console.log(loc, 'loc');
          if (actions.indexOf(loc) === -1) {
            actions.push(loc);
          }
        });
      });

      console.log(actions, 'labels');
      setReportData({
        labels: Object.keys(analytics),
        datasets: actions.map((set, index) => {
          const color = colorPallete[index];
          // monthData.push({
          //   location: set,
          //   data: Object.values(analytics)
          //     .map((val) => val[set])
          //     ?.reduce((a, b) => (b ? a + b : a), 0),
          // });
          return {
            label: set,
            data: Object.values(analytics).map((val) => val[set]),
            borderWidth: 1,
            stack: 'Stack 0',
            backgroundColor: color,
            strokeColor: color,
          };
        }),
      });
      // let dataMonth = [];
      // dataMonth = locations.map((loc) => {
      //   return {
      //     location: loc.location,
      //     ratio: _.groupBy(loc.data, 'gender'),
      //   };
      // });
      // console.log(dataMonth, 'data month');
      // setDataTable(_.sortBy(dataMonth, ['location']));
    }
  }, [analytics]); // eslint-disable-line

  console.log(dataTable, 'data table');

  return (
    <div className="analytic-chart">
      {/* <div className="field-body"> */}

      {/* </div> */}
      <ReportWrapper>
        <ChartTitle>Analytics</ChartTitle>

        {/* <div className="selector">
          {locations && (
            <div className="m-2">
              <Select
                // defaultValue={loc}
                placeholder="Location"
                style={{ width: 120 }}
                onChange={(val) => {
                  setReportData();
                  setLoc(val);
                }}
                options={[
                  {
                    value: null,
                    label: 'All location',
                  },
                  ...locations.map((location) => {
                    return {
                      value: location,
                      label: location,
                    };
                  }),
                ]}
              />
            </div>
          )}
        </div> */}

        {/* <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{"Start Date"}</label>
          </div>
          <div className="field-label is-normal">
            <label className="label">{"End Date"}</label>
          </div>
        </div> */}
        {reportData && !loading ? (
          <ChartWrapper>
            <Bar
              data={reportData}
              width={100}
              height={250}
              options={type === 'trafficcounter' ? optionsTC : options}
            />
          </ChartWrapper>
        ) : (
          <CircularProgress />
        )}
      </ReportWrapper>
      {onExport && type !== 'trafficcounter' && (
        <div className="leaderboard-export">
          <button className="btn-outline-primary" onClick={onExport}>
            <ExportIcon color="#64D5D2" size={18} />
            Export Summary
          </button>
        </div>
      )}
      <Table columns={columns} dataSource={dataTable} />
    </div>
  );
};

export default AnalyticReport;
