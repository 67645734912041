import { Checkbox } from 'antd';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';
import { fetchUsers, modifyUserAccess } from 'state/actions/users';
import './UserManagementSys.css';

function UserManagementSys({ match, history }) {
  const { id } = useParams();

  const {
    // isAdmin,
    organizationsList,
    userData,
    sitesList,
    success,
    role,
    userAdmin
  } = useSelector(
    (state) => ({
      userAdmin: state.auth.userData,
      role: state.auth.userData.role,
      error: state.users.error,
      loading: state.users.loading,
      organizations: state.auth.organizations,
      organizationData: state.auth.organizationData,
      organizationId: state.auth.oranizationId,
      organizationsList: state.organizations.list,
      sitesList: state.sites.list,
      sites: state.sites.sites,
      userData: state.users.user,
      success: state.users.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [userAccess, setUserAccess] = useState([]);

  useEffect(() => {
    dispatch(fetchUsers(match.params.id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData && userData.access) {
      const defaultSelectedAccess = sitesList.map((siteList) => {
        if(userData.access.length>1 && userData.role!=='superadmin'){
          const findExisting = userData.access.find(
            (access) => access.siteId === siteList._id
          );
          return {
            api: findExisting ? findExisting.api : false,
            media: findExisting ? findExisting.media : false,
            poi: findExisting ? findExisting.poi : false,
            maps: findExisting ? findExisting.maps : false,
            information: findExisting ? findExisting.information : false,
            feedback: findExisting ? findExisting.feedback : false,
            siteId: siteList._id,
          };

        } else if(userData.access.length<2 && siteList.organizationId===userData.organizationId){
          const findExisting = userData.access[0];
          return {
            api: findExisting ? findExisting.api : false,
            media: findExisting ? findExisting.media : false,
            poi: findExisting ? findExisting.poi : false,
            maps: findExisting ? findExisting.maps : false,
            information: findExisting ? findExisting.information : false,
            feedback: findExisting ? findExisting.feedback : false,
            siteId: siteList._id,
          };
        } else{
          return {
            api: false,
            media:  false,
            poi: false,
            maps: false,
            information:  false,
            feedback:  false,
            siteId: siteList._id,
          };

        }
        
      });
      setUserAccess(defaultSelectedAccess);
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      history.push(`/users`); // eslint-disable-line react-hooks/exhaustive-deps
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps
  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(
      modifyUserAccess({ id: id, user: userData, accessObject: userAccess })
    );
  };

  const onCheckboxChanged = (event, siteId, access) => {
    const newUserAccess = [...userAccess];
    const findUserAccess = newUserAccess.find(
      (access) => access.siteId === siteId
    );
    if (access === 'all') {
      if (role === "superadmin") {
        if (event.target.checked) {
          findUserAccess.api = true;
          findUserAccess.poi = true;
          findUserAccess.media = true;
          findUserAccess.information = true;
          findUserAccess.feedback = true;
          findUserAccess.maps = true;
        } else {
          findUserAccess.api = false;
          findUserAccess.poi = false;
          findUserAccess.media = false;
          findUserAccess.information = false;
          findUserAccess.feedback = false;
          findUserAccess.maps = false;
        }

      } else if (role === "admin") {
        const allAccess = Object.keys(userAdmin.access[0]).filter((keys) => userAdmin.access[0][keys] === true);
        if (event.target.checked) {
          allAccess.map((acc) => findUserAccess[acc] = true);
        } else {
          findUserAccess.api = false;
          findUserAccess.poi = false;
          findUserAccess.media = false;
          findUserAccess.information = false;
          findUserAccess.feedback = false;
          findUserAccess.maps = false;
        }
      }

    }

    findUserAccess[access] = event.target.checked;

    setUserAccess(newUserAccess);
  };

  const handleReset = () => {
    setUserAccess(null);
  };
  return (
    <div className="user">
      {redirect}
      <div className="user-header">
        <h1>Access User</h1>
        <div>
          <h5>User Name : {userData.name}</h5>
          <h5>Email : {userData.email}</h5>
        </div>
      </div>
      <div className="user-breadcrumb">
        <ul>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>Access User</li>
        </ul>
        <Link to="/users" className="button-secondary">
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="user-management-content">
        {organizationsList &&
          organizationsList
            // eslint-disable-next-line
            .map((organization) => {
              if (role === 'admin') {
                if (userData.organizationId === organization._id) {
                  return (
                    <div className="user-access-wrapper" key={organization._id}>
                      <div className="user-access">
                        <h3 className="user-access-organization-title">
                          {organization.name}
                        </h3>
                        <div className="user-access-option">
                          <div>
                            <p>Select All</p>
                          </div>
                          <div>
                            <p>API</p>
                          </div>
                          <div>
                            <p>POI</p>
                          </div>
                          <div>
                            <p>Media</p>
                          </div>
                          <div>
                            <p>Maps</p>
                          </div>
                          <div>
                            <p>Information</p>
                          </div>
                          <div>
                            <p>Feedback</p>
                          </div>
                        </div>
                      </div>
                      {sitesList
                        .filter(
                          (site) => site.organizationId === organization._id
                        )
                        .map((site) => {
                          const access =
                            userAccess.length>1?userAccess &&
                            userAccess.find((data) => data.siteId === site._id):userAccess[0];
                          const adminAccess = userAdmin.access.length > 1 ? userAdmin.access.find((acc) => acc.siteId === site._id) : userAdmin.access[0];
                          const allAccess = Object.keys(userAdmin.access[0]).filter((keys) => userAdmin.access[0][keys] === true);
                          let isAllChecked = true;
                          for (const acc of allAccess) {
                            if (access && access[acc] === false) {
                              isAllChecked = false;
                              break;
                            }

                          }

                          return (
                            <div className="user-access-sites" key={site._id}>
                              <p>{site.name}</p>
                              <div className="user-access-selection">
                                <div>
                                  <Checkbox
                                    checked={isAllChecked}
                                    onChange={(event) =>
                                      onCheckboxChanged(event, site._id, 'all')
                                    }
                                  />
                                </div>
                                <div>
                                  <Checkbox
                                    checked={access && access.api}
                                    disabled={!adminAccess.api}
                                    onChange={(event) =>
                                      onCheckboxChanged(event, site._id, 'api')
                                    }
                                  />
                                </div>
                                <div>
                                  <Checkbox
                                    checked={access && access.poi}
                                    onChange={(event) =>
                                      onCheckboxChanged(event, site._id, 'poi')
                                    }
                                    disabled={!adminAccess.poi}
                                  />
                                </div>
                                <div>
                                  <Checkbox
                                    checked={access && access.media}
                                    disabled={!adminAccess.media}
                                    onChange={(event) =>
                                      onCheckboxChanged(
                                        event,
                                        site._id,
                                        'media'
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <Checkbox
                                    checked={access && access.maps}
                                    disabled={!adminAccess.maps}
                                    onChange={(event) =>
                                      onCheckboxChanged(event, site._id, 'maps')
                                    }
                                  />
                                </div>

                                <div>
                                  <Checkbox
                                    checked={access && access.information}
                                    disabled={!adminAccess.information}
                                    onChange={(event) =>
                                      onCheckboxChanged(
                                        event,
                                        site._id,
                                        'information'
                                      )
                                    }
                                  />
                                </div>

                                <div>
                                  <Checkbox
                                    checked={access && access.feedback}
                                    disabled={!adminAccess.feedback}
                                    onChange={(event) =>
                                      onCheckboxChanged(
                                        event,
                                        site._id,
                                        'feedback'
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                }
              }
              else if (role === 'superadmin') {
                // if (userData.organizationId === organization._id) {
                return (
                  <div className="user-access-wrapper" key={organization._id}>
                    <div className="user-access">
                      <h3 className="user-access-organization-title">
                        {organization.name}
                      </h3>
                      <div className="user-access-option">
                        <div>
                          <p>Select All</p>
                        </div>
                        <div>
                          <p>API</p>
                        </div>
                        <div>
                          <p>POI</p>
                        </div>
                        <div>
                          <p>Media</p>
                        </div>
                        <div>
                          <p>Maps</p>
                        </div>
                        <div>
                          <p>Information</p>
                        </div>
                        <div>
                          <p>Feedback</p>
                        </div>
                      </div>
                    </div>
                    {sitesList
                      .filter(
                        (site) => site.organizationId === organization._id
                      )
                      .map((site) => {
                        const access = userAccess.length>1?
                          userAccess &&
                          userAccess.find((data) => data.siteId === site._id):userAccess[0];
                        const isAllChecked =
                          access &&
                          access.api &&
                          access.poi &&
                          access.media &&
                          access.maps &&
                          access.information &&
                          access.feedback;
                        return (
                          <div className="user-access-sites" key={site._id}>
                            <p>{site.name}</p>
                            <div className="user-access-selection">
                              <div>
                                <Checkbox
                                  checked={isAllChecked}
                                  onChange={(event) =>
                                    onCheckboxChanged(event, site._id, 'all')
                                  }
                                />
                              </div>
                              <div>
                                <Checkbox
                                  checked={access && access.api}
                                  onChange={(event) =>
                                    onCheckboxChanged(event, site._id, 'api')
                                  }
                                />
                              </div>
                              <div>
                                <Checkbox
                                  checked={access && access.poi}
                                  onChange={(event) =>
                                    onCheckboxChanged(event, site._id, 'poi')
                                  }
                                />
                              </div>
                              <div>
                                <Checkbox
                                  checked={access && access.media}
                                  onChange={(event) =>
                                    onCheckboxChanged(event, site._id, 'media')
                                  }
                                />
                              </div>

                              <div>
                                <Checkbox
                                  checked={access && access.maps}
                                  onChange={(event) =>
                                    onCheckboxChanged(event, site._id, 'maps')
                                  }
                                />
                              </div>

                              <div>
                                <Checkbox
                                  checked={access && access.information}
                                  onChange={(event) =>
                                    onCheckboxChanged(
                                      event,
                                      site._id,
                                      'information'
                                    )
                                  }
                                />
                              </div>

                              <div>
                                <Checkbox
                                  checked={access && access.feedback}
                                  onChange={(event) =>
                                    onCheckboxChanged(
                                      event,
                                      site._id,
                                      'feedback'
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
                // }
              }
            })}
      </div>
      <div className="button-wrapper">
        <button className="button-primary" onClick={onSubmitHandler}>
          Save Changes
        </button>
        <button className="button-secondary" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div >
  );
}

export default UserManagementSys;
