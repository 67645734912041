import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { regexInput } from 'utils/regex';
// import './User.css';
import PageHeader from 'design-components/PageHeader';
import { fetchMediaAnalytic } from 'state/actions/mediaAnalytic';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { imgUrl } from 'utils/url';
import { isEmpty } from 'underscore';
import moment from 'moment';
import './MediaAnalytic.scss';
import { fetchMediaCategories } from 'state/actions/mediaCategories';

function MediaAnalytic() {
  const [search, setSearch] = useState('');

  const {
    mediaAnalytic,
    // isAdmin,
    categoriesList,
    error,
    loading,
  } = useSelector(
    (state) => ({
      mediaAnalytic: state.mediaanalytic.list,
      categoriesList: state.mediaCategories.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { siteId } = useParams();
  useEffect(() => {
    dispatch(fetchMediaCategories({ siteId }));
    dispatch(fetchMediaAnalytic({ siteId }));
  }, [siteId]); // eslint-disable-line

  const [searchError, setSearchError] = useState(null);

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  console.log(mediaAnalytic, 'data');
  // const redirect =
  //   role === 'admin' || role === 'superadmin' ? (
  //     false
  //   ) : (
  //     <Redirect to={paths.ROOT} />
  //   );

  const getCategoryName = (categoryId) => {
    const category = categoriesList.find((cat) => cat._id === categoryId);
    return category?.name;
  };

  const columns = [
    {
      title: 'Media Name',
      dataIndex: 'name',
      sorter: (a, b) => a.mediaId?.name.localeCompare(b.mediaId?.name),
      render: (text, record) => (
        <div className="table-name">{record.mediaId?.name}</div>
      ),
    },
    {
      title: 'Image',
      dataIndex: 'logo',
      sorter: (a, b) => a.mediaId?.logo.localeCompare(b.mediaId?.logo),
      render: (text, record) =>
        // eslint-disable-next-line
        record.mediaId?.logo &&
        record.mediaId?.fileType &&
        record.mediaId?.fileType.split('/')[0] === 'image' ? (
          <img
            src={`${imgUrl}/media/${record.mediaId?.logo}`}
            alt="default"
            className="card-list-img"
            draggable={false}
          />
        ) : record.mediaId?.logo &&
          record.mediaId?.fileType &&
          record.mediaId?.fileType.split('/')[0] === 'video' ? (
          <video
            id="my-video"
            className="video-js card-list-video"
            src={`${imgUrl}/media/${record.mediaId?.logo}`}
          />
        ) : null,
    },

    {
      title: 'Media Category',
      dataIndex: 'category',
      sorter: (a, b) =>
        getCategoryName(a.categoryId).localeCompare(
          getCategoryName(b.categoryId)
        ),
      render: (text, record) => (
        <div className="table-name">{getCategoryName(record.categoryId)}</div>
      ),
    },
    {
      title: 'Start - End Date',
      dataIndex: 'date',

      render: (text, record) => (
        <div className="table-name">
          {record.mediaId?.type === 'date'
            ? `${moment(record.mediaId?.start).format(
                'DD-MM-YYYY hh:mm'
              )} - ${moment(record.mediaId?.end).format('DD-MM-YYYY hh:mm')}`
            : record.mediaId?.type === 'day'
            ? `${record.mediaId?.days[0].day} - ${
                record.mediaId?.days[record.mediaId?.days.length - 1].day
              }`
            : record.mediaId?.start && record.mediaId?.end
            ? `${moment(record.mediaId?.start).format(
                'DD-MM-YYYY hh:mm'
              )} - ${moment(record.mediaId?.end).format('DD-MM-YYYY hh:mm')}`
            : 'All Day'}
        </div>
      ),
    },
    {
      title: 'Number of Loop (Today)',
      dataIndex: 'today',
      render: (text, record) => (
        <div className="table-name">{record.countToday || 0}</div>
      ),
    },
    {
      title: 'Number of Loop (All-Time)',
      dataIndex: 'alltime',
      sorter: (a, b) => a.count - b.count,
      render: (text, record) => (
        <div className="table-name">{record.count}</div>
      ),
    },
  ];

  const data = search
    ? mediaAnalytic.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.mediaId?.name &&
          clonedElem.mediaId?.name.toLowerCase().includes(search.toLowerCase());

        if (name) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      mediaAnalytic;

  return (
    <section className="panel-user">
      {/* {redirect} */}
      <PageHeader
        title="Media Analytics"
        isGrid={false}
        isSearch
        searchValue={search}
        searchAction={handleSearch}
        searchPlaceholder="Enter Media's Name"
        searchError={searchError}
        isAdd={false}
        addLabel=""
      />

      <div className="panel-user__table">
        {loading ? (
          <ClipLoader />
        ) : (
          !isEmpty(data) && (
            <Table columns={columns} dataSource={data} rowKey="id" />
          )
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default MediaAnalytic;
