/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Input, Modal, Tooltip, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { categoriesCleanUp } from 'state/actions/categories';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';
import './ModalCategoryForm.scss';
import { imgUrl } from 'utils/url';
import { ExportIcon, TrashIcon } from 'assets/icon/dynamic';

const ModalSubCategoryForm = ({
  subCategory,
  setSubCategory,
  visible,
  isEditing,
  onCancel,
  onCreate,
}) => {
  const [form] = Form.useForm();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isDragged, setIsDragged] = useState(false);

  const { loading, error } = useSelector(
    (state) => ({
      loading: state.subcategories.loading,
      error: state.subcategories.error,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState(error);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(categoriesCleanUp());
  }, [dispatch]);

  // const onChangeHandler = useChangeHandler(setSubCategory);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(values.name) &&
        values.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(values.description) &&
        values.description && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      setErrors(newErrors);
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...subCategory,
        id: subCategory._id,
        name: values.name,
        description: values.description,
        file: subCategory.file,
      });
      form.resetFields();
    }
  };
  useEffect(() => {
    if (subCategory.file || subCategory.logo) {
      setImagePreviewUrl(
        subCategory.file
          ? subCategory.file && URL.createObjectURL(subCategory.file)
          : subCategory.logo && `${imgUrl}/poi/${subCategory.logo}`
      );
    } else {
      setImagePreviewUrl(null);
    }
  }, [subCategory]);

  const handleChange = (name, value) => {
    setSubCategory({
      ...subCategory,
      [name]: value,
    });
  };

  const handleDeletePreviewImage = () => {
    setSubCategory({ ...subCategory, file: null, logo: null });
    setImagePreviewUrl(null);
  };

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif'
    ) {
      if (file.size / 1000000 <= 10) {
        // setMedia((prevState) => ({ ...prevState, file, logo: null }));
        dispatch(
          setSubCategory({
            ...subCategory,
            file,
          })
        );
        return false;
      }
      message.error('Maximum file size is 300MB');
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
    return true;
  };

  console.log(errors, error, 'errors');
  console.log(subCategory, 'subCategory');

  return (
    <Modal
      visible={visible}
      className="adsign-modal"
      title={isEditing ? 'Edit Category' : 'Create New Category'}
      closeIcon={<i className="feather-x" />}
      okText="Submit"
      confirmLoading={loading}
      okButtonProps={{
        className: 'button-submit',
      }}
      cancelButtonProps={{
        className: 'button-cancel',
      }}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        // onChange={onChangeHandler}
        initialValues={{
          name: subCategory.name,
          description: subCategory.description,
        }}
      >
        {log(loading, 'loading')}
        <Form.Item label="Name" required>
          <Input
            placeholder="Enter Category's name"
            onChange={(e) => handleChange('name', e.target.value)}
            value={subCategory.name}
          />
        </Form.Item>
        {errors &&
          errors.errors &&
          errors.errors.name &&
          errors.errors.name.map((name, idx) => (
            <div className="error-message" key={idx}>
              {name.msg}
            </div>
          ))}

        <Form.Item label="Description">
          <Input.TextArea
            rows={4}
            placeholder="Description"
            onChange={(e) => handleChange('description', e.target.value)}
            value={subCategory.description}
          />
        </Form.Item>
        {errors &&
          errors.errors &&
          errors.errors.description &&
          errors.errors.description.map((name, idx) => (
            <div className="error-message" key={idx}>
              {name.msg}
            </div>
          ))}

        <Form.Item
          label="Category Logo"
          // name="description"
          validateStatus={errors.logo ? 'error' : 'success'}
          help={errors.logo ? errors.logo : null}
        >
          {imagePreviewUrl ? (
            <div className="upload-image-preview">
              <Tooltip placement="top" title="Delete">
                <button
                  type="button"
                  onClick={handleDeletePreviewImage}
                  className="btn-remove"
                >
                  <TrashIcon size={18} color="#CB3A31" />
                </button>
              </Tooltip>
              {(subCategory.file && subCategory.file.type === 'video/mp4') ||
              (subCategory.logo?.split('.').at(-1) === 'mp4' &&
                !subCategory.file) ? (
                <video
                  controls="controls"
                  preload="metadata"
                  key={imagePreviewUrl}
                >
                  <source src={imagePreviewUrl} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="User profile logo preview"
                />
              )}
            </div>
          ) : (
            <Upload
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
              style={{ width: '100%' }}
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png, mp4 (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalSubCategoryForm;
