import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const MAPVIEWER_FETCH_DATA_INIT = createAction(
  'MAPVIEWER_FETCH_DATA_INIT'
);
export const MAPVIEWER_FETCH_DATA_SUCCESS = createAction(
  'MAPVIEWER_FETCH_DATA_SUCCESS'
);
export const MAPVIEWER_FETCH_DATA_FAIL = createAction(
  'MAPVIEWER_FETCH_DATA_FAIL'
);

export const MAPVIEWER_DELETE_MAPVIEWER_INIT = createAction(
  'MAPVIEWER_DELETE_MAPVIEWER_INIT'
);
export const MAPVIEWER_DELETE_MAPVIEWER_SUCCESS = createAction(
  'MAPVIEWER_DELETE_MAPVIEWER_SUCCESS'
);
export const MAPVIEWER_DELETE_MAPVIEWER_FAIL = createAction(
  'MAPVIEWER_DELETE_MAPVIEWER_FAIL'
);

export const MAPVIEWER_CLEAR_DATA = createAction('MAPVIEWER_CLEAR_DATA');

export const MAPVIEWER_CREATE_MAPVIEWER_INIT = createAction(
  'MAPVIEWER_CREATE_MAPVIEWER_INIT'
);
export const MAPVIEWER_CREATE_MAPVIEWER_SUCCESS = createAction(
  'MAPVIEWER_CREATE_MAPVIEWER_SUCCESS'
);
export const MAPVIEWER_CREATE_MAPVIEWER_FAIL = createAction(
  'MAPVIEWER_CREATE_MAPVIEWER_FAIL'
);

export const MAPVIEWER_MODIFY_MAPVIEWER_INIT = createAction(
  'MAPVIEWER_MODIFY_MAPVIEWER_INIT'
);
export const MAPVIEWER_MODIFY_MAPVIEWER_SUCCESS = createAction(
  'MAPVIEWER_MODIFY_MAPVIEWER_SUCCESS'
);
export const MAPVIEWER_MODIFY_MAPVIEWER_FAIL = createAction(
  'MAPVIEWER_MODIFY_MAPVIEWER_FAIL'
);

export const MAPVIEWER_CLEAN_UP = createAction('MAPVIEWER_CLEAN_UP');

export const MAPVIEWER_CLEAR_DATA_LOGOUT = createAction(
  'MAPVIEWER_CLEAR_DATA_LOGOUT'
);

export const fetchMapviewer = ({ siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(MAPVIEWER_FETCH_DATA_INIT());

    log(siteId, 'site Id adsum');

    let mapsData;
    try {
      mapsData = await Axios.get(`${url}/kiosk/get/${siteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then((res) => res.data);

      log(mapsData, 'map data');
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(MAPVIEWER_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      MAPVIEWER_FETCH_DATA_SUCCESS({
        list: mapsData,
      })
    );
  };
};

export const deleteAdsumFile = ({ type, siteId }) => {
  return async (dispatch) => {
    dispatch(MAPVIEWER_DELETE_MAPVIEWER_INIT());

    try {
      await Axios.post(
        `${url}/kiosk/delete`,
        {
          type,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MAPVIEWER_DELETE_MAPVIEWER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The maps was deleted.');
    dispatch(MAPVIEWER_DELETE_MAPVIEWER_SUCCESS());
    return dispatch(fetchMapviewer({ siteId }));
  };
};

export const clearMapsData = () => {
  return (dispatch) => {
    dispatch(MAPVIEWER_CLEAR_DATA());
  };
};

export const clearMapsDataFileut = () => {
  return (dispatch) => {
    dispatch(MAPVIEWER_CLEAR_DATA_LOGOUT());
  };
};

export const createAdsumFile = ({ file, siteId }) => {
  return async (dispatch) => {
    dispatch(MAPVIEWER_CREATE_MAPVIEWER_INIT());
    console.log(file, 'file');

    const fd = new FormData();
    fd.set('siteId', siteId);
    fd.set('type', file.name.split('.')[0].toLowerCase());
    if (file) {
      fd.append('file', file);
    }
    let createMaps;

    try {
      createMaps = await Axios.post(`${url}/kiosk/upload`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then((res) => res.data.data);
      log(createMaps);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        MAPVIEWER_CREATE_MAPVIEWER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Adsum File is uploaded successfully');
    dispatch(MAPVIEWER_CREATE_MAPVIEWER_SUCCESS());
    return dispatch(fetchMapviewer({ siteId }));
  };
};

export const modifyMaps = ({ id, name, description, file, siteId }) => {
  return async (dispatch) => {
    dispatch(MAPVIEWER_MODIFY_MAPVIEWER_INIT());

    const fd = new FormData();
    fd.set('id', id);
    fd.set('path', 'map');
    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    if (file) {
      fd.append('file', file);
    }
    let updateMap;

    try {
      updateMap = await Axios.post(`${url}/map/edit`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then((res) => res.data.data);
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MAPVIEWER_CREATE_MAPVIEWER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Maps updated successfully');

    return dispatch(
      MAPVIEWER_MODIFY_MAPVIEWER_SUCCESS({ maps: updateMap, id })
    );
  };
};

export const modifyMapsPath = ({
  id,
  map,
  mapPos,
  userPos,
  pathsObject,
}) => async (dispatch) => {
  dispatch(MAPVIEWER_MODIFY_MAPVIEWER_INIT());
  const paths = JSON.parse(JSON.stringify(pathsObject));
  const mapData = {
    ...map,
    mapPos,
    paths,
    userPos,
  };
  log(mapData);
  const updateUserAccess = await Axios.post(
    `${url}/map/edit`,
    {
      id,
      ...mapData,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
  ).then((res) => res.data.data);
  try {
    await updateUserAccess;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error && error.response && error.response.data.message;
    }
    toastr.error('', errorMessage);
    return dispatch(
      MAPVIEWER_MODIFY_MAPVIEWER_FAIL({
        error: errorMessage,
      })
    );
  }
  toastr.success('', 'Map path updated successfully');
  return dispatch(MAPVIEWER_MODIFY_MAPVIEWER_SUCCESS({ map: mapData, id }));
};

export const mapsCleanUp = () => (dispatch) => dispatch(MAPVIEWER_CLEAN_UP());
