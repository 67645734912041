import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const CONFIG_FETCH_DATA_INIT = createAction('CONFIG_FETCH_DATA_INIT');
export const CONFIG_FETCH_DATA_SUCCESS = createAction(
  'CONFIG_FETCH_DATA_SUCCESS'
);
export const CONFIG_FETCH_DATA_FAIL = createAction('CONFIG_FETCH_DATA_FAIL');

export const CONFIG_DELETE_INIT = createAction('CONFIG_DELETE_INIT');
export const CONFIG_DELETE_SUCCESS = createAction('CONFIG_DELETE_SUCCESS');
export const CONFIG_DELETE_FAIL = createAction('CONFIG_DELETE_FAIL');

export const CONFIG_CLEAR_DATA = createAction('CONFIG_CLEAR_DATA');

export const CONFIG_CREATE_INIT = createAction('CONFIG_CREATE_INIT');
export const CONFIG_CREATE_SUCCESS = createAction('CONFIG_CREATE_SUCCESS');
export const CONFIG_CREATE_FAIL = createAction('CONFIG_CREATE_FAIL');

export const CONFIG_MODIFY_INIT = createAction('CONFIG_MODIFY_INIT');
export const CONFIG_MODIFY_SUCCESS = createAction('CONFIG_MODIFY_SUCCESS');
export const CONFIG_MODIFY_FAIL = createAction('CONFIG_MODIFY_FAIL');

export const CONFIG_CLEAN_UP = createAction('CONFIG_CLEAN_UP');

export const CONFIG_CLEAR_DATA_LOGOUT = createAction(
  'CONFIG_CLEAR_DATA_LOGOUT'
);

export const fetchConfig = ({ configId, siteId, screenId }) => {
  return async (dispatch, getState) => {
    dispatch(CONFIG_FETCH_DATA_INIT());
    if (configId) {
      let configData;
      try {
        configData = await Axios.get(`${url}/config/list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(CONFIG_FETCH_DATA_FAIL({ error }));
      }
      const config = {
        ...configData.data.data,
        id: configId,
      };
      return dispatch(
        CONFIG_FETCH_DATA_SUCCESS({
          list: getState().categories.list,
          config,
        })
      );
    }

    if (siteId) {
      let config;
      try {
        config = await Axios.get(`${url}/config/list`, {
          params: {
            siteId,
            screenId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        log(config, '<<< infor cat');
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(CONFIG_FETCH_DATA_FAIL({ error }));
      }

      config = config.data.data.filter((config) => config.siteId === siteId);

      return dispatch(
        CONFIG_FETCH_DATA_SUCCESS({
          list: config,
          config: getState().config.config,
        })
      );
    }
  };
};

export const deleteconfig = (id, cb) => {
  return async (dispatch, getState) => {
    dispatch(CONFIG_DELETE_INIT());

    try {
      await Axios.delete(`${url}/config/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIG_DELETE_FAIL({
          error: errorMessage,
        })
      );
    }
    cb();
    toastr.success('', 'The information category was deleted.');
    return dispatch(CONFIG_DELETE_SUCCESS({ id }));
  };
};

export const clearConfigData = () => {
  return (dispatch) => {
    dispatch(CONFIG_CLEAR_DATA());
  };
};

export const clearConfigDataLogout = () => {
  return (dispatch) => {
    dispatch(CONFIG_CLEAR_DATA_LOGOUT());
  };
};

export const createconfig = ({ variable, values, siteId, screenId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(CONFIG_CREATE_INIT());
    let config;
    try {
      config = await Axios.post(
        `${url}/config/create`,
        {
          variable,
          values,
          siteId,
          screenId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error.response, '<<< error response');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIG_CREATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Config created successfully');
    cb();
    return dispatch(
      CONFIG_CREATE_SUCCESS({
        config: config.data.data,
      })
    );
  };
};

export const modifyconfig = (
  { id, variable, values, siteId, screenId, modifiedAt, modifiedBy },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(CONFIG_MODIFY_INIT());

    const configData = {
      id,
      variable,
      values,
      siteId,
      screenId,
      modifiedAt,
      modifiedBy,
    };

    const updateconfigDbTask = Axios.post(`${url}/config/edit`, configData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    try {
      await Promise.all([updateconfigDbTask]);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        CONFIG_MODIFY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Config updated successfully');
    cb();
    return dispatch(
      CONFIG_MODIFY_SUCCESS({
        config: configData,
        id,
      })
    );
  };
};

export const configCleanUp = () => (dispatch) => dispatch(CONFIG_CLEAN_UP());
