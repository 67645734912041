import { Tabs } from 'antd';
// import Feedbacks from 'pages/Feedbacks';
import { log } from 'utils';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from 'state/actions/screens';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { fetchConfigs } from 'state/actions/configuration';
import { InfoIcon, TickIcon } from 'assets/icon';
import './Configuration.css';
import ColorForm from 'components/ColorForm';
import MediaConfigurationForm from 'components/MediaConfigurationForm';

const { TabPane } = Tabs;

function Configuration() {
  const dispatch = useDispatch();
  const { loading, configData, userData } = useSelector(
    (state) => ({
      loading: state.config.loading,
      configData: state.configuration.config,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const { tab: activeTab } = useSelector((state) => ({
    tab: state.screens.tab,
    activeScreen: state.screens.activeScreen,
  }));

  const { siteId } = useParams();
  const [configuration, setConfiguration] = useState({ siteId });
  const [configPages, setConfigPages] = useState();

  useEffect(() => {
    dispatch(fetchConfigs({ siteId }));
  }, [siteId, dispatch]); //eslint-disable-line

  useEffect(() => {
    if (configData.siteId) {
      setConfiguration(configData);
    } else {
      setConfiguration({ ...configData, siteId });
    }
  }, [configData]); //eslint-disable-line
  useEffect(() => {
    const newConf = [
      {
        key: 'colors',
        title: 'Colors',
        active: true,
        completeParams: ['colors'],
        children: (
          <ColorForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'medias',
        title: 'Medias',
        active: true,
        completeParams: ['medias'],
        children: (
          <MediaConfigurationForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
    ];
    setConfigPages(newConf);
  }, [configData, configuration]); //eslint-disable-line

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    dispatch(setActiveTab({ tab: activeKey }));
  };

  const checkComplete = (array) => {
    const empty = array.filter((arr) => !configData[arr]);
    console.log(empty);
    return empty.length === 0;
  };

  return (
    <div className="configuration">
      <Tabs
        activeKey={activeTab}
        onTabClick={handleChangeTab}
        className="config-tab"
        tabPosition="left"
      >
        {configPages &&
          configPages.map(
            (page) =>
              page.active && (
                <TabPane
                  tab={
                    <div
                      onClick={(e) => e.preventDefault()}
                      className="configuration-nav"
                    >
                      <span>{page.title}</span>
                      {checkComplete(page.completeParams) ? (
                        <TickIcon />
                      ) : (
                        <InfoIcon />
                      )}
                    </div>
                  }
                  key={page.key}
                >
                  {page.children}
                </TabPane>
              )
          )}
      </Tabs>
    </div>
  );
}

export default Configuration;
