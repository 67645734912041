import { createReducer } from 'redux-act';

import {
  CONFIGS_FETCH_DATA_INIT,
  CONFIGS_FETCH_DATA_SUCCESS,
  CONFIGS_FETCH_DATA_FAIL,
  CONFIGS_DELETE_CONFIG_INIT,
  CONFIGS_DELETE_CONFIG_SUCCESS,
  CONFIGS_DELETE_CONFIG_FAIL,
  CONFIGS_CLEAR_DATA,
  CONFIGS_CREATE_CONFIG_INIT,
  CONFIGS_CREATE_CONFIG_SUCCESS,
  CONFIGS_CREATE_CONFIG_FAIL,
  CONFIGS_MODIFY_CONFIG_INIT,
  CONFIGS_MODIFY_CONFIG_SUCCESS,
  CONFIGS_MODIFY_CONFIG_FAIL,
  CONFIGS_CLEAN_UP,
  CONFIGS_CLEAR_DATA_LOGOUT,
  SET_CONFIG_TAB,
  SET_ACTIVE_CONFIG,
} from 'state/actions/configuration';

const initialState = {
  list: [],
  config: {
    colors: [],
    medias: [],
    siteId: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
  tab: 'configSettings',
  activeConfig: {
    _id: '',
    name: '',
    description: '',
  },
};

export const configsReducer = createReducer(
  {
    [CONFIGS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CONFIGS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      config: payload.config ?? initialState.config,
      loading: false,
      error: null,
    }),
    [CONFIGS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGS_DELETE_CONFIG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIGS_DELETE_CONFIG_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [CONFIGS_DELETE_CONFIG_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [CONFIGS_CREATE_CONFIG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIGS_CREATE_CONFIG_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.config),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [CONFIGS_CREATE_CONFIG_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGS_MODIFY_CONFIG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIGS_MODIFY_CONFIG_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.config.name,
                description: payload.config.description,
                organizationId: payload.config.organizationId,
                organizationName: payload.config.organizationName,
                modifiedAt: new Date().toDateString(),
                modifiedBy: elem.email,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [CONFIGS_MODIFY_CONFIG_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [CONFIGS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_CONFIG_TAB]: (state, payload) => ({
      ...state,
      tab: payload.tab,
    }),
    [SET_ACTIVE_CONFIG]: (state, payload) => {
      return {
        ...state,
        activeConfig: payload,
      };
    },
  },
  initialState
);
