import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';
import fileDownload from 'js-file-download';
import moment from 'moment';

export const TEMPLATE_FETCH_DATA_INIT = createAction(
  'TEMPLATE_FETCH_DATA_INIT'
);
export const TEMPLATE_FETCH_DATA_SUCCESS = createAction(
  'TEMPLATE_FETCH_DATA_SUCCESS'
);
export const TEMPLATE_FETCH_DATA_FAIL = createAction(
  'TEMPLATE_FETCH_DATA_FAIL'
);

export const TEMPLATE_DELETE_TEMPLATE_INIT = createAction(
  'TEMPLATE_DELETE_TEMPLATE_INIT'
);
export const TEMPLATE_DELETE_TEMPLATE_SUCCESS = createAction(
  'TEMPLATE_DELETE_TEMPLATE_SUCCESS'
);
export const TEMPLATE_DELETE_TEMPLATE_FAIL = createAction(
  'TEMPLATE_DELETE_TEMPLATE_FAIL'
);

export const TEMPLATE_CLEAR_DATA = createAction('TEMPLATE_CLEAR_DATA');

export const TEMPLATE_CREATE_TEMPLATE_INIT = createAction(
  'TEMPLATE_CREATE_TEMPLATE_INIT'
);
export const TEMPLATE_CREATE_TEMPLATE_SUCCESS = createAction(
  'TEMPLATE_CREATE_TEMPLATE_SUCCESS'
);
export const TEMPLATE_CREATE_TEMPLATE_FAIL = createAction(
  'TEMPLATE_CREATE_TEMPLATE_FAIL'
);

export const TEMPLATE_MODIFY_TEMPLATE_INIT = createAction(
  'TEMPLATE_MODIFY_TEMPLATE_INIT'
);
export const TEMPLATE_MODIFY_TEMPLATE_SUCCESS = createAction(
  'TEMPLATE_MODIFY_TEMPLATE_SUCCESS'
);
export const TEMPLATE_MODIFY_TEMPLATE_FAIL = createAction(
  'TEMPLATE_MODIFY_TEMPLATE_FAIL'
);

export const TEMPLATE_CLEAN_UP = createAction('TEMPLATE_CLEAN_UP');

export const TEMPLATE_CLEAR_DATA_LOGOUT = createAction(
  'TEMPLATE_CLEAR_DATA_LOGOUT'
);
export const TEMPLATE_EXPORT_TEMPLATE_INIT = createAction(
  'TEMPLATE_EXPORT_TEMPLATE_INIT'
);
export const TEMPLATE_EXPORT_TEMPLATE_SUCCESS = createAction(
  'TEMPLATE_EXPORT_TEMPLATE_SUCCESS'
);
export const TEMPLATE_EXPORT_TEMPLATE_FAIL = createAction(
  'TEMPLATE_EXPORT_TEMPLATE_FAIL'
);

export const fetchTemplates = ({ templateId, siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(TEMPLATE_FETCH_DATA_INIT());
    let templateData;

    try {
      templateData = await Axios.get(`${url}/template/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        params: {
          siteId,
        },
      });
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(TEMPLATE_FETCH_DATA_FAIL({ error }));
    }
    if (templateId) {
      const templateFind = templateData.data.data.find(
        (x) => x._id === templateId
      );
      const template = { ...templateFind, id: templateId };
      return dispatch(
        TEMPLATE_FETCH_DATA_SUCCESS({
          list: getState().template.list,
          template,
        })
      );
    } else {
      return dispatch(
        TEMPLATE_FETCH_DATA_SUCCESS({
          list: templateData.data.data,
          template: getState().template.template,
        })
      );
    }
  };
};

export const removeTemplate = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(TEMPLATE_DELETE_TEMPLATE_INIT());
    try {
      console.log('delete');
      await Axios.delete(
        `${url}/template/delete`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            id,
            siteId,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TEMPLATE_DELETE_TEMPLATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The poi was removed.');
    cb();
    return dispatch(TEMPLATE_DELETE_TEMPLATE_SUCCESS({ id }));
  };
};

export const deletePoi = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(TEMPLATE_DELETE_TEMPLATE_INIT());
    try {
      await Axios.post(
        `${url}/template/delete`,
        {
          poiId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TEMPLATE_DELETE_TEMPLATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The poi was deleted.');
    cb();
    return dispatch(TEMPLATE_DELETE_TEMPLATE_SUCCESS({ id }));
  };
};

export const clearPoisData = () => {
  return (dispatch) => {
    dispatch(TEMPLATE_CLEAR_DATA());
  };
};

export const clearPoisDataLogout = () => {
  return (dispatch) => {
    dispatch(TEMPLATE_CLEAR_DATA_LOGOUT());
  };
};

export const createTemplate = ({ template, file }, cb) => {
  return async (dispatch, getState) => {
    dispatch(TEMPLATE_CREATE_TEMPLATE_INIT());

    // let createPoi;
    console.log(template, 'template body');
    let fd = new FormData();
    fd.set('name', template.name);
    fd.set('description', template.description);
    fd.set('siteId', template.siteId);
    fd.set('width', template.width);
    fd.set('height', template.height);
    fd.set('orientation', template.orientation);
    fd.set('backgroundType', template.backgroundType);
    fd.set('color', template.color);
    fd.append('file', file);

    let response;
    try {
      response = await Axios.post(`${url}/template/create`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        TEMPLATE_CREATE_TEMPLATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Template created successfully');
    if (cb) {
      cb(response.data.data);
    }
    console.log(response.data.data, 'response data');
    return dispatch(
      TEMPLATE_CREATE_TEMPLATE_SUCCESS({ template: response.data.data })
    );
  };
};

export const modifyTemplate = ({ template, file }) => {
  return async (dispatch, getState) => {
    dispatch(TEMPLATE_MODIFY_TEMPLATE_INIT());

    let fd = new FormData();
    fd.set('name', template.name);
    fd.set('id', template._id);
    fd.set('description', template.description);
    fd.set('siteId', template.siteId);
    fd.set('width', template.width);
    fd.set('height', template.height);
    fd.set('orientation', template.orientation);
    fd.set('canvas', JSON.stringify(template.canvas));
    fd.set('backgroundType', template.backgroundType);
    fd.set('color', template.color);
    fd.append('file', file);
    try {
      console.log(template, 'template');
      await Axios.post(`${url}/template/edit`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TEMPLATE_MODIFY_TEMPLATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Template updated successfully');
    return dispatch(
      TEMPLATE_MODIFY_TEMPLATE_SUCCESS({ template, id: template.id })
    );
  };
};

export const downloadPois = ({ siteId, siteName }) => {
  return async (dispatch, getState) => {
    dispatch(TEMPLATE_EXPORT_TEMPLATE_INIT());
    Axios.get(`${url}/template/export`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      responseType: 'blob',
      params: {
        siteId,
      },
    })
      .then((res) => {
        fileDownload(
          res.data,
          `poi_export_${siteName}_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.csv`
        );
        return dispatch(TEMPLATE_EXPORT_TEMPLATE_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(TEMPLATE_EXPORT_TEMPLATE_FAIL({ error }));
      });
  };
};

export const poisCleanUp = () => (dispatch) => dispatch(TEMPLATE_CLEAN_UP());
