import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import CategoryForm from 'components/CategoryForm';
import {
  createCategory,
  modifyCategory,
  fetchCategories,
} from 'state/actions/categories';
import { useFormatMessage } from 'hooks';

const Category = ({ history }) => {
  const { id } = useParams();

  const {
    success,
    categoriesList,
    categoryData,
    sitesList,
    error,
  } = useSelector(
    (state) => ({
      success: state.categories.success,
      categoriesList: state.categories.list,
      categoryData: state.categories.category,
      sitesList: state.sites.list,
      error: state.categories.error,
    }),
    shallowEqual
  );

  const [category, setCategory] = useState(categoryData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const categoryFetched = categoriesList.find(
        (fetchedCategory) => fetchedCategory._id === id
      );
      if (categoryFetched) {
        setCategory(categoryFetched);
      } else if (categoryData.id === id) {
        setCategory(categoryData);
      } else {
        dispatch(fetchCategories(id));
      }
    }
  }, [id, categoryData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEditing = !!id;

  const categoryForm =
    !category && id ? (
      <ClipLoader />
    ) : (
      <CategoryForm
        isEditing={isEditing}
        category={category}
        sites={sitesList}
        id={id}
        setCategory={setCategory}
        action={isEditing ? modifyCategory : createCategory}
        history={history}
        success={success}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editCategoryMessage = useFormatMessage('Category.editCategory');

  const newCategoryMessage = useFormatMessage('Category.newCategory');

  return (
    <>
      {/* {redirect} */}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCategoryMessage : newCategoryMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">{categoryForm}</section>
    </>
  );
};

export default Category;
