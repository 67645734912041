import { CircularProgress } from '@material-ui/core';
import { Table } from 'antd';

import React from 'react';

import { shallowEqual, useSelector } from 'react-redux';
// import ScreensaverReport from './ScreensaverReport';

const LeaderboardReport = (props) => {
  const { loading } = props;

  const { leaderboard, screens } = useSelector(
    (state) => ({
      leaderboard: state.analytic.leaderboard,
      screens: state.screens.list,
    }),
    shallowEqual
  );

  const columns = [
    {
      title: 'Number of Touches',
      dataIndex: 'count',
      width: '50%',
      sorter: (a, b) => a.count - b.count,
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Kiosk Location',
      dataIndex: 'screenId',
      width: '50%',
      sorter: (a, b) => a.select - b.select,
      render: (text) => (
        <div className="table-name">
          {screens.find((screen) => screen.adsumScreenId === text)?.name}
        </div>
      ),
    },
  ];

  return (
    <div className="analytic-chart">
      {loading ? (
        <CircularProgress />
      ) : (
        <Table columns={columns} dataSource={leaderboard} />
      )}
    </div>
  );
};

export default LeaderboardReport;
