import { Button, Modal, Pagination, Tooltip } from 'antd';
import ModalMapForm from 'components/ModalMapForm';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
// import Table from 'components/Table';
import {
  createMaps,
  deleteMaps,
  fetchMaps,
  modifyMaps,
} from 'state/actions/maps';
import { fetchPois } from 'state/actions/pois';
import _ from 'underscore';
import { regexInput } from 'utils/regex';
import {imgUrl} from 'utils/url';
import { log } from 'utils';
import './Maps.scss';

const Maps = () => {
  const { siteId, siteName } = useParams();

  const {
    maps,
    error,
    loading,
    deleted,
    userData,
    updated,
    role,
  } = useSelector(
    (state) => ({
      maps: state.maps.list,
      error: state.maps.error,
      loading: state.maps.loading,
      deleted: state.maps.deleted,
      userData: state.auth.userData,
      updated: state.maps.updated,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    mapId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const defaultMap = {
    description: '',
    fileUrl: null,
    file: null,
    name: '',
  };

  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [map, setMap] = useState(defaultMap);
  // const [imgUrl, setImgUrl] = useState(null);
  const [page, setPage] = useState(0);
  const [searchError, setSearchError] = useState(null);

  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchMaps({ siteId }));
    dispatch(fetchPois({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updated && !loading) {
      dispatch(fetchMaps({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        categoryId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchMaps({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = !role && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (mapId) => {
    setDeleteModal((prevState) => ({
      mapId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ mapId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteMaps(deleteModal.mapId, siteId));
    // setImgUrl(null);
  };

  const onCreate = (values) => {
    log(values, '<<< values on create');
    const action = isEditing ? modifyMaps : createMaps;
    dispatch(action({ uid, ...values, siteId }));
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage - 1);
  };

  const onNewMapHandler = () => {
    setIsEditing(false);
    setVisible(true);
    setMap(defaultMap);
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const data = search
    ? maps.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : maps;

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Categories.permDelete');

  return (
    <section className="maps">
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        >
          {permDeleteMessage}
        </Modal>
      )}

      {visible && (
        <ModalMapForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          map={map}
          setMap={setMap}
          isEditing={isEditing}
        />
      )}
      <div className="maps__nav">
        <div className="maps__search-wrapper">
          <div className="maps__search">
            <i className="feather-search"></i>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>

        <Button
          type="primary"
          className="button-primary"
          onClick={onNewMapHandler}
        >
          {useFormatMessage('Maps.newMap')}
        </Button>
      </div>

      {loading ? (
        <ClipLoader />
      ) : (
        data &&
        _.chunk(_.sortBy(data, 'name').slice(8 * page, 8 * (page + 1)), 4).map(
          (chunk, index) => {
            return (
              <div className="maps__list" key={index}>
                {chunk.map((map) => (
                  <div className="maps__card" key={map._id}>
                    <div className="maps__overlay">
                      <h3 className="maps__name">{map.name}</h3>
                      <div className="maps__overlay-button-group">
                        <Tooltip title="Draw Paths">
                          <Link
                            className="btn btn--pin"
                            to={`/${siteId}/${siteName}/maps/${map._id}/${map.name}/paths`}
                          >
                            <i className="feather-map-pin"></i>
                          </Link>
                        </Tooltip>

                        <Tooltip title="Delete Map">
                          <button
                            className="btn btn--delete"
                            onClick={() => onRemoveButtonClickHandler(map._id)}
                          >
                            <i className="feather-trash-2"></i>
                          </button>
                        </Tooltip>

                        <Tooltip title="Edit Map">
                          <button
                            onClick={() => {
                              setIsEditing(true);
                              setMap(map);
                              setVisible(true);
                            }}
                            className="btn btn--edit"
                          >
                            <i className="feather-edit"></i>
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <img
                      className="maps__img"
                      src={`${imgUrl}/map/${map.fileUrl}`}
                      alt="map-img"
                    />
                  </div>
                ))}
              </div>
            );
          }
        )
      )}

      {error && 'Show error'}

      <Pagination
        className="maps__pagination"
        defaultCurrent={page + 1}
        total={data.length}
        pageSize={8}
        onChange={handleChangePage}
      />
    </section>
  );
};

export default Maps;
