import { Modal, Select, Switch, Table, Tag, Tooltip } from 'antd';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchUsers, resetMFA } from 'state/actions/users';
import { fetchRoles, modifyRole } from 'state/actions/roles';
import { regexInput } from 'utils/regex';
import moment from 'moment';
import MFAImage from 'assets/mfa.png';
// import './User.css';
import './MFAManagement.scss';
import ModalConfirmation from 'components/ModalConfirmation';
import PageHeader from 'design-components/PageHeader';

function MFAManagement() {
  const [search, setSearch] = useState('');
  const [userId, setUserId] = useState();
  const [roleId, setRoleId] = useState();
  const [visible, setVisible] = useState(false);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    title: '',
    description: '',
    icon: '',
    roleId: null,
    checked: false,
  });

  const {
    roles,
    // isAdmin,
    error,
    loading,
    deleted,
    role,
    updated,
    usersList,
  } = useSelector(
    (state) => ({
      usersList: state.users.list,
      roles: state.roles.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
      updated: state.roles.updated,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'superadmin') {
      dispatch(fetchUsers());
      dispatch(fetchRoles());
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchUsers());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpenModal((prevState) => ({
      ...prevState,
      roleId: null,
      isOpen: false,
    }));
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchError, setSearchError] = useState(null);
  const onSelectHandler = (event) => {
    setUserId(event.value);
  };

  const onResetMFAButtonClickHandler = (roleId) => {
    setRoleId(roleId);
    setVisible(true);
  };

  const onMFAChanged = (roleName, roleId, isEnable) => {
    setOpenModal({
      isOpen: true,
      header: isEnable ? 'Enable MFA?' : 'Disable MFA',
      title: 'Change MFA Status',
      description: isEnable
        ? 'MFA for this role will be enabled'
        : 'MFA for this role will be disabled',
      roleId,
      checked: isEnable,
      icon: MFAImage,
    });
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '45%',
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'MFA Enabled',
      dataIndex: 'isRoleEnableMFA',
      width: '20%',
      render: (text, record) => {
        return (
          <Switch
            checked={record.isRoleEnableMFA}
            onChange={(checked) =>
              onMFAChanged(record.name, record._id, checked)
            }
          ></Switch>
        );
        // return record.isRoleEnableMFA ? 'Enabled' : 'Disabled';
      },
    },
    {
      title: 'MFA Enabled Date',
      dataIndex: 'dateEnableMFA',
      width: '20%',
      render: (text, record) => {
        if (record.isRoleEnableMFA) {
          return (
            <Tag className="table-date">
              <div>{`${moment(record.dateEnableMFA)
                .utcOffset('+08:00')
                .format('h:mm A, MMM Do, YYYY')}`}</div>
            </Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',
      width: '20%',
      render: (text, record) => {
        return (
          <div className="mfa-mng__actions">
            {/* {role === 'superadmin' ? (
              <Tooltip title="Enable MFA">
                <button
                  className="btn btn--delete"
                  onClick={() =>
                    onMFAChanged(
                      record.name,
                      record._id,
                      record.isRoleEnableMFA
                    )
                  }
                >
                  <i className="feather-edit"></i>
                  {record.isRoleEnableMFA ? 'Disable MFA' : 'Enable MFA'}
                </button>
              </Tooltip>
            ) : (
              <></>
            )} */}
            {role === 'superadmin' ? ( // eslint-disable-line no-mixed-operators
              <Tooltip title="Reset MFA">
                <button
                  className="btn btn--delete"
                  onClick={() => onResetMFAButtonClickHandler(record._id)}
                >
                  <i className="feather-repeat"></i>
                  Reset MFA
                </button>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];
  const onCloseModalHandler = () => {
    setOpenModal((prevState) => ({
      ...prevState,
      roleId: null,
      isOpen: false,
    }));
  };

  const data = search
    ? roles.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.name &&
          clonedElem.name.toLowerCase().includes(search.toLowerCase());
        const email =
          clonedElem.email &&
          clonedElem.email.toLowerCase().includes(search.toLowerCase());
        const role =
          clonedElem.role &&
          clonedElem.role.toLowerCase().includes(search.toLowerCase());
        if (name || email || role) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      roles;

  return (
    <section className="mfa-mng">
      {redirect}
      <PageHeader
        title="MFA Management"
        isGrid={false}
        isSearch={true}
        searchValue={search}
        searchAction={handleSearch}
        searchPlaceholder="Enter Role's Name"
        searchError={searchError}
        isAdd={false}
        addLabel=""
      />
      <div className="mfa-mng__table">
        {visible && (
          <Modal
            visible={visible}
            title="Select user to reset MFA"
            onOk={() => {
              resetMFA(userId);
              setVisible(false);
              setRoleId();
              setUserId();
            }}
            okButtonProps={loading}
            onCancel={() => {
              setVisible(false);
              setRoleId();
              setUserId();
            }}
          >
            <Select
              labelInValue
              value={
                userId
                  ? {
                      value: userId,
                      label:
                        usersList.length &&
                        usersList.find((category) => category._id === userId)
                          .email,
                    }
                  : null
              }
              onChange={onSelectHandler}
              placeholder="Select User"
            >
              {usersList &&
                usersList
                  .filter((user) => user.roleId === roleId)
                  // .sort((a, b) =>
                  //   a.name.toLowerCase() > b.name.toLowerCase() ? 1 = -1
                  // )
                  .map((user) => (
                    <Select.Option value={user._id} key={user._id}>
                      {user.email}
                    </Select.Option>
                  ))}
            </Select>
          </Modal>
        )}

        {openModal.isOpen && (
          <ModalConfirmation
            title={openModal.title}
            onCloseHandler={onCloseModalHandler}
            header={openModal.header}
            onConfirmHandler={() =>
              dispatch(
                modifyRole({
                  id: openModal.roleId,
                  enableMFA: openModal.checked,
                })
              )
            }
            description={openModal.description}
            visible={openModal.isOpen}
            icon={openModal.icon}
          />
        )}
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default MFAManagement;
