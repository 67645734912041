import { createReducer } from 'redux-act';

import {
  TAG_FETCH_DATA_INIT,
  TAG_FETCH_DATA_SUCCESS,
  TAG_FETCH_DATA_FAIL,
  TAG_DELETE_INIT,
  TAG_DELETE_SUCCESS,
  TAG_DELETE_FAIL,
  TAG_CLEAR_DATA,
  TAG_CREATE_INIT,
  TAG_CREATE_SUCCESS,
  TAG_CREATE_FAIL,
  TAG_MODIFY_INIT,
  TAG_MODIFY_SUCCESS,
  TAG_MODIFY_FAIL,
  TAG_CLEAN_UP,
  TAG_CLEAR_DATA_LOGOUT,
  SET_TAG,
} from 'state/actions/tag';

const initialState = {
  list: [],
  tag: {
    _id: '',
    name: '',
    logo: '',
    file: null,
    isRibbon: false,
    isBuilding: false,
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const tagReducer = createReducer(
  {
    [TAG_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TAG_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      tag: payload.tag || initialState.tag,
      loading: false,
      error: null,
    }),
    [TAG_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAG_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAG_DELETE_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      deleted: true,
    }),
    [TAG_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAG_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [TAG_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAG_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [TAG_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAG_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAG_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [TAG_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAG_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [TAG_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_TAG]: (state, payload) => ({
      ...state,
      tag: payload.data,
    }),
  },
  initialState
);
